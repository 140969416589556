// // ** React Imports
// // ** Icons Imports

export const columns = [
  {
    name: 'Sıra No',
    sortable: true,
    // minWidth: '100px',
    sortField: 'sequenceNo',
    selector: row => row.sequenceNo,
    cell: row => row.sequenceNo
  },
  {
    name: 'S.Güvenlik No',
    sortable: true,
    // minWidth: '100px',
    sortField: 'securityNumber',
    selector: row => row.securityNumber,
    cell: row => row.securityNumber
  },
  {
    name: 'Adı',
    sortable: true,
    // minWidth: '100px',
    sortField: 'firstName',
    selector: row => row.firstName,
    cell: row => row.firstName
  },
  {
    name: 'Soyadı',
    // minWidth: '138px',
    sortable: true,
    sortField: 'lastName',
    selector: row => row.lastName,
    cell: row => row.lastName
  },
  {
    name: 'İlkSoyadı',
    // minWidth: '230px',
    sortable: true,
    sortField: 'firstSurname',
    selector: row => row.firstSurname,
    cell: row => row.firstSurname
  },
  {
    name: 'Ücret TL',
    // minWidth: '230px',
    sortable: true,
    sortField: 'fee',
    selector: row => row.fee,
    cell: row => row.fee
  },
  {
    name: 'İkramiye TL',
    // minWidth: '230px',
    sortable: true,
    sortField: 'bonus',
    selector: row => row.bonus,
    cell: row => row.bonus
  },
  {
    name: 'Gün',
    // minWidth: '230px',
    sortable: true,
    sortField: 'day',
    selector: row => row.day,
    cell: row => row.day
  },
  {
    name: 'UÇG',
    // minWidth: '230px',
    sortable: true,
    sortField: 'taxNumber',
    selector: row => row.taxNumber,
    cell: row => row.taxNumber
  },
  {
    name: 'Eksik Gün',
    // minWidth: '230px',
    sortable: true,
    sortField: 'day',
    selector: row => row.day,
    cell: row => row.day
  },
  {
    name: 'Ggün',
    // minWidth: '230px',
    sortable: true,
    sortField: 'ucg',
    selector: row => row.ucg,
    cell: row => row.ucg
  },
  {
    name: 'Çgün',
    // minWidth: '230px',
    sortable: true,
    sortField: 'cDay',
    selector: row => row.cDay,
    cell: row => row.cDay
  },
  {
    name: 'İÇN',
    // minWidth: '230px',
    sortable: true,
    sortField: 'fiveThousandFiveHundredTen',
    selector: row => row.fiveThousandFiveHundredTen,
    cell: row => row.fiveThousandFiveHundredTen
  },
  {
    name: 'EGN',
    // minWidth: '230px',
    sortable: true,
    sortField: 'poi',
    selector: row => row.poi,
    cell: row => row.poi
  },
  {
    name: 'Meslek Kod',
    // minWidth: '230px',
    sortable: true,
    sortField: 'occupationCode',
    selector: row => row.occupationCode,
    cell: row => row.occupationCode
  }
]
