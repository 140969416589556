import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import toast from "react-hot-toast"


export const getIncentiveProcessAllGroup = createAsyncThunk('incentive/getIncentiveProcessAll', async (params) => {
    const path = `/api/Incentive/GetProcessAllGroup`
    const response = await axios.post(path, params)
    return {
        params,
        data: response.data.processList,
        totalPages: response.data.pageCount
    }
})

export const getSchedulerList = createAsyncThunk('incentive/getSchedulerList', async (data) => {
    const response = await axios.get(`/api/Scheduler`, {
        params: {
            SchedulerType: data
        }
    })
    return {
        data: response.data.scheduler
    }
})

export const retryButtonDisabled = createAsyncThunk('incentive/retryButtonDisabled', async (data) => {
    return data
})

export const reTry = createAsyncThunk('incentive/reTry', async (data, {dispatch}) => {
    await dispatch(retryButtonDisabled(true))
    toast.loading('İşlem Tamamlanana Kadar Bekleyiniz...')
    const response = await axios.get(`/api/Scheduler/retry/${data}`)
    toast.remove()
    await dispatch(retryButtonDisabled(false))
    if (response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getSchedulerList())
    } else {
        toast.error("Bir Sorun Oluştu")
    }
})

export const downloadSchedulerExcel = createAsyncThunk('incentive/downloadSchedulerExcel', async () => {
    const response = await axios.get(`/api/Incentive/GetCurrentAuditExport`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob'
    })
    debugger
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

export const downloadCompaniesExcel = createAsyncThunk('incentive/downloadCompaniesExcel', async (period) => {
    if (period === null) {
        toast.error("Dönem seçiniz.")
        return
    }
    const response = await axios.get(`/api/Incentive/DownloadCompaniesExcel`, {
        params: {Period: parseInt(period.value)},
        responseType: 'arraybuffer',
        'Content-Type': 'blob'
    })
    debugger
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

export const incentiveManagementSlice = createSlice({
    name: 'incentiveManagement',
    initialState: {
        total: 1,
        params: {},
        data: [],
        schedulerData: [],
        retryButtonDisabled: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getIncentiveProcessAllGroup.fulfilled, (state, action) => {
                console.log(action.payload)
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getSchedulerList.fulfilled, (state, action) => {
                state.schedulerData = action.payload.data
            })
            .addCase(retryButtonDisabled.fulfilled, (state, action) => {
                state.retryButtonDisabled = action.payload
            })
    }
})

export default incentiveManagementSlice.reducer