import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import { default as HomeTable } from '../views/Home'
import { default as CompanyGroupTable } from '../views/pages/company-group/Table'
import { default as CompanyTable } from '../views/pages/company/list/Table'
import UserInfoCard from "../views/pages/company/view/index"
import { default as EDeclarationByServiceTable } from '../views/pages/edeclaration-by-service/list/Table'
import { default as EDeclarationTable } from '../views/pages/edeclaration/list/Table'
import { default as Payroll } from '../views/pages/payroll/list/Table'
import userDetails from '../views/pages/user-management/details/userDetails'
import Table from '../views/pages/user-management/Table'
import { default as UserFileManager } from '../views/pages/UserFileManager/index'
import {default as Incentive } from '../views/pages/incentive/index'
import {default as IncentiveManagement } from '../views/pages/incentive-management/index'
import {default as SubProcessList } from '../views/pages/incentive-management/SubProcessList'
import {default as ProcessWorkers } from '../views/pages/incentive-management/ProcessWorkers'
import {default as IncentiveBilling } from '../views/pages/incentive-billing/index'
import { default as ApprovedVisitList } from '../views/pages/visit-approved/list/Table'
import { default as Visit } from '../views/pages/visit/list/Table'
import { default as WorkAccident } from '../views/pages/work-accident/list/Table'
import { default as GendarmerieTable } from '../views/pages/WorkerDeclaration/Gendarmerie/Table'
import { default as PoliceTable } from '../views/pages/WorkerDeclaration/Police/Table'
import { default as WorkerEntryLayoffDeclaration } from '../views/pages/WorkerDeclaration/Sgk/index'
import { default as WorkerTable } from '../views/pages/workerlist/list/Table'
import { default as CurrentAudit } from '../views/pages/current-audit/index'

import { default as ListAnnouncements } from '../views/pages/announcements/list/index'
import { default as AddAnnouncements } from '../views/pages/announcements/add/index'

export const setActiveTab = createAsyncThunk('tabManagement/setActiveTab', (index) => {
  return index
})

export const updateTab = createAsyncThunk('tabManagement/updateTab', (data) => {
  if (data.id === 'users-management') {
    return { value: Table, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'GendarmerieWorkerDeclaration') {
    return { value: GendarmerieTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'PoliceWorkerDeclaration') {
    return { value: PoliceTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'companies') {
    return { value: CompanyTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'edeclaration') {
    return { value: EDeclarationTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'companies-group') {
    return { value: CompanyGroupTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'home') {
    return { value: HomeTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'worker-list') {
    return { value: WorkerTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'edeclarationByService') {
    return { value: EDeclarationByServiceTable, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'workaccident') {
    return { value: WorkAccident, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'visit') {
    return { value: Visit, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'filemanager') {
    return { value: UserFileManager, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'payroll') {
    return { value: Payroll, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'workerEntryLayoffDeclaration') {
    return { value: WorkerEntryLayoffDeclaration, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'incentive') {
    return { value: Incentive, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'incentive-management') {
    return { value: IncentiveManagement, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'incentive-billing') {
    return { value: IncentiveBilling, data: { id: data.id, text: data.title, name: data.id } }
  }  else if (data.id === 'current-audit') {
    return { value: CurrentAudit, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'ListAnnouncements') {
    return { value: ListAnnouncements, data: { id: data.id, text: data.title, name: data.id } }
  } else if (data.id === 'AddAnnouncements') {
    return { value: AddAnnouncements, data: { id: data.id, text: data.title, name: data.id } }
  }

  return data
})

export const getUserDetail = createAsyncThunk('tabManagement/userDetail', (id) => {
  const response = axios.get(`/api/users/forUserDetail?id=${id}`)
  return response
})

export const createTab = createAsyncThunk('tabManagement/createTab', (data) => {
  // data => id,text,name
  if (data.name === 'KullaniciYonetimiDetay1') {
    return { value: userDetails, data }
  } else if (data.name === 'FirmaDetay1') {
    return { value: UserInfoCard, data }
  } else if (data.name === 'onayli-vizite-rapor-listesi') {
    return { value: ApprovedVisitList, data }
  } else if (data.name === 'SubProcessList') {
    return { value: SubProcessList, data }
  } else if (data.name === 'ProcessWorkers') {
    return { value: ProcessWorkers, data }
  }
})

export const deleteTab = createAsyncThunk('tabManagement/deleteTab', (index) => {
  return index
})

export const clearTabs = createAsyncThunk('tabManagement/clearTabs', () => {
  return []
})

export const appTabsManagement = createSlice({
  name: 'appTabsManagement',
  initialState: {
    tabData: 'Anasayfa',
    detailTabData: [],
    userDetailData: null,
    active: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateTab.fulfilled, (state, action) => {
        state.detailTabData[0] = action.payload
      })
      .addCase(createTab.fulfilled, (state, action) => {
        state.detailTabData.push(action.payload)
        state.active = 1
      })
      .addCase(clearTabs.fulfilled, (state, action) => {
        state.detailTabData = action.payload
      })
      .addCase(getUserDetail.fulfilled, (state, action) => {
        state.userDetailData = action.payload

        if (state.userDetailData.data.user.gender === 0) {
          state.userDetailData.data.user.gender = 'Kadin'
        }

        if (state.userDetailData.data.user.gender === 1) {
          state.userDetailData.data.user.gender = 'Erkek'
        }

        if (state.userDetailData.data.user.gender === 2) {
          state.userDetailData.data.user.gender = 'Belirtilmemis'
        }

        if (state.userDetailData.data.user.description === null) {
          state.userDetailData.data.user.description = 'Yok'
        }
      })
      .addCase(deleteTab.fulfilled, (state, action) => {
        state.detailTabData.splice(action.payload, 1)
        state.active = 0
      })
      .addCase(setActiveTab.fulfilled, (state, action) => {
        state.active = action.payload
      })
  }
})

export default appTabsManagement.reducer