// ** Reactstrap Imports
import {Fragment, useEffect, useState} from 'react'
import {
    Button,
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    CardTitle, Table
} from 'reactstrap'

import {useDispatch, useSelector} from 'react-redux'
import {Trash2, Eye, Plus, Edit, Download} from 'react-feather'
import {
    createPopupStatus,
    getData,
    getStatistic,
    handleOpenNewTab,
    editDataSet,
    removePopupStatus
} from "../store"
import {store} from '@store/store'

import {Pie} from 'react-chartjs-2'
import 'chart.js/auto'

import * as React from "react"
import CreatePopup from "./create-popup"
import DeletePopup from "./delete-popup"
import {DataGrid, trTR} from "@mui/x-data-grid"
import {IconButton, MenuItem, Select} from "@mui/material"
import Pagination from "@mui/material/Pagination"
import PaginationItem from "@mui/material/PaginationItem"

const columns = [
    {
        field: 'id',
        sortable: false,
        filterable: false,
        flex: 1,
        maxWidth: 70,
        headerClassName: 'headerStyle',
        renderHeader: () => (<strong>Sıra</strong>)
    },
    {
        field: 'title',
        renderHeader: () => (<strong>Başlık</strong>),
        sortable: false,
        filterable: false,
        flex: 1,
        headerClassName: 'headerStyle'
    },
    {
        headerClassName: 'headerStyle',
        sortable: false,
        filterable: false,
        renderHeader: () => (<strong>İşlemler</strong>),
        minWidth: 100,
        renderCell: row => (
            <div className='column-action'>
                <Eye size={18} className='cursor-pointer me-1' onClick={() => {
                    store.dispatch(handleOpenNewTab(row.row.fileView))
                }}/>
                <Edit size={18} className='cursor-pointer me-1' onClick={() => {
                    store.dispatch(createPopupStatus(true))
                    store.dispatch(editDataSet(row.row))
                }}/>
                <Trash2 size={18} className='cursor-pointer me-1' onClick={() => {
                    store.dispatch(removePopupStatus(true))
                    store.dispatch(editDataSet(row.row))
                }}/>

            </div>
        )
    }
]

function CustomPagination(props) {
    const handlePageSizeChange = (event) => {
        props.onPageSizeChange(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        props.onPageChange(newPage - 1)
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Select
                value={props.pageSize}
                onChange={handlePageSizeChange}
                style={{marginRight: '16px', marginTop: '-10px'}}
            >
                {props.pageSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color='primary'
                shape='circular'
                page={props.page + 1}
                count={props.rowCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple/>}
                onChange={handlePageChange}
            />
        </div>
    )
}

const AddAnnouncement = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.announcements)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)

    useEffect(() => {
        dispatch(getData({Page: page, PageSize: pageSize}))
        dispatch(getStatistic())
    }, [])

    const data = {
        labels: ['Toplam Kullanıcı Sayısı', 'Giriş Yapan Kullanıcı Sayısı'],
        datasets: [
            {
                label: '# of Votes',
                data: [store.statisticData?.totalPersonalCount, store.statisticData?.totalLoginPersonalCount],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }
        ]
    }

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <CardTitle tag='h4'>DUYURULAR </CardTitle>
                    <Button outline size='sm' className='btn-icon' onClick={() => dispatch(createPopupStatus(true))}>
                        <Plus size={14}/> Duyuru Ekle
                    </Button>
                </CardHeader>
            </Card>
            <Row>
                <Col md='6'>
                    <Card>
                        <CardHeader>
                            <CardTitle tag='h4'>YAYINLANAN DUYURULAR</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div style={{height: 540, width: '100%'}}>
                                <DataGrid
                                    rows={store.data}
                                    columns={columns}
                                    rowCount={store.total}
                                    pageSize={pageSize}
                                    paginationMode="server"
                                    sortingMode='server'
                                    filterMode='server'
                                    onPageChange={(params) => {
                                        setPage(params.page)
                                    }}
                                    onPageSizeChange={(params) => {
                                        setPageSize(params.pageSize)
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                    slots={{
                                        pagination: () => <CustomPagination
                                            page={page}
                                            pageSize={pageSize}
                                            rowCount={store.total}
                                            pageSizeOptions={[5, 10, 25]}
                                            onPageChange={(newPage) => {
                                                setPage(newPage)
                                            }}
                                            onPageSizeChange={(newPageSize) => {
                                                setPageSize(newPageSize)
                                            }}
                                        />
                                    }}
                                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </div>

                        </CardBody>
                    </Card>
                </Col>
                <Col md='6'>
                    <Card>
                        <CardHeader>
                            <CardTitle tag='h4'>HAREKET RAPORU</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead>
                                <th scope='col' className='text-nowrap'> TOPLAM KULLANICI SAYISI</th>
                                <th scope='col' className='text-nowrap'> GİRİŞ YAPILAN KULLANICI SAYISI</th>
                                <th scope='col' className='text-nowrap'> RAPOR AL</th>
                                </thead>
                                <tbody>
                                <tr key={1}>
                                    <td className='text-nowrap'>{store.statisticData?.totalPersonalCount}</td>
                                    <td className='text-nowrap'>{store.statisticData?.totalLoginPersonalCount}</td>
                                    <td className='text-nowrap'>
                                        <Download size={30} className='cursor-pointer me-1'/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            <Pie data={data}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <CreatePopup/>
            <DeletePopup/>
        </Fragment>
    )
}

export default AddAnnouncement
