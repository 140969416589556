// ** Third Party Components
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { getAllCompanies, getDecryptedCompanyServiceAccess } from './store'

// ** Reactstrap Imports
import { Button, Label, Form, Input, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap"

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'


const LoginPopup = ({ open, toggleSidebar }) => {
  const store = useSelector(state => state.workAccident)
  const dispatch = useDispatch()
  const [serviceAccessInformation, setServiceAccessInformation] = useState({})

  useEffect(() => {
    dispatch(getAllCompanies())
  }, [])

  async function onSelectionChanged(e) {
    if (e.value === 0 || e.value === undefined) {
      return
    }

    const selectedCompanyId = e.value
    const response = await getDecryptedCompanyServiceAccess(selectedCompanyId)

    if (serviceAccessInformation === null) {
      return
    }

    if (response.companyServices?.length === 0) {
      return
    }

    setServiceAccessInformation(response.companyServices[0])
  }

  function OpenWindowWithPost(url, windowoption, params) {
    const form = document.createElement("form")
    form.setAttribute("method", "post")
    form.setAttribute("action", url)
    form.setAttribute("target", "_blank")
    for (const i in params) {
      if (params.hasOwnProperty(i)) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = i
        input.value = params[i]
        form.appendChild(input)
      }
    }
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const loginUrl = "https://uyg.sgk.gov.tr/IsvBildirimFormu/kullanici_login.do"
    const options = "width=1000, height=600, left=100, top=100, resizable=yes, scrollbars=yes"
    const payload = {
      encrypff: 1,
      kullaniciAdi: serviceAccessInformation?.username,
      isyeriKodu: serviceAccessInformation?.suffix,
      isyeriSifresi: serviceAccessInformation?.companyPassword,
      guvenlikKodu: serviceAccessInformation?.securityCode,
      kaydet: " Giriş "
    }

    OpenWindowWithPost(loginUrl, options, payload)
  }

  function onSecurityCodeChanged(e) {
    setServiceAccessInformation({...serviceAccessInformation, securityCode: e.target.value})
  }

  return (
    <>
      <Modal isOpen={open} toggle={() => toggleSidebar()} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => toggleSidebar()}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>İş Kazasın Sistemine Giriş Yap</h1>
          </div>

          <Form>
            <Row>
              <Col>
                <Select options={store.companyGroupSelectData} onChange={onSelectionChanged} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <img className='mt-2' src="https://uyg.sgk.gov.tr/IsvBildirimFormu/Captcha.jpg?0.31273515533321783" />
              </Col>
              <Col md={8}>
                <Label className='form-label' for='securityCode'>
                  Güvenlik Kodu <span className='text-danger'>*</span>
                </Label>
                <Input id='securityCode' name='guvenlikKodu' placeholder='' onChange={onSecurityCodeChanged} />
              </Col>
            </Row>
            <Row>
              <Col className='d-flex mt-2 justify-content-end'>
                <Button className='me-1' color='primary' onClick={handleSubmit}>Kaydet</Button>
                <Button type='reset' color='secondary' outline onClick={toggleSidebar} >İptal</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default LoginPopup
