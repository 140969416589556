import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid, trTR  } from '@mui/x-data-grid'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import { Select, MenuItem, Grid, Card, Typography, Button, InputAdornment, Popover, TextField, CardContent} from '@mui/material'
import { BsFiletypeXls, BsFilter, BsSearch } from 'react-icons/bs'
import { getData} from './store'

function formatToTurkishLira(number) {
  return new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY'
  }).format(number)
}

const columns = [
  { field: 'name', headerName: 'Grup', minWidth: 300},
  { field: 'totalAmountCalculated', 
    headerName: 'Hesaplanan Toplam Tutar', 
    minWidth: 300,
    renderCell: (params) => (
      <>
      {formatToTurkishLira(params.row.totalAmountCalculated)}
      </>
    ) 
  },
  { field: 'approvedTotalAmount', 
    headerName: 'Onaylanan Toplam Tutar', 
    minWidth: 300,
    renderCell: (params) => (
      <>
      {formatToTurkishLira(params.row.approvedTotalAmount)}
      </>
    )
   },
  { field: 'difference',
    headerName: 'Fark', 
    minWidth: 300,
    renderCell: (params) => (
      <>
      {formatToTurkishLira(params.row.difference) === "₺0,00" ? (
        formatToTurkishLira(params.row.difference)) : (
        <div style={{color:'red'}}> {formatToTurkishLira(params.row.difference)} </div>
        )
      }
      </>
    )
   }
]

function CustomPagination(props) {
  const handlePageSizeChange = (event) => {
    props.onPageSizeChange(event.target.value)
  }

  const handlePageChange = (event, newPage) => {
    props.onPageChange(newPage - 1)
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Select
          value={props.pageSize}
          onChange={handlePageSizeChange}
          style={{ marginRight: '16px', marginTop: '-10px' }}
    >
          {props.pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
      </Select>
      <Pagination 
      color='primary'
      shape='circular'
      page={props.page + 1}
      count={props.rowCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={handlePageChange}
    />
    </div>
  )
}
const IncentiveBilling = () => {
  const dispatch = useDispatch()
  const store = useSelector(state => state.incentiveBilling)
  const authStore = useSelector(state => state.auth)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [sort, setSort] = useState('desc')
  const [sortColumn, setSortColumn] = useState('id')
  const [searchTerm, setSearchTerm] = useState('')

  
  function generateRandom() {
    const length = 8
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        let retVal = ""
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
    }
    return retVal
}


  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    if (sortModel.length > 0) {
      setSortColumn(sortModel[0].field)
      setSort(sortModel[0].sort)
    } else {
      setSortColumn('id')
      setSort('desc')
    }
  }, [])

  const getDataParameter = () => {
    return {
      page: page + 1,
      pageSize,
      q: searchTerm,
      sort,
      sortColumn
    }
  }

  const handleFilter = val => {
    setSearchTerm(val)
  }

  useEffect(() => {
    dispatch(
      getData(getDataParameter())
    )
  }, [dispatch, authStore.selectedCompanyGroup, page, pageSize])
  
  useEffect(() => {
    dispatch(
      getData(getDataParameter())
    )
  }, [sortColumn, sort, searchTerm])

    return (
        <>
          <Card className='mb-1'>
      <div style={{height:100, width: '100%'}}>
        <Grid container style={{ height: '100%' }} alignItems="center">
          <Grid item xs={6} container alignItems="center" justifyContent="flex-start">
            <Grid item>
            </Grid>
          </Grid>

          <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <TextField
                variant="outlined"
                placeholder="Ara"
                type='search'
                size='small'
                value={searchTerm}
                onChange={e => handleFilter(e.target.value)}
                sx={{ pt: 0,
                      pb: 0,               
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)' // Varsayılan MUI çerçeve rengi
                          },
                        '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.5)' // Varsayılan MUI çerçeve hover rengi
                          },
                        '&.Mui-focused fieldset': {
                        borderColor: 'primary.main' // Varsayılan MUI odaklanma rengi
                          }
                        } 
                      }}
                InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsSearch />
                  </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Card>
    <Card>
    <div style={{ height: 540, width: '100%'}}>
      <DataGrid
        rows={store.data}
        getRowId={generateRandom} 
        columns={columns} 
        rowCount={store.total}
        pageSize={pageSize}
        paginationMode="server"
        sortingMode='server'
        onSortModelChange={handleSortModelChange}
        onPageChange={(params) => {
          setPage(params.page)
      }}
        onPageSizeChange={(params) => {
          setPageSize(params.pageSize)
      }}
        pageSizeOptions={[5, 10, 25]} 
        slots={{
          pagination: () => <CustomPagination 
            page={page}
            pageSize={pageSize}
            rowCount={store.total}
            pageSizeOptions={[5, 10, 25]}
            onPageChange={(newPage) => {
                setPage(newPage)
            }}
            onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize)
            }} 
          />
        }}     
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
    </Card>
        </>
    )
}

export default IncentiveBilling