import Select from 'react-select'

// ** Reactstrap Imports
import { Fragment, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { DownloadCloud, FileText, X } from 'react-feather'
import { Button, Col, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { addBulkUser, selectGroup } from './store'

// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import toast from 'react-hot-toast'
import toplu_kullanici_ekle_ornek from '../../../assets/usermanagement/toplu_kullanici_ekle_ornek.xlsx'
// import { addWorkerDeclarationFile } from './store'

const FilePopup = ({ open, toggleFilePopup }) => {
  const [files, setFiles] = useState([])
  // const [active, setActive] = useState('1')
  const dispatch = useDispatch()
  const store = useSelector(state => state.users)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: result => {
      setFiles([...result.map(file => Object.assign(file))])
    }
  })

  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />
    } else {
      return <FileText size='28' />
    }
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
    }
  }

  const sendUserExcellData = () => {
    if (files.length === 0) {
      toast.error("Dosya boş olmamalı")
      return
    }
    const data = new FormData()
    data.append('UserData', files[0], files[0].name)
    const postgroupIds = store.userData?.companyGroupIds || []
    postgroupIds.forEach((element, i) => {
      data.append(`GroupIds[${i}]`, element)
    })
    dispatch(addBulkUser(data))
    setFiles([])
  }

  const fileList = files.map((file, index) => (
    <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
      <div className='file-details d-flex align-items-center'>
        <div className='file-preview me-1'>{renderFilePreview(file)}</div>
        <div>
          <p className='file-name mb-0'>{file.name}</p>
          <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const _downloadExcel = () => {
    const link = document.createElement('a')
    const filename = 'toplu_kullanici_ekle_ornek.xlsx'
    link.setAttribute('href', toplu_kullanici_ekle_ornek)
    link.setAttribute('download', filename)
    link.click()
  }

  const closePopup = () => {
    setFiles([])
    dispatch(selectGroup([]))
    toggleFilePopup()
  }

  return (
    <>
      <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Toplu Kullanıcı Ekle</h1>
          </div>
          <Fragment>
            <Row>
              <Col lg={12} md={12}>
                <Button.Ripple block outline color='warning' onClick={() => _downloadExcel()}>
                  Örnek Excel İndir
                </Button.Ripple>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div className='d-flex align-items-center justify-content-center flex-column'>
                    <DownloadCloud size={64} />
                    <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                    <p className='text-secondary'>
                      Dosyaları buraya bırakın veya tıklayın{' '}
                      <a href='/' onClick={e => e.preventDefault()}>
                        Araştır
                      </a>{' '}
                    </p>
                  </div>
                </div>
                {files.length ? (
                  <Fragment>
                    <ListGroup className='my-2'>{fileList}</ListGroup>
                  </Fragment>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <div className='mb-1'>
                  <Label className='form-label' for='period'>
                    <span className='bold'>Yetkili Olunan Grup</span>
                  </Label>
                  <Select
                    isClearable={false}
                    options={store.companyGroups}
                    className='react-select'
                    classNamePrefix='select'
                    onChange={(item) => dispatch(selectGroup(item))}
                    value={store.companyGroups.filter(x => store.userData?.companyGroupIds?.includes(x.value))}
                    id='groupIds'
                    name='groupIds'
                    isMulti
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <Button type='button' className='me-1' color='primary' onClick={sendUserExcellData}>
                  Kaydet
                </Button>
                <Button type='reset' color='secondary' outline onClick={closePopup} >
                  İptal
                </Button>
              </Col>
            </Row>
          </Fragment>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FilePopup
