export const columns = [
    {
        name: 'T.C',
        sortable: true,
        minWidth: '125px',
        sortField: 'identificationNumber',
        selector: row => row.identificationNumber,
        cell: row => row.identificationNumber
    },
    {
        name: 'Adres',
        minWidth: '75px',
        sortable: true,
        sortField: 'adress',
        selector: row => row.adress,
        cell: row => row.adress
    },
    {
        name: 'İşe Giriş Tarihi',
        minWidth: '125px',
        sortable: true,
        sortField: 'workstartdate',
        selector: row => row.workstartdate,
        cell: row => row.workstartdate
    },
    {
        name: 'Görevi',
        minWidth: '75px',
        sortable: true,
        sortField: 'jobname',
        selector: row => row.jobName,
        cell: row => row.jobName
    },
    {
        name: 'Hata',
        minWidth: '75px',
        sortable: true,
        sortField: 'error',
        selector: row => row.error,
        cell: row => row.error
    }
  
]
