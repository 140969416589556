// ** Icons Imports
import {store} from '@store/store'
import {Edit, File, Trash2, FileText, Image} from 'react-feather'
import {BsFiletypeXlsx} from 'react-icons/bs'
// ** Reactstrap Imports
import {
    deleteCompanyGroup,
    getCompanyGroup,
    toggleUpdatePopupClose,
    getRoleList,
    CreatePastPeriodAnalysis,
    ExportPastPeriodAnalysis
} from './store'
import React from "react"
import {Button} from 'reactstrap'
import {Box, CircularProgress, Typography} from "@mui/material"
import PropTypes from "prop-types"


function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
}

export const columns = [
    {
        name: 'Grup Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'name',
        selector: row => row.name,
        cell: row => row.name
    },
    {
        name: 'Geçmiş Dönem Analizi',
        sortable: true,
        minWidth: '100px',
        maxWidth: '250px',
        sortField: 'name',
        selector: row => row.id,
        cell: row => (
            <>
                <Button className='add-new-user me-1' color='primary' onClick={(e) => {
                    e.preventDefault()
                    store.dispatch(CreatePastPeriodAnalysis(row.id))
                }}>
                    Analiz Yap
                </Button>
            </>
        )
    },
    {
        name: 'Sonuç Rapor',
        sortable: true,
        minWidth: '100px',
        maxWidth: '200px',
        sortField: 'name',
        selector: row => row.file,
        cell: row => {
            return row.pastPeriod.id < 1 ? (<></>) ? row.pastPeriod.succesPercentInt < 100 : (<>
                <CircularProgressWithLabel
                    value={parseInt(row.pastPeriod.succesPercentInt)}/></>) : (
                <>
                    <BsFiletypeXlsx size={25} className={`cursor-pointer me-1`} onClick={(e) => {
                        e.preventDefault()
                        store.dispatch(ExportPastPeriodAnalysis(row.id))
                    }}/>
                </>
            )
        }
    },
    {
        name: 'İşlemler',
        minWidth: '100px',
        maxWidth: '150px',
        cell: row => (
            <>
                <div className='column-action'>
                    <Edit size={20} className={`cursor-pointer me-1`} onClick={(e) => {
                        e.preventDefault()
                        store.dispatch(getCompanyGroup(row.id))
                        store.dispatch(toggleUpdatePopupClose(true))
                    }}/>
                </div>
                <div className='column-action'>
                    <FileText size={20} className={`cursor-pointer me-1`}
                              onClick={() => store.dispatch(getRoleList(row.id))}/>
                </div>
                <div className='column-action'>
                    <Trash2 size={20} className={`cursor-pointer me-1`} onClick={(e) => {
                        e.preventDefault()
                        store.dispatch(deleteCompanyGroup({id: row.id}))
                    }}/>
                </div>
            </>
        )
    }
]
