import { lazy } from 'react'
const Company = lazy(() => import('../../views/pages/company/list/index'))
const CompanyView = lazy(() => import('../../views/pages/company/view/index'))
const WorkerListView = lazy(() => import('../../views/pages/workerlist/list/index'))
const CompanyGroup = lazy(() => import('../../views/pages/company-group/index'))
const CompanyRoutes = [
  {
    path: '/firmalar',
    element: <Company />
  },
  {
    path: '/firma/:id',
    element: <CompanyView />
  },
  {
    path: '/calisan-listesi',
    element: <WorkerListView />
  },
  {
    path: '/firma-grup',
    element: <CompanyGroup />
  }
]

export default CompanyRoutes
