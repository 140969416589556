import {useDispatch, useSelector} from 'react-redux'
import {getIncentiveSubProcess, showProcessData} from './store'
import React, {useEffect, useState} from 'react'
import { store } from "@store/store"

import {DataGrid, trTR} from '@mui/x-data-grid'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import {
    Select,
    MenuItem,
    Card,
    Typography,
    CircularProgress,
    Box,
    IconButton
} from '@mui/material'

import PropTypes from 'prop-types'
import {CheckCircle, AlertTriangle, Loader, AlertOctagon} from 'react-feather'

import {BsFileZip, BsFileImage} from 'react-icons/bs'

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired
}

const download = (row) => {
    const link = document.createElement('a')
    link.setAttribute('href', row)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.setAttribute('download', row.split('/')[-1])
    link.click()
}

const columns = [
    {
        field: 'companyName',
        headerName: 'İşyeri',
        minWidth: 200,
        sortable: false,
        renderCell: (params) => (
            <>

                {params.row.queueName === "IncentiveWorkerIdentificationRequested" ?  <>
                    <button onClick={() => store.dispatch(showProcessData({id: params.row.id, name: 'ProcessWorkers', text: params.row.companyName}))}
                            className="btn btn-outline-primary" id={params.row.id}>{params.row.companyName}</button>
                </> : params.row.companyName}

            </>
        )
    },
    {
        field: 'queueName',
        headerName: 'Kuyruk Adı',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                {params.row.queueName === "IncentiveDisabledWorkerIdentificationRequested" ? "Engelli Teşvik Tanımlama" : ""}
                {params.row.queueName === "IncentiveWorkerIdentificationRequested" ? "Teşvik Tanımlama" : ""}
                {params.row.queueName === "ExportDisabledWorkersRequested" ? "Engelli Teşvik Kaydetme" : ""}
                {params.row.queueName === "ExportIncentiveWorkersRequested" ? "Teşvik Excel İndirme ve Tarih Kontrolü" : ""}
                {params.row.queueName === "IncentiveCalculationResultRequested" ? "Grup Teşvik Hesaplama" : ""}
                {params.row.queueName === "IncentiveCalculationRequested" ? "Firma Sonuç Hesaplama" : ""}
            </>
        )
    },
    {
        field: 'isIdentificationQueue',
        headerName: 'Tanımlama Kuyruğu',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                {!params.row.isIdentificationQueue ? (
                    <>-</>) : (
                    params.row.isSuccess ? (
                        <CheckCircle color="green" size={40}/>) : (
                        <AlertTriangle color="red" size={40}/>)
                )}
            </>
        )
    },
    {
        field: 'isExportQueue',
        headerName: 'Export Kuyruğu',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                {!params.row.isExportQueue ? (
                    <>-</>) : (
                    params.row.isSuccess ? (
                        <CheckCircle color="green" size={40}/>) : (
                        <AlertTriangle color="red" size={40}/>)
                )}
            </>
        )
    },
    {
        field: 'isCalculateQueue',
        headerName: 'Hesaplama Kuyruğu',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                {!params.row.isCalculateQueue ? (
                    <>-</>) : (
                    params.row.isSuccess ? (
                        <CheckCircle color="green" size={40}/>) : (
                        <AlertTriangle color="red" size={40}/>)
                )}
            </>
        )
    },
    {field: 'message', headerName: 'Mesaj', minWidth: 250, sortable: false, filterable: false},
    {field: 'startDate', headerName: 'İşlem Başlangıç Tarihi', minWidth: 250, filterable: false},
    {field: 'endDate', headerName: 'İşlem Bitiş Tarihi', minWidth: 250, sortable: false, filterable: false},
    {
        field: 'errorImage',
        headerName: 'Sonuç Rapor',
        minWidth: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                {console.log(params.row)}
                {params.row.errorImage !== null ? <IconButton size='60' onClick={() => download(params.row.errorImage)}>
                    <BsFileImage size={30}/>
                </IconButton> : null}
                {params.row.errorDocument !== null ? <IconButton size='60' onClick={() => download(params.row.errorDocument)}>
                    <BsFileZip size={30}/>
                </IconButton> : null}
            </>
        )
    }
]

function CustomPagination(props) {
    const handlePageSizeChange = (event) => {
        props.onPageSizeChange(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        props.onPageChange(newPage - 1)
    }
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Select
                value={props.pageSize}
                onChange={handlePageSizeChange}
                style={{marginRight: '16px', marginTop: '-10px'}}
            >
                {props.pageSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color='primary'
                shape='circular'
                page={props.page + 1}
                count={props.rowCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple/>}
                onChange={handlePageChange}
            />
        </div>
    )
}

const SubProcessList = ({data}) => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentiveManagement)
    const authStore = useSelector(state => state.auth)

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [searchTerm, setSearchTerm] = useState([])
    const [sort, setSort] = useState('desc')
    const [sortColumn, setSortColumn] = useState('id')

    const handleSortModelChange = React.useCallback((sortModel) => {
        // Here you save the data you need from the sort model
        if (sortModel.length > 0) {
            setSortColumn(sortModel[0].field)
            setSort(sortModel[0].sort)
        } else {
            setSortColumn('id')
            setSort('desc')
        }
    }, [])


    const getDataParameter = () => {
        return {
            page: page + 1,
            pageSize,
            sort,
            sortColumn,
            searchTerm,
            id: data
        }
    }


    const handleFilter = val => {
        setSearchTerm(val.items)
    }

    useEffect(() => {
        dispatch(getIncentiveSubProcess(getDataParameter()))
    }, [dispatch, authStore.selectedCompanyGroup, page, pageSize, sort, searchTerm])

    useEffect(() => {
        dispatch(getIncentiveSubProcess(getDataParameter()))

    }, [])

    return (
        <>
            <Card>
                <div style={{height: 540, width: '100%'}}>
                    <DataGrid
                        rows={store.subProcessData}
                        columns={columns}
                        rowCount={store.subProcessTotal}
                        pageSize={pageSize}
                        paginationMode="server"
                        sortingMode='server'
                        filterMode='server'
                        onFilterModelChange={handleFilter}
                        onSortModelChange={handleSortModelChange}
                        onPageChange={(params) => {
                            setPage(params.page)
                        }}
                        onPageSizeChange={(params) => {
                            setPageSize(params.pageSize)
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        slots={{
                            pagination: () => <CustomPagination
                                page={page}
                                pageSize={pageSize}
                                rowCount={store.subProcessTotal}
                                pageSizeOptions={[5, 10, 25]}
                                onPageChange={(newPage) => {
                                    setPage(newPage)
                                }}
                                onPageSizeChange={(newPageSize) => {
                                    setPageSize(newPageSize)
                                }}
                            />
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </Card>
        </>
    )
}

export default SubProcessList
