// ** React Imports

// ** Icons Imports
import { store } from '@store/store'
import { Copy, Edit, Edit2, Eye, Settings, Trash2, User, FileText } from 'react-feather'

// ** Third Party Components
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Reactstrap Imports
import { Badge, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { deleteUser, getUser, getUserDetails, showHideSidebar, getRoleList } from './store'

// ** Renders Client Columns
const renderClient = row => {
  if (row.profilePhoto !== "") {
    return <Avatar className='me-1' img={`${process.env.REACT_APP_API_URL}/${row.profilePhoto}`} width='32' height='32' />
  } else {
    return (
      <Avatar
        initials
        className='me-1'
        color={row.avatarColor || 'light-primary'}
        content={row.firstAndLastName || 'John Doe'}
      />
    )
  }
}

// ** Renders Role Columns
const renderRole = row => {
  const roleObj = {
    ConsultantAdmin: {
      class: 'text-success',
      icon: User
    },
    Consultant: {
      class: 'text-info',
      icon: User
    },
    CustomerAdmin: {
      class: 'text-warning',
      icon: User
    },
    Customer: {
      class: 'text-danger',
      icon: User
    }
  }

  const Icon = roleObj[row.role] ? roleObj[row.role].icon : Edit2

  return (
    <span className='text-truncate text-capitalize align-middle'>
      <Icon size={18} className={`${roleObj[row.role] ? roleObj[row.role].class : ''} me-50`} />
      {row.role}
    </span>
  )
}

const statusObj = {
  Aktif: 'light-success',
  Pasif: 'light-secondary'
}

const onCopy = () => {
  toast.success('Link Kopyalandı !')
}


export const columns = [
  {
    name: 'Kullanıcı',
    sortable: true,
    minWidth: '172px',
    sortField: 'name',
    selector: row => row.firstAndLastName,
    cell: row => (
      <div className='d-flex justify-content-left align-items-center'>
        {renderClient(row)}
        <div className='d-flex flex-column'>
          <a
            className='user_name text-truncate text-body'
            onClick={(e) => {
              e.preventDefault()
              store.dispatch(getUserDetails({ id: row.id, name: 'KullaniciYonetimiDetay1', text: row.userName }))
            }}
          >
            <span className='fw-bolder'>{row.firstAndLastName}</span>
          </a>
          <small className='text-truncate text-muted mb-0'>{row.userName}</small>
        </div>
      </div>
    )
  },
  {
    name: 'Mail',
    sortable: true,
    minWidth: '172px',
    sortField: 'name',
    selector: row => row.email,
    cell: row => row.email
  },
  {
    name: 'Cinsiyet',
    sortable: true,
    minWidth: '172px',
    sortField: 'name',
    selector: row => row.gender,
    cell: row => row.gender
  },
  {
    name: 'Rol',
    sortable: true,
    minWidth: '172px',
    sortField: 'name',
    selector: row => row.role,
    cell: row => renderRole(row)
  },
  {
    name: 'Durum',
    sortable: true,
    minWidth: '172px',
    sortField: 'name',
    selector: row => row.status,
    cell: row => (
      <Badge className='text-capitalize' color={statusObj[row.status]} pill>
        {row.status}
      </Badge>
    )
  },
  {
    name: 'Login Linki',
    sortable: true,
    minWidth: '172px',
    sortField: 'name',
    selector: row => row.passwordLink,
    cell: row => (
      row.passwordLink !== "" && row.status !== "Aktif" ? <CopyToClipboard onCopy={onCopy} text={`${document.location.origin}/sifre-olustur/${row.passwordChangeLink}`}>
        <Button.Ripple color='warning' href={row.passwordLink} size={"sm"} className='me-1'>
          <Copy size={14} />
          <span className='align-middle'>Linki Kopyala</span>
        </Button.Ripple>
      </CopyToClipboard> : null
    )
  },
  {
    name: 'İşlemler',
    minWidth: '100px',
    cell: row => (
      <div className='column-action'>
        <UncontrolledDropdown>
          <DropdownToggle tag='div' className='btn btn-sm'>
            <Settings size={14} className='cursor-pointer' />
          </DropdownToggle>
          <DropdownMenu container="body">
            <DropdownItem
              tag='a'
              href='/'
              id={row.id}
              className='w-100'
              onClick={e => {
                e.preventDefault()
                store.dispatch(getUser(row.id))
                store.dispatch(showHideSidebar(!store.toggleSidebar))
              }}
            >
              <Edit size={14} className='me-50' />
              <span className='align-middle'>Düzenle</span>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className='w-100'
              onClick={() => store.dispatch(getRoleList(row.id))}
            >
              <FileText size={14} className='me-50' />
              <span className='align-middle'>Yetkiler</span>
            </DropdownItem>
            <DropdownItem
              tag='a'
              href='/'
              id={row.id}
              className='w-100'
              onClick={e => {
                e.preventDefault()
                store.dispatch(getUserDetails({ id: row.id, name: 'KullaniciYonetimiDetay1', text: row.userName }))
              }}
            >
              <Eye size={14} className='me-50' />
              <span className='align-middle'>Detay Goruntule</span>
            </DropdownItem>
            <DropdownItem
              tag='a'
              href='/'
              id={row.id}
              className='w-100'
              onClick={e => {
                e.preventDefault()
                store.dispatch(deleteUser({ id: row.id }))
              }}
            >
              <Trash2 size={14} className='me-50' />
              <span className='align-middle'>Sil</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    )
  }
]
