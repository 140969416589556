import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import toast from "react-hot-toast"
import {createTab} from "../../../../redux/tabManagement"

export const getIncentiveSubProcess = createAsyncThunk('incentive/getIncentiveSubProcess', async (params) => {
    const path = `/api/Incentive/GetIncentiveSubProcess`
    const response = await axios.post(path, params)
    return {
        params,
        data: response.data.processList,
        totalPages: response.data.pageCount
    }
})

export const getIncentiveProcessWorker = createAsyncThunk('incentive/getIncentiveProcessWorker', async (params) => {
    const path = `/api/Incentive/GetIncentiveProcessWorker`
    const response = await axios.post(path, params)
    return {
        params,
        data: response.data.processList,
        totalPages: response.data.pageCount
    }
})

export const getIncentiveProcessAllGroup = createAsyncThunk('incentive/getIncentiveProcessAll', async (params) => {
    const path = `/api/Incentive/GetProcessAllGroup`
    const response = await axios.post(path, params)
    return {
        params,
        data: response.data.processList,
        totalPages: response.data.pageCount
    }
})

export const getSchedulerList = createAsyncThunk('incentive/getSchedulerList', async (data) => {
    const response = await axios.get(`/api/Scheduler`, {
        params: {
            SchedulerType: data
        }
    })
    return {
        data: response.data.scheduler
    }
})

export const retryButtonDisabled = createAsyncThunk('incentive/retryButtonDisabled', async (data) => {
    return data
})

export const reTry = createAsyncThunk('incentive/reTry', async (data, {dispatch}) => {
    await dispatch(retryButtonDisabled(true))
    toast.loading('İşlem Tamamlanana Kadar Bekleyiniz...')
    const response = await axios.get(`/api/Scheduler/retry/${data}`)
    toast.remove()
    await dispatch(retryButtonDisabled(false))
    if (response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getSchedulerList())
    } else {
        toast.error("Bir Sorun Oluştu")
    }
})

export const resumeProcess = createAsyncThunk('incentive/resumeProcess', async (data, {dispatch}) => {
    toast.loading('İşlem Tamamlanana Kadar Bekleyiniz...')
    const response = await axios.get(`/api/Incentive/ResumeProcess/${data}`)
    toast.remove()
    if (response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getSchedulerList())
    } else {
        toast.error("Bir Sorun Oluştu")
    }
})

export const downloadInvoices = createAsyncThunk('incentive/downloadInvoices', async (data) => {
    toast.loading('Fatura Listesi Oluşturulana Kadar Bekleyiniz...')
    debugger
    const response = await axios.get(`/api/Incentive/GetIncentiveCalculationExport`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {
            Period: data
        }
    })
    toast.remove()
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'fatura-sonuc.xlsx')
        document.body.appendChild(link)
        link.click()
    } else {
        toast.error("Bir Sorun Oluştu")
    }
})

export const downloadSchedulerExcel = createAsyncThunk('incentive/downloadSchedulerExcel', async (id) => {
    const response = await axios.get(`/api/Scheduler/download/${id}`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})


export const showProcessData = createAsyncThunk("incentive/showProcessData", (data, {dispatch}) => {
    dispatch(createTab(data))
})

export const incentiveManagementSlice = createSlice({
    name: 'incentiveManagement',
    initialState: {
        total: 1,
        params: {},
        data: [],
        subProcessTotal: 1,
        workerProcessTotal: 1,
        subProcessParams: {},
        workerProcessParams: {},
        subProcessData: [],
        workerProcessData: [],
        schedulerData: [],
        retryButtonDisabled: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getIncentiveProcessAllGroup.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getIncentiveSubProcess.fulfilled, (state, action) => {
                state.subProcessData = action.payload.data
                state.subProcessParams = action.payload.params
                state.subProcessTotal = action.payload.totalPages
            })
            .addCase(getIncentiveProcessWorker.fulfilled, (state, action) => {
                state.workerProcessData = action.payload.data
                state.workerProcessParams = action.payload.params
                state.workerProcessTotal = action.payload.totalPages
            })
            .addCase(getSchedulerList.fulfilled, (state, action) => {
                state.schedulerData = action.payload.data
            })
            .addCase(retryButtonDisabled.fulfilled, (state, action) => {
                state.retryButtonDisabled = action.payload
            })
    }
})

export default incentiveManagementSlice.reducer