export const columns = [
    {
        name: 'SICIL KODU',
        sortable: true,
        minWidth: '100px',
        sortField: 'registrationCode',
        selector: row => row.registrationCode,
        cell: row => row.registrationCode
    },
    {
        name: 'T.C NO',
        sortable: true,
        minWidth: '250px',
        sortField: 'identificationNumber',
        selector: row => row.identificationNumber,
        cell: row => row.identificationNumber
    },
    {
        name: 'DONEM ICINDE CALISTIGI GUN',
        sortable: true,
        minWidth: '350px',
        sortField: 'workDay',
        selector: row => row.workDay,
        cell: row => row.workDay
    },
    {
        name: 'DONEM ICINDE RAPORLU GUN',
        sortable: true,
        minWidth: '350px',
        sortField: 'reportDay',
        selector: row => row.reportDay,
        cell: row => row.reportDay
    },
    {
        name: 'DONEM ICINDE DIGER EKSIK GUN',
        sortable: true,
        minWidth: '350px',
        sortField: 'workOffDay',
        selector: row => row.workOffDay,
        cell: row => row.workOffDay
    },
    {
        name: 'SGK MATRAHI',
        sortable: true,
        minWidth: '100px',
        sortField: 'sgkBase',
        selector: row => row.sgkBase,
        cell: row => row.sgkBase
    },
    {
        name: 'ISE GIRIS TARIHI',
        sortable: true,
        minWidth: '100px',
        sortField: 'workStartDate',
        selector: row => row.workStartDate,
        cell: row => row.workStartDate
    },
    {
        name: 'ISTEN CIKIS TARIHI',
        sortable: true,
        minWidth: '100px',
        sortField: 'workEndDate',
        selector: row => row.workEndDate,
        cell: row => row.workEndDate
    },
    {
        name: 'BELGE TURU',
        sortable: true,
        minWidth: '100px',
        sortField: 'docType',
        selector: row => row.docType,
        cell: row => row.docType
    },
    {
        name: 'MESLEK KODU',
        sortable: true,
        minWidth: '100px',
        sortField: 'jobCode',
        selector: row => row.jobCode,
        cell: row => row.jobCode
    },
    {
        name: 'HATA 1',
        sortable: true,
        minWidth: '100px',
        sortField: 'error1',
        selector: row => row.error1,
        cell: row => row.error1
    },
    {
        name: 'HATA 1 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription1',
        selector: row => row.errorDescription1,
        cell: row => row.errorDescription1
    },
    {
        name: 'HATA 2',
        sortable: true,
        minWidth: '100px',
        sortField: 'error2',
        selector: row => row.error2,
        cell: row => row.error2
    },
    {
        name: 'HATA 2 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription2',
        selector: row => row.errorDescription2,
        cell: row => row.errorDescription2
    },
    {
        name: 'HATA 3',
        sortable: true,
        minWidth: '100px',
        sortField: 'error3',
        selector: row => row.error3,
        cell: row => row.error3
    },
    {
        name: 'HATA 3 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription3',
        selector: row => row.errorDescription3,
        cell: row => row.errorDescription3
    },
    {
        name: 'HATA 4',
        sortable: true,
        minWidth: '100px',
        sortField: 'error4',
        selector: row => row.error4,
        cell: row => row.error4
    },
    {
        name: 'HATA 4 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription4',
        selector: row => row.errorDescription4,
        cell: row => row.errorDescription4
    },
    {
        name: 'HATA 5',
        sortable: true,
        minWidth: '100px',
        sortField: 'error5',
        selector: row => row.error5,
        cell: row => row.error5
    },
    {
        name: 'HATA 5 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription5',
        selector: row => row.errorDescription5,
        cell: row => row.errorDescription5
    },
    {
        name: 'HATA 6',
        sortable: true,
        minWidth: '100px',
        sortField: 'error6',
        selector: row => row.error6,
        cell: row => row.error6
    },
    {
        name: 'HATA 6 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription6',
        selector: row => row.errorDescription6,
        cell: row => row.errorDescription6
    },
    
    {
        name: 'HATA 6',
        sortable: true,
        minWidth: '100px',
        sortField: 'error6',
        selector: row => row.error6,
        cell: row => row.error6
    },
    {
        name: 'HATA 6 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription6',
        selector: row => row.errorDescription6,
        cell: row => row.errorDescription6
    },
    {
        name: 'HATA 7',
        sortable: true,
        minWidth: '100px',
        sortField: 'error7',
        selector: row => row.error7,
        cell: row => row.error7
    },
    {
        name: 'HATA 7 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription7',
        selector: row => row.errorDescription7,
        cell: row => row.errorDescription7
    },
    {
        name: 'HATA 8',
        sortable: true,
        minWidth: '100px',
        sortField: 'error8',
        selector: row => row.error8,
        cell: row => row.error8
    },
    {
        name: 'HATA 8 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription8',
        selector: row => row.errorDescription8,
        cell: row => row.errorDescription8
    },
    {
        name: 'HATA 9',
        sortable: true,
        minWidth: '100px',
        sortField: 'error9',
        selector: row => row.error9,
        cell: row => row.error9
    },
    {
        name: 'HATA 9 ACIKLAMA',
        sortable: true,
        minWidth: '100px',
        sortField: 'errorDescription9',
        selector: row => row.errorDescription9,
        cell: row => row.errorDescription9
    }


]
