// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {createTab} from "../../../../redux/tabManagement"

// ** Axios Imports
import axios from "axios"
import toast from "react-hot-toast"

export const getData = createAsyncThunk("companies/getData", async params => {
    const url = `/api/companies`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.companies,
        totalPages: response.data.pageCount
    }
})
export const allCompaniesAddPopupState = createAsyncThunk("appCompanies/list/AllCompaniesAddPopup", (status) => {
    return status
})
export const newCompanyGroupData = createAsyncThunk("appCompanies/list/newCompanyGroupData", (status) => {
    return status
})
export const addAllCompanyControlFile = createAsyncThunk("appCompanies/addfile", async (data) => {
    return data
})
export const getCompanyDetails = createAsyncThunk("user/getCompanyDetails", (data, {dispatch}) => {
    dispatch(createTab(data))
})
export const toggleSidebar = createAsyncThunk("api/toggleSidebar", async (status) => {
    return status
})
export const setRefreshData = createAsyncThunk("api/setRefreshData", async (status) => {
    return status
})
export const setUpdateId = createAsyncThunk("api/setUpdateId", async (id) => {
    return id
})
export const setPasswordShow = createAsyncThunk("api/setPasswordShow", async (status) => {
    return status
})
export const setAddPasswordShow = createAsyncThunk("api/setAddPasswordShow", async (status) => {
    return status
})
export const setSendButtonDisabled = createAsyncThunk("api/setSendButtonDisabled", async (status) => {
    return status
})

export const getCompany = createAsyncThunk("appCompanies/getCompany", async id => {
    const response = await axios.get(`/api/companies/${id}`)
    return response.data
})

export const getCompanyGroupData = createAsyncThunk("companies/getCompanyGroupData", async () => {
    const url = `/api/companygroup?PageSize=1000&page=1`
    const response = await axios.get(url)
    const selectData = []
    selectData.push({label: "Seçiniz", value: "0"})
    response.data.companyGroups.map((item) => {
        selectData.push({label: item.name, value: item.id})
    })

    return selectData
})

export const getPasswordData = createAsyncThunk("companies/companyserviceaccess", async id => {
    const url = `/api/companyserviceaccess?companyId=${id}`
    const response = await axios.get(url)
    return response.data.companyServices
})

export const addCompany = createAsyncThunk("api/addCompanies", async (data, {dispatch, getState}) => {
    await dispatch(setSendButtonDisabled(true))
    const response = await axios.post("/api/companies", data)
    await dispatch(toggleSidebar(false))
    await dispatch(setSendButtonDisabled(false))
    if (response && response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getData(getState().company.params))
    }
})

export const updateCompany = createAsyncThunk("api/updateCompanies", async (data) => {
    const response = await axios.put("/api/companies", data)
    if (response && response.status === 200) {
        toast.success("İşleminiz Başarılı")
    }
})

export const setCompanyServiceAccess = createAsyncThunk("api/setCompanyServiceAccess", async (data, {getState}) => {
    let response = null
    if (getState().company.serviceAccessUpdateId === -1) {
        response = await axios.post("/api/companyserviceaccess", data)
    } else {
        data.id = getState().company.serviceAccessUpdateId
        response = await axios.put("/api/companyserviceaccess", data)
    }
    if (response && response.status === 200) {
        toast.success("İşleminiz Başarılı")
        return true
    }
    return false
})

export const deleteCompany = createAsyncThunk("api/deleteCompanies", async (id, {dispatch, getState}) => {
    await axios.delete(`/api/companies/${id}`)
    await dispatch(getData(getState().company.params))
    await dispatch(getAllData())
    return id
})

export const getCompanyPassword = createAsyncThunk("api/getCompanyPassword", async (id, {getState}) => {
    const response = await axios.get(`/api/CompanyServiceAccess/`, {
        params: {
            CompanyId: getState().company.loginPopupCompanyId,
            ServiceType: 0,
            DecryptData: true
        }
    })
    if (response.status === 200) {
        return response.data.companyServices.length > 0 ? response.data.companyServices[0] : null
    }
})

export const setCompanyGroupId = createAsyncThunk("api/deleteCompanies", async (id) => {
    return id
})
export const modalShow = createAsyncThunk("api/modalShow", async (id) => {
    return id
})

export const showLoginPopup = createAsyncThunk("api/showLoginPopup", async (data) => {
    return data
})

export const addTotallyCompanyAddControl = createAsyncThunk("appCompanies/control", async (companydata, {
        dispatch,
        getState
    }) => {
        await dispatch(setSendButtonDisabled(true))
        const fileData = getState().company.controlfile
        const periodData = getState().company.companyGroupId
        const companyGroupName = companydata.newCompanyGroupName


        const data = new FormData()
        if (fileData) data.append("file", fileData, fileData.name)
        data.append("companyGroupId", periodData || 0)
        data.append("newCompanyGroupName", companyGroupName || "")
        const response = await axios.post(`api/companies/control`, data)
        if (response.status === 200) {
            await dispatch(getData(getState().company.params))
            toast.success("Kayıt Başarılı Bir Şekilde Yapıldı.")
            await dispatch(allCompaniesAddPopupState(false))
        }
        await dispatch(setSendButtonDisabled(false))
        return response

    }
)
export const appCompanySlice = createSlice({
    name: "appCompanies",
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        companyGroupSelectData: [],
        companyPasswords: [],
        selectedCompany: null,
        allCompaniesAddPopupState: false,
        filePopupStatus: false,
        fileData: null,
        filePeriod: 0,
        responseExcel: null,
        controlPending: true,
        controlfile: null,
        companyGroupId: 0,
        newCompanyGroupName: "",
        toggleSideBar: false,
        refreshData: false,
        serviceAccessUpdateId: -1,
        passwordShow: false,
        addPasswordShow: false,
        passwordChangeCompanyId: -1,
        disabledSendButton: false,
        loginPopupCompanyId: -1,
        loginPopupShow: false,
        companyPassword: null
    },

    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getCompanyGroupData.fulfilled, (state, action) => {
                state.companyGroupSelectData = action.payload
            })
            .addCase(getCompany.fulfilled, (state, action) => {
                state.selectedCompany = action.payload
            })
            .addCase(getPasswordData.fulfilled, (state, action) => {
                state.companyPasswords = action.payload
            })
            .addCase(allCompaniesAddPopupState.fulfilled, (state, action) => {
                state.allCompaniesAddPopupState = action.payload
            })
            .addCase(addAllCompanyControlFile.fulfilled, (state, action) => {
                state.controlfile = action.payload
            })
            .addCase(setCompanyGroupId.fulfilled, (state, action) => {
                state.companyGroupId = action.payload
            })
            .addCase(newCompanyGroupData.fulfilled, (state, action) => {
                state.newCompanyGroupName = action.payload
            })
            .addCase(toggleSidebar.fulfilled, (state, action) => {
                state.toggleSideBar = action.payload
            })
            .addCase(setCompanyServiceAccess.fulfilled, (state, action) => {
                state.refreshData = action.payload
            })
            .addCase(setRefreshData.fulfilled, (state, action) => {
                state.refreshData = action.payload
            })
            .addCase(setUpdateId.fulfilled, (state, action) => {
                state.serviceAccessUpdateId = action.payload
            })
            .addCase(setPasswordShow.fulfilled, (state, action) => {
                state.passwordShow = action.payload
            })
            .addCase(setAddPasswordShow.fulfilled, (state, action) => {
                state.addPasswordShow = action.payload
            })
            .addCase(modalShow.fulfilled, (state, action) => {
                state.passwordChangeCompanyId = action.payload
                state.addPasswordShow = true
            })
            .addCase(showLoginPopup.fulfilled, (state, action) => {
                state.loginPopupCompanyId = action.payload.id
                state.loginPopupShow = action.payload.show
            })
            .addCase(getCompanyPassword.fulfilled, (state, action) => {
                state.companyPassword = action.payload
            })
            .addCase(setSendButtonDisabled.fulfilled, (state, action) => {
                state.disabledSendButton = action.payload
            })

    }
})

export default appCompanySlice.reducer
