import { Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap'
import { detailPopupStateClose } from '../store'
import { useDispatch, useSelector } from 'react-redux'
import '@styles/react/libs/flatpickr/flatpickr.scss'


const SidebarEditUser = ({open}) => {

  const store = useSelector(state => state.visit)
  const dispatch = useDispatch()

  const headerStyle = {
    fontWeight: 'bold',
    marginLeft : 20
  }


  return (
    <>
      <Modal isOpen={open} toggle={() => dispatch(detailPopupStateClose(false))} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => dispatch(detailPopupStateClose(false))}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Detay</h1>
            <p>Vizite Detay Ekranı</p>
          </div>

          <Row>
            { <Col md='12'>
              <p><span style={headerStyle}>Ad</span> : {store?.detailVisitData?.data?.detail?.name}</p>
              <p><span style={headerStyle}>Soyad</span> : {store?.detailVisitData?.data?.detail?.surname}</p>
              <p><span style={headerStyle}>Vaka Adi</span> : {store?.detailVisitData?.data?.detail?.caseName}</p>
              <p><span style={headerStyle}>Medular Rapor ID</span> : {store?.detailVisitData?.data?.detail?.medulaReportId}</p>
              <p><span style={headerStyle}>Isverene Bildiris Tarihi</span> : {store?.detailVisitData?.data?.detail?.notificationEmployerDate}</p>
              <p><span style={headerStyle}>Tesis Kodu</span> : {store?.detailVisitData?.data?.detail?.facility}</p>
              <p><span style={headerStyle}>Tesis Adi</span> : {store?.detailVisitData?.data?.detail?.facilityName}</p>
              <p><span style={headerStyle}>T.C. Kimlik No</span> : {store?.detailVisitData?.data?.detail?.identificationNumber}</p>
              <p><span style={headerStyle}>Rapor Bitis Tarihi</span> : {store?.detailVisitData?.data?.detail?.reportEndDate}</p>
              <p><span style={headerStyle}>Ayakta Baslangic Tarihi</span> : {store?.detailVisitData?.data?.detail?.standingStartDate}</p>
              <p><span style={headerStyle}>Ayakta Bitis Tarihi</span> : {store?.detailVisitData?.data?.detail?.standingEndDate}</p>
              <p><span style={headerStyle}>Bashekim Onay Tarihi</span> : {store?.detailVisitData?.data?.detail?.chefDoctorApproveDate}</p>
              <p><span style={headerStyle}>Poliklinik Tarihi</span> : {store?.detailVisitData?.data?.detail?.policlinicDate}</p>
              <p><span style={headerStyle}>Rapor Takip No</span> : {store?.detailVisitData?.data?.detail?.reportTrackingNumber}</p>
            </Col>}
          </Row> 

          <Button type='button' color='secondary' outline onClick={() => dispatch(detailPopupStateClose(false))} >
              Çık
            </Button>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarEditUser