// ** Reactstrap Imports
import { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import { DownloadCloud } from 'react-feather'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading, uploadFile } from './store'

const FilePopup = ({ open, toggleFilePopup }) => {
  const dispatch = useDispatch()
  const store = useSelector(state => state.userFileManager)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: result => {
      const file = result[0]
      const data = new FormData()
      data.append('file', file, file.name)
      data.append('Path', store.path)
      dispatch(uploadFile(data))
      dispatch(showLoading(true))
      toggleFilePopup()
    }
  })

  return (
    <>
      <Modal isOpen={open} toggle={() => toggleFilePopup()} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => toggleFilePopup()}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Dosya Yükle</h1>
          </div>
          <Fragment>
            <Row>
              <Col sm='12'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div className='d-flex align-items-center justify-content-center flex-column'>
                    <DownloadCloud size={64} />
                    <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                    <p className='text-secondary'>
                      Dosyaları buraya bırakın veya tıklayın{' '}
                      <a href='/' onClick={e => e.preventDefault()}>
                        Araştır
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Fragment>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FilePopup
