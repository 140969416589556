// ** React Import
import { Fragment, useEffect, useState } from "react"

// ** Third Party Components
import "@styles/react/libs/file-uploader/file-uploader.scss"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import { useDropzone } from "react-dropzone"
import { DownloadCloud, FileText, X } from "react-feather"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
//import toast from 'react-hot-toast'
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import {
  addAllCompanyControlFile,
  addTotallyCompanyAddControl,
  allCompaniesAddPopupState,
  setCompanyGroupId
} from "../store"

const AllCompaniesAddPopup = () => {

  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const store = useSelector(state => state.company)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: result => {
      const file = result[0]
      dispatch(addAllCompanyControlFile(file))
      setFiles([...result.map(file => Object.assign(file))])
    }
  })
  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
    }
  }

  const renderFilePreview = file => {
    if (file.type.startsWith("image")) {
      return <img className="rounded" alt={file.name} src={URL.createObjectURL(file)} height="28" width="28" />
    } else {
      return <FileText size="28" />
    }
  }
  // ** Vars
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()
  // ** Function to handle form submit
  const onSubmit = data => {
    dispatch(addTotallyCompanyAddControl(data))
  }
  const fileList = files.map((file, index) => (
    <ListGroupItem key={`${file.name}-${index}`} className="d-flex align-items-center justify-content-between">
      <div className="file-details d-flex align-items-center">
        <div className="file-preview me-1">{renderFilePreview(file)}</div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button color="danger" outline size="sm" className="btn-icon" onClick={() => handleRemoveFile(file)}>
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const closePopup = () => {
    dispatch(allCompaniesAddPopupState(false))
    reset()
    setFiles([])
  }

  useEffect(() => {
    reset()
    setFiles([])
  }, [store.allCompaniesAddPopupState])

  return (
    <>
      <Modal isOpen={store.allCompaniesAddPopupState} toggle={closePopup} className="modal-dialog-centered modal-lg">
        <ModalHeader className="bg-transparent" toggle={closePopup}>
        </ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Toplu Firma Ekleme</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Fragment>
              <Row>
                <Col sm="12">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="d-flex align-items-center justify-content-center flex-column">
                      <DownloadCloud size={64} />
                      <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                      <p className="text-secondary">
                        Dosyaları buraya bırakın veya tıklayın{" "}
                        <a href="/" onClick={e => e.preventDefault()}>
                          Araştır
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  {files.length ? (
                    <Fragment>
                      <ListGroup className="my-2">{fileList}</ListGroup>
                    </Fragment>
                  ) : null}
                </Col>
              </Row>

              <div className="mb-1">
                <Label className="form-label" for="companyGroupId">
                  Grup <span className="text-danger">*</span>
                </Label>
                <Controller
                  name="companyGroupId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      options={store.companyGroupSelectData}
                      className="react-select"
                      classNamePrefix="select"
                      invalid={errors.companyGroupId && true}
                      {...field}
                      onChange={(event) => {
                        dispatch(setCompanyGroupId(event.value))

                      }}
                    />
                  )}
                />
              </div>
              <div className="mb-1">
                {store.companyGroupId > 0 ? <>
                </> : <Controller
                  name="newCompanyGroupName"
                  control={control}
                  render={({ field }) => (
                    <Input id="newCompanyGroupName" placeholder=" Yeni Grup Oluştur"
                           invalid={errors.newCompanyGroupName && true} {...field} />
                  )}
                />}
              </div>
              <Button type="submit" className="me-1" color="primary" disabled={store.disabledSendButton}>
                Kaydet
              </Button>
              <Button type="reset" color="secondary" outline onClick={closePopup}>
                İptal
              </Button>
            </Fragment>
          </Form>

        </ModalBody>
      </Modal>
    </>
  )
}


export default AllCompaniesAddPopup
