// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  Row,
  Col,
  Label,
  Button,
  Input,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
// ** React Imports
import { useEffect, useState } from "react"

import { store } from "@store/store"

// ** Third Party Components
import Cleave from 'cleave.js/react'
import { ChevronDown, Settings, Edit } from "react-feather"
import DataTable from "react-data-table-component"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { setCompanyServiceAccess, setUpdateId, setPasswordShow } from "../store"


// ** Styles
import "@styles/react/libs/tables/react-dataTable-component.scss"

const selectServiceData = [{ value: 0, label: "SGK" }, { value: 1, label: "Jandarma" }, { value: 2, label: "Emniyet" }]
const selectServiceTypePoliceData = [{ value: 1, label: "Emniyet (Konaklama Sektörü)" }, { value: 2, label: "Emniyet" }]
export const columns = [
  {
    name: "Servis",
    selector: row => row.serviceType,
    cell: row => {
      if (row.serviceType === 0) return "SGK"
      if (row.serviceType === 2) return "Emniyet"
      if (row.serviceType === 1) return "Jandarma"
    }
  },
  {
    name: "Hata Mesajı",
    selector: row => row.errorMessage,
    cell: row => row.errorMessage
  },
  {
    name: "İşlemler",
    minWidth: "172px",
    sortField: "name",
    selector: row => row.id,
    cell: row => {
      return <div className="column-action">
        <UncontrolledDropdown>
          <DropdownToggle tag="div" className="btn btn-sm">
            <Settings size={14} className="cursor-pointer" />
          </DropdownToggle>
          <DropdownMenu container="body">
            <DropdownItem
              tag="a"
              href="/"
              id={row.id}
              className="w-100"
              onClick={e => {
                e.preventDefault()
                store.dispatch(setUpdateId(row.id))
                store.dispatch(setPasswordShow(true))
              }}
            >
              <Edit size={14} className="me-50" />
              <span className="align-middle">Düzenle</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    }
  }
]
const CompanyServiceAccesses = ({ id }) => {
  const [passwordData, setPasswordData] = useState([])
  const [serviceTypeList, setServiceTypeList] = useState([])
  const [serviceType, setServiceType] = useState(null)
  const dispatch = useDispatch()
  const storeCompany = useSelector(state => state.company)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }
  // ** Hook
  const {
    reset,
    control,
    // setError,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const optionsTcKimlikNo = { blocks: [11], numericOnly: true }

  const getPassword = () => {
    axios.get(`/api/companyserviceaccess?companyId=${id}`).then(item => {
      const typeList = []
      item.data.companyServices.forEach(element => {
        typeList.push(element.serviceType)
      })
      setServiceTypeList(typeList)
      setPasswordData(item.data.companyServices)
    })
  }
  const handleReset = () => {
    reset()
    dispatch(setUpdateId(-1))
    dispatch(setPasswordShow(!storeCompany.passwordShow))
    setServiceType(null)
    setSelectedOption(null)
  }

  useEffect(() => {
    getPassword()
  }, [])
  useEffect(() => {
    getPassword()
    dispatch(setPasswordShow(false))
    // dispatch(setRefreshData(!storeCompany.refreshData))
    setServiceType(null)
    setSelectedOption(null)
  }, [storeCompany.refreshData])

  const onSubmit = data => {
    dispatch(
      setCompanyServiceAccess({
        CompanyId: id,
        ServiceType: serviceType ? serviceType.value : -1,
        Username: data.username,
        Suffix: data.suffix,
        RegistrationNumber: data.registrationNumber,
        SystemPassword: data.systemPassword,
        CompanyPassword: data.companyPassword,
        PoliceDeclarationServiceType: selectedOption?.value ?? 0
      })
    )
  }
  return (
    <>
      <Card>
        <CardHeader tag="h4">Şifreler
          <Button color="primary" onClick={() => dispatch(setPasswordShow(true))}>
            Ekle
          </Button>
        </CardHeader>
        <div className="react-dataTable user-view-account-projects">
          <DataTable
            noHeader
            responsive
            columns={columns}
            data={passwordData}
            className="react-dataTable"
            sortIcon={<ChevronDown size={10} />}
          />
        </div>
      </Card>

      <Modal isOpen={storeCompany.passwordShow} toggle={handleReset} className="modal-dialog-centered modal-lg">
        <ModalHeader className="bg-transparent" toggle={handleReset}></ModalHeader>
        <ModalBody className="px-sm-5 pt-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Şifre Ekle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 pt-75">
              <Col xs={12}>
                <Label className="form-label" for="serviceType">
                  Servis
                </Label>
                <Controller
                  control={control}
                  id="serviceType"
                  name="serviceType"
                  render={({ field }) => (
                    <Select
                      isClearable={false}
                      options={(storeCompany.serviceAccessUpdateId !== -1 && passwordData.filter(x => x.id === storeCompany.serviceAccessUpdateId).length > 0 ? selectServiceData.filter(z => z.value === passwordData.filter(x => x.id === storeCompany.serviceAccessUpdateId)[0].serviceType) : selectServiceData.filter(z => !serviceTypeList.includes(z.value)))}
                      className="react-select"
                      classNamePrefix="select"
                      id="period"
                      {...field}
                      onChange={data => setServiceType(data)}
                    />
                  )}
                />
              </Col>
              {serviceType?.value === 2 && <>
                <Col xs={12}>
                  <Label className="form-label" for="applyType">
                    Bildirim Türü <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="applyType"
                    control={control}
                    render={() => (
                      <Select
                        isClearable={false}
                        options={selectServiceTypePoliceData}
                        className="react-select"
                        classNamePrefix="select"
                        value={selectedOption}
                        onChange={handleSelectChange} />
                    )}
                  />

                </Col>
                {selectedOption?.value === 1 ? <Col xs={12}>
                  <Label className="form-label" for="suffix">
                    TC Kimlik No
                  </Label>
                  <Controller
                    control={control}
                    id="registrationNumber"
                    name="registrationNumber"
                    render={({ field }) => (
                      <Cleave className='form-control' {...field} id="registrationNumber" invalid={errors.registrationNumber && true} options={optionsTcKimlikNo} />
                    )}
                  />
                </Col> : null}
              </>}
              <Col xs={12}>
                <Label className="form-label" for="username">
                  Kullanıcı Adı
                </Label>
                <Controller
                  control={control}
                  id="username"
                  name="username"
                  render={({ field }) => (
                    <Input {...field} id="username" invalid={errors.username && true} />
                  )}
                />
              </Col>
              {serviceType?.value === 0 ? <>
                <Col xs={12}>
                  <Label className="form-label" for="suffix">
                    İşyeri Kodu
                  </Label>
                  <Controller
                    control={control}
                    id="suffix"
                    name="suffix"
                    render={({ field }) => (
                      <Input {...field} id="suffix" invalid={errors.suffix && true} />
                    )}
                  />
                </Col>
                <Col xs={12}>
                  <Label className="form-label" for="companyPassword">
                    İşyeri Şifresi
                  </Label>
                  <Controller
                    control={control}
                    id="companyPassword"
                    name="companyPassword"
                    render={({ field }) => (
                      <Input {...field} id="companyPassword" invalid={errors.companyPassword && true} />
                    )}
                  />
                </Col>
              </> : null}
              <Col xs={12}>
                <Label className="form-label" for="systemPassword">
                  Sistem Şifresi
                </Label>
                <Controller
                  control={control}
                  id="systemPassword"
                  name="systemPassword"
                  render={({ field }) => (
                    <Input {...field} id="systemPassword" invalid={errors.systemPassword && true} />
                  )}
                />
              </Col>

              <Col xs={12} className="text-center mt-2 pt-50">
                <Button type="submit" className="me-1" color="primary">
                  Kaydet
                </Button>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={handleReset}>
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CompanyServiceAccesses
