// ** React Import
import { useState } from "react"
// ** Third Party Components
import { Controller, useForm } from 'react-hook-form'

// ** Reactstrap Imports
import { Button, Form, Col, Table, Row, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { useDispatch, useSelector } from 'react-redux'
import { toggleRolePopup, selectRole, updateRole } from './store'

const RolePopup = () => {
  // ** States
  const [searchModule, setSearchModule] = useState("")
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.companyGroups)
  // ** Vars
  const {
    handleSubmit,
    reset
  } = useForm()
  // ** Function to handle form submit
  const onSubmit = data => {
    console.log(data)
    dispatch(
      updateRole("")
    )
    reset()
  }

  const popupClose = () => {
    dispatch(toggleRolePopup(false))
    reset()
  }

  const changeSelect = (id, status) => {
    dispatch(selectRole({ id, status }))
  }

  return (
    <>
      <Modal
        isOpen={store.toggleRolePopup}
        toggle={popupClose}
        className='modal-dialog-centered modal-lg'
      >
        <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
        <ModalBody className='px-5 pb-5'>
          <div className='text-center mb-4'>
            <h1>Rol</h1>
            <p>Firma yetkilerini düzenle</p>
          </div>
          <Row tag='form' onSubmit={handleSubmit(onSubmit)}>
          <Col xs={12}>
              <Label className='form-label' for='roleName'>
                Rol ismi
              </Label>
              <Input id='roleName' onChange={(element) => setSearchModule(element.target.value.toLocaleLowerCase())} placeholder='Role ismi yazın' />
            </Col>
            <Col xs={12}>
              <h4 className='mt-2 pt-50'>Roller</h4>
              <Table className='table-flush-spacing' responsive>
                <tbody>
                  {(searchModule ? store.companyRoleList.filter(x => x.moduleName.toLocaleLowerCase().includes(searchModule)) : store.companyRoleList)?.map((role, index) => {
                    return (
                      <tr key={index}>
                        <td className='text-nowrap fw-bolder'>{role.moduleName}</td>
                        <td>
                          <div className='d-flex'>
                            <div className='form-check me-3 me-lg-5'>
                              <Input type='radio' checked={store.selectCompanyRoleList.filter(x => x.id === role.id)[0].authorizationState} onChange={() => changeSelect(role.id, true)} name={role.id} id={`true-${role.id}`} />
                              <Label className='form-check-label' for={`true-${role.id}`}>
                                Evet
                              </Label>
                            </div>
                            <div className='form-check me-3 me-lg-5'>
                              <Input type='radio' checked={store.selectCompanyRoleList.filter(x => x.id === role.id)[0].authorizationState === false} onChange={() => changeSelect(role.id, false)} name={role.id} id={`false-${role.id}`} />
                              <Label className='form-check-label' for={`false-${role.id}`}>
                                Hayır
                              </Label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
            <Col className='text-center mt-2' xs={12}>
              <Button type='submit' color='primary' className='me-1'>
                Kaydet
              </Button>
              <Button type='reset' outline onClick={popupClose}>
                İptal
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default RolePopup
