// You can customize the template with the help of this file

//Template config options
const flatpickrConfig = {
  altInput: true,
  altFormat: "d.m.Y",
  dateFormat: "Y-m-d",
  locale: 'tr'
}

export default flatpickrConfig
