import { lazy } from 'react'

const ListAnnouncements = lazy(() => import('../../views/pages/announcements/list/index'))
const AddAnnouncements = lazy(() => import('../../views/pages/announcements/add/index'))

const AnnouncementRoutes = [
  {
    path: '/duyurular',
    element: <ListAnnouncements />
  },
  {
    path: '/duyuru-ekle',
    element: <AddAnnouncements />
  }
]

export default AnnouncementRoutes
