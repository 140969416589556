// ** React Imports
import React, { Fragment, memo, useEffect } from "react"

// ** Third Party Components
import classnames from "classnames"

// ** Store & Actions
import {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden
} from "@store/layout"
import { useDispatch, useSelector } from "react-redux"
import { deleteTab, setActiveTab } from "../../../../redux/tabManagement"

// ** ThemeConfig
import themeConfig from "@configs/themeConfig"

// ** Styles
import "animate.css/animate.css"
import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

const LayoutWrapper = (props) => {
  // ** Props
  // const { children, routeMeta } = props
  const { routeMeta } = props

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state)
  const storeTab = useSelector((state) => state.appTabsManagement)

  const navbarStore = store.navbar
  const layoutStored = store.layout.layout
  const contentWidth = store.layout.contentWidth
  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === "horizontal" && !routeMeta) ||
    (layoutStored.layout === "horizontal" && routeMeta && !routeMeta.appLayout)
  const Tag = appLayoutCondition ? "div" : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (
        routeMeta.contentWidth &&
        routeMeta.contentWidth === store.layout.contentWidth
      ) {
        dispatch(handleContentWidth(themeConfig.layout.contentWidth))
      }
      if (
        routeMeta.menuCollapsed &&
        routeMeta.menuCollapsed === store.layout.menuCollapsed
      ) {
        dispatch(handleMenuCollapsed(!store.layout.menuCollapsed))
      }
      if (
        routeMeta.menuHidden &&
        routeMeta.menuHidden === store.layout.menuHidden
      ) {
        dispatch(handleMenuHidden(!store.layout.menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      }
    }
    return () => cleanUp()
  }, [routeMeta])

  useEffect(() => {
    console.log(storeTab.active)
  }, [storeTab.active])

  return (
    <div
      className={classnames("app-content content overflow-hidden", {
        [routeMeta ? routeMeta.className : ""]:
          routeMeta && routeMeta.className,
        "show-overlay": navbarStore.query.length
      })}
    >
      <React.Fragment>
        <Nav tabs>
          {storeTab.detailTabData.map((elem, i) => (
            <NavItem>
              <NavLink active={storeTab.active === i}>
                <a
                  onClick={() => {
                    dispatch(setActiveTab(i))
                  }}
                >
                  {elem.data.text} &nbsp;

                </a>
                <a onClick={() => {
                  dispatch(deleteTab(i))
                }}
                >
                  <Badge
                    hidden={i === 0}
                    color="danger"
                  >
                    x
                  </Badge>
                </a>
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent className="py-50" activeTab={storeTab.active}>
          {storeTab.detailTabData.map((elem, i) => (
            <TabPane tabId={i}>
              <elem.value data={elem.data.id}></elem.value>
            </TabPane>
          ))}
        </TabContent>
      </React.Fragment>

      <div className="content-overlay"></div>
      <div className="header-navbar-shadow" />
      <div
        className={classnames({
          "content-wrapper": routeMeta && !routeMeta.appLayout,
          "content-area-wrapper": routeMeta && routeMeta.appLayout,
          "container-xxl p-0": contentWidth === "boxed"
        })}
      >
        {/* <Tag {...(appLayoutCondition ? { className: "content-body" } : {})}>
          {children}
        </Tag> */}
      </div>
    </div>
  )
}

export default memo(LayoutWrapper)
