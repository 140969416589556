import '@styles/react/libs/flatpickr/flatpickr.scss'
import {Fragment} from 'react'
import {useForm} from 'react-hook-form'
// ** Custom Components
import Cleave from 'cleave.js/react'
import {useDropzone} from 'react-dropzone'
import {DownloadCloud, FileText, X} from 'react-feather'

import Sidebar from '@components/sidebar'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {Button, Form, Input, Label, ListGroup, ListGroupItem} from 'reactstrap'
import {addUser, changeData, editData, profilePhoto, selectGroup, selectRole} from './store'

const SidebarNewUsers = ({open, toggleSidebar}) => {

    const dispatch = useDispatch()
    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop: result => {
            dispatch(profilePhoto(result))
        }
    })
    const store = useSelector(state => state.users)
    const {
        handleSubmit,
        formState: {errors}
    } = useForm()

    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28'/>
        } else {
            return <FileText size='28'/>
        }
    }

    const handleRemoveFile = file => {
        const uploadedFiles = store.profilePhoto
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        dispatch(profilePhoto([...filtered]))
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }

    const fileList = store.profilePhoto.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>{renderFilePreview(file)}</div>
                <div>
                    <p className='file-name mb-0'>{file.name}</p>
                    <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                <X size={14}/>
            </Button>
        </ListGroupItem>
    ))

    const onSubmit = () => {
        const postData = new FormData()
        postData.append('FirstName', store.userData?.firstName ?? "")
        postData.append('LastName', store.userData?.lastName ?? "")
        if (store.profilePhoto.length > 0) {
            postData.append('ProfilePhoto', store.profilePhoto[0], store.profilePhoto[0].name)
        }
        postData.append('IdentityNumber', store.userData?.identityNumber ?? "")
        postData.append('RoleName', store.userData?.roles ?? "")
        postData.append('Password', store.userData?.password ?? "")
        postData.append('Id', store.userData?.id ?? 0)
        const postgroupIds = store.userData?.companyGroupIds || []
        postgroupIds.forEach((element, i) => {
            postData.append(`GroupIds[${i}]`, element)
        })
        if (store.userData.id && store.userData.id > 0) {
            dispatch(editData(postData))
        } else {
            dispatch(addUser(postData))
        }
    }

    return (
        <>
            <Sidebar
                size='lg'
                open={open}
                title='Yeni Kullanıcı'
                headerClassName='mb-1'
                contentClassName='pt-0'
                toggleSidebar={toggleSidebar}
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-1'>
                        <Label className='form-label' for='firstName'>
                            Ad <span className='text-danger'>*</span>
                        </Label>
                        <Input id='firstName' name='firstName' onChange={(item) => dispatch(changeData({
                            label: "firstName",
                            value: item.currentTarget.value
                        }))} value={store.userData?.firstName} placeholder='' invalid={errors.name && true}/>
                        <Input style={{display: 'none'}} id='userId' onChange={(item) => dispatch(changeData({
                            label: "userId",
                            value: item.currentTarget.value
                        }))} name='userId' placeholder='' value={store.userData?.userId} invalid={errors.name && true}/>
                    </div>

                    <div className='mb-1'>
                        <Label className='form-label' for='lastName'>
                            Soyad <span className='text-danger'>*</span>
                        </Label>
                        <Input id='lastName' onChange={(item) => dispatch(changeData({
                            label: "lastName",
                            value: item.currentTarget.value
                        }))} name='firslastNametName' value={store.userData?.lastName} placeholder=''
                               invalid={errors.name && true}/>
                    </div>
                    <div className='mb-1'>
                        <Label className='form-label' for='identityNumber'>
                            Email / T.C. NO <span className='text-danger'>*</span>
                        </Label>
                        <Cleave className='form-control' id='identityNumber' onChange={(item) => dispatch(changeData({
                            label: "identityNumber",
                            value: item.currentTarget.value
                        }))} name='identityNumber' value={store.userData?.identityNumber} placeholder=''
                                invalid={errors.name && true}/>
                    </div>
                    {
                        store.userData ? <div className='mb-1'>
                            <Label className='form-label' for='password'>
                                Şifre <span className='text-danger'>*</span>
                            </Label>
                            <Cleave className='form-control' id='password'
                                    onChange={(item) => dispatch(changeData({
                                        label: "password",
                                        value: item.currentTarget.value
                                    }))} name='password' value={store.userData?.password} placeholder=''
                                    invalid={errors.password && true}/>
                        </div> : null
                    }
                    <div className='mb-1'>
                        <Label className='form-label' for='roleName'>
                            Rol <span className='text-danger'>*</span>
                        </Label>
                        <Select
                            isClearable={false}
                            options={store.roleData}
                            className='react-select'
                            classNamePrefix='select'
                            name='roleName'
                            onChange={(item) => dispatch(selectRole(item))}
                            value={store.roleData.filter(x => x.label === store.userData?.roles)}
                        />
                    </div>
                    <div className='mb-1'>
                        <Label className='form-label' for='groupIds'>
                            Yetkili Olunan Grup
                        </Label>
                        <Select
                            isClearable={false}
                            options={store.companyGroups}
                            className='react-select'
                            classNamePrefix='select'
                            onChange={(item) => dispatch(selectGroup(item))}
                            value={store.companyGroups.filter(x => store.userData?.companyGroupIds?.includes(x.value))}
                            id='groupIds'
                            name='groupIds'
                            isMulti
                        />
                        <Input style={{display: 'none'}} id='userId' name='userId' placeholder='' value={store.userId}
                               invalid={errors.name && true}/>
                    </div>
                    <div className='mb-1'>
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                <DownloadCloud size={64}/>
                                <h5>Profil Fotoğrafı yüklemek için tıklayın</h5>
                                <p className='text-secondary'>
                                    Dosyaları buraya bırakın veya tıklayın{' '}
                                    <a href='/' onClick={e => e.preventDefault()}>
                                        Araştır
                                    </a>{' '}
                                </p>
                            </div>
                        </div>
                        {store.profilePhoto.length ? (
                            <Fragment>
                                <ListGroup className='my-2'>{fileList}</ListGroup>
                            </Fragment>
                        ) : null}
                    </div>

                    <Button type='submit' className='me-1' color='primary'>
                        Kaydet
                    </Button>
                    <Button type='reset' color='secondary' outline onClick={toggleSidebar}>
                        İptal
                    </Button>
                </Form>
            </Sidebar>
        </>
    )
}

export default SidebarNewUsers