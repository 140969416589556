// ** React Import
import { useEffect, useState } from "react"
// ** Third Party Components
import Flatpickr from "react-flatpickr"
import { Controller, useForm } from "react-hook-form"
import Cleave from "cleave.js/react"
import Select from "react-select"
// ** Reactstrap Imports
import flatpickrConfig from "@configs/flatpickrConfig"
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"

// ** Store & Actions
import "@styles/react/libs/flatpickr/flatpickr.scss"
import { useDispatch, useSelector } from "react-redux"
import { addWorkerDeclaration, getCompanyList } from "./store"

// ** Utils
import { sendDateFormat } from "@utils"

const defaultValues = {
  email: "",
  contact: "",
  company: "",
  fullName: "",
  username: "",
  country: null
}

const SidebarNewUsers = ({ open, toggleSidebar }) => {
  // ** Store Vars
  const [companyId, setCompanyId] = useState(null)

  const dispatch = useDispatch()
  const store = useSelector(state => state.police)
  const authStore = useSelector(state => state.auth)

  // ** Vars
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })
  // ** Function to handle form submit

  const onSubmit = data => {
    dispatch(
      addWorkerDeclaration({
        identificationNumber: data.identificationNumber ?? "",
        residenceAddress: data.residenceAddress ?? "",
        employmentDate: sendDateFormat(data.employmentDate) ?? null,
        title: data.title ?? "",
        Type: 2, //police
        SyncStatus: 5,
        SecurityKey: data.securityKey,
        IsPoliceHotelWorkerDeclarationEmploye: companyId?.isPoliceHotelWorkerDeclarationEmploye,
        CompanyId: companyId !== null ? companyId.value : 0
      })
    )

  }
  const popupClose = () => {
    toggleSidebar()
    reset({
      identificationNumber: "",
      residenceAddress: "",
      employmentDate: "",
      title: "",
      securityKey: ""
    })
    setCompanyId(null)
  }

  useEffect(() => {
    dispatch(getCompanyList(1))
  }, [dispatch, authStore.selectedCompanyGroup])

  useEffect(() => {
    reset({
      identificationNumber: "",
      residenceAddress: "",
      employmentDate: "",
      title: "",
      securityKey: ""
    })
    setCompanyId(null)
  }, [store.sidebarOpen])

  const optionsIdentityNumber = { blocks: [11], numericOnly: true }

  return (
    <>
      <Modal isOpen={open} toggle={popupClose} className="modal-dialog-centered modal-lg">
        <ModalHeader className="bg-transparent" toggle={popupClose}></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Emniyet Tek Çalışan Bildirme</h1>
            <p>Emniyet Tek Çalışan Bildirim Ekranı</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-1">
              <Label className="form-label" for="company">
                Sicil <span className="text-danger">*</span>
              </Label>
              <Select
                isClearable={false}
                options={store.companyList}
                className="react-select"
                classNamePrefix="select"
                value={companyId}
                onChange={data => setCompanyId(data)}
              />
            </div>
            <div className="mb-1">
              <Label className="form-label" for="identificationNumber">
                TC Kimlik Numarası <span className="text-danger">*</span>
              </Label>
              <Controller
                name="identificationNumber"
                control={control}
                render={({ field }) => (
                  <Cleave className="form-control" id="identificationNumber" placeholder=""
                          invalid={errors.name && true} {...field} options={optionsIdentityNumber} />
                )}
              />
            </div>
            <div className="mb-1">
              <Label className="form-label" for="residenceAddress">
                Adres <span className="text-danger">*</span>
              </Label>
              <Controller
                name="residenceAddress"
                control={control}
                render={({ field }) => (
                  <Input type="textarea" name="text" id="residenceAddress" rows="3" placeholder=""
                         invalid={errors.residenceAddress && true} {...field} />
                )}
              />
            </div>
            <div className="mb-1">
              <Label className="form-label" for="employmentDate">
                İşe Giriş Tarihi <span className="text-danger">*</span>
              </Label>
              <Controller
                name="employmentDate"
                control={control}
                render={({ field }) => (
                  <Flatpickr className="form-control" id="employmentDate" options={flatpickrConfig}
                             invalid={errors.employmentDate && true} {...field} />
                )}
              />
            </div>
            <div className="mb-1">
              <Label className="form-label" for="title">
                Görevi <span className="text-danger">*</span>
              </Label>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input id="title" placeholder="" invalid={errors.title && true} {...field} />
                )}
              />
            </div>

            {companyId && companyId.isPoliceHotelWorkerDeclarationEmploye ? <div className="mb-1">
              <Label className="form-label" for="title">
                Güvenlik Kodu <span className="text-danger">*</span>
              </Label>
              <Controller
                name="securityKey"
                control={control}
                render={({ field }) => (
                  <Input id="securityKey" placeholder="" invalid={errors.securityKey && true} {...field} />
                )}
              />
            </div> : null}


            <Button type="submit" className="me-1" color="primary">
              Kaydet
            </Button>
            <Button type="reset" color="secondary" outline onClick={popupClose}>
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarNewUsers
