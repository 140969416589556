// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
export const getData = createAsyncThunk('workerdeclaration/getData', async (params, { getState }) => {
  params.CompanyGroupId = getState().auth.selectedCompanyGroup
    params.WorkerDeclarationType = 1
  const response = await axios.get(`/api/workerdeclaration`, { params })
  return {
    params,
    data: response.data.workerDeclarations,
    totalPages: response.data.pageCount
  }
})

export const getGendarmerieCountryData = createAsyncThunk('workerdeclaration/getGendarmerieCountryData', async () => {
  const response = await axios.get('/api/commonvalue?moduleType=1&groupType=1')
  const selectData = []
  response.data.commonValues.map((item) => {
    selectData.push({ label: item.name, value: item.id })
  })
  return selectData
})

export const addWorkerDeclaration = createAsyncThunk('api/workerdeclaration', async (data, { dispatch, getState }) => {
  await axios.post('/api/workerdeclaration', data)
  await dispatch(getData(getState().gendarmerie.params))
  return police
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().gendarmerie.params))
  return id
})
export const getCompanyList = createAsyncThunk('api/getCompanyList', async (data, { getState }) => {
  const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
  return response.data
})

export const appWorkerDeclarationGendarmerieSlice = createSlice({
  name: 'appWorkerDeclarationsGendarmerie',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    gendarmerieCountryData: [],
    companyList: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getGendarmerieCountryData.fulfilled, (state, action) => {
        state.gendarmerieCountryData = action.payload
      })
      .addCase(getCompanyList.fulfilled, (state, action) => {
        state.companyList = action.payload
    })
  }
})

export default appWorkerDeclarationGendarmerieSlice.reducer
