import { lazy } from 'react'


const WorkerList = lazy(() => import('../../views/pages/workerlist/list/index'))
const Payroll = lazy(() => import('../../views/pages/payroll/list/index'))
const EdeclarationByServices = lazy(() => import('../../views/pages/edeclaration-by-service/list/index'))
const HumanResourcesInformationSystemRoutes = [
  {
    path: '/calisan-listesi',
    element: <WorkerList />
  },
  {
    path: '/payroll',
    element: <Payroll />
  },
  {
    path: '/hizmet-listesi/:id',
    element: <EdeclarationByServices />
  }

 
]

export default HumanResourcesInformationSystemRoutes
