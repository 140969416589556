import { lazy } from 'react'

const Incentive = lazy(() => import('../../views/pages/incentive/index'))
const IncentiveManagement = lazy(() => import('../../views/pages/incentive-management/index'))
const IncentiveBilling = lazy(() => import('../../views/pages/incentive-billing/index'))
const IncentiveRoutes = [
  {
    path: '/tesvik',
    element: <Incentive />
  },
  {
    path: '/tesvik-yonetim',
    element: <Incentive />
  },
  {
    path: '/tesvik-fatura',
    element: <IncentiveBilling />
  }
]

export default IncentiveRoutes