import {useDispatch, useSelector} from 'react-redux'
import {
    getIncentiveProcessAllGroup, getSchedulerList, downloadSchedulerExcel, reTry, showProcessData,
    resumeProcess, downloadInvoices
} from './store'
import React, {useEffect, useState} from 'react'
import {store} from "@store/store"

import {DataGrid, trTR} from '@mui/x-data-grid'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Select from 'react-select'
import {
    MenuItem,
    Card,
    Typography,
    CircularProgress,
    Box,
    IconButton, Button
} from '@mui/material'
import {
    Col,
    Label, Row, Table
} from 'reactstrap'
import PropTypes from 'prop-types'
import {CheckCircle, AlertTriangle, Loader, AlertOctagon} from 'react-feather'

import {BsFiletypeXls, BsArrowCounterclockwise, BsFiletypeXml} from 'react-icons/bs'

// Ayları geri almak için yardımcı fonksiyon
const subMonths = (date, months) => {
    const newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() - months)
    return newDate
}

// YYYYMM formatında tarih dönüştürme fonksiyonu
const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Ayları 2 basamaklı yapmak için
    return `${year}${month}`
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired
}

const downloadXML = (row) => {
    const link = document.createElement('a')
    const filename = row.resultXml.substring(21)
    link.setAttribute('href', row.resultXml)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.setAttribute('download', filename)
    link.click()
}

const downloadExcel = (row) => {
    const link = document.createElement('a')
    const filename = row.resultExcel.substring(21)
    link.setAttribute('href', row.resultExcel)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.setAttribute('download', filename)
    link.click()
}

const columns = [
    {
        field: 'name',
        headerName: 'Grup',
        minWidth: 200,
        sortable: false,
        renderCell: (params) => (
            <>
                <button onClick={() => store.dispatch(showProcessData({
                    id: params.row.id,
                    name: 'SubProcessList',
                    text: params.row.name
                }))}
                        className="btn btn-outline-primary" id={params.row.id}>{params.row.name}</button>
            </>
        )
    },
    {field: 'company', headerName: 'İşyeri', minWidth: 200, sortable: false, filterable: false},
    {
        field: 'succesPercent',
        headerName: 'İşlem Durumu',
        minWidth: 250,
        filterable: false,
        renderCell: (params) => (
            <>
                {params.row.status === "2" || params.row.status === "" ? (
                    <AlertTriangle color='red' size={40}/>) : params.row.status === "5" ? (<>
                        <AlertOctagon color='orange' size={40}/>
                        <button onClick={() => store.dispatch(resumeProcess(params.row.id))}
                                className="btn btn-danger" id={params.row.id}>Devam Et
                        </button>
                    </>
                ) : (
                    parseInt(params.row.succesPercent) >= 100 ? (
                        <CheckCircle color="green" size={40}/>) : (
                        <CircularProgressWithLabel value={parseInt(params.row.succesPercent)}/>)
                )
                }
            </>
        )
    },
    {field: 'message', headerName: 'Mesaj', minWidth: 250, sortable: false, filterable: false},
    {field: 'startDate', headerName: 'İşlem Başlangıç Tarihi', minWidth: 250, filterable: false},
    {field: 'endDate', headerName: 'İşlem Bitiş Tarihi', minWidth: 250, sortable: false, filterable: false},
    {
        field: 'resultExcel',
        headerName: 'Sonuç Rapor',
        minWidth: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <>
                {params.row.status === "2" || params.row.status === "" ? (
                    <AlertTriangle color='red' size={40}/>) : params.row.status === "5" ? (
                    <AlertOctagon color='orange' size={40}/>) : (
                    params.row.resultExcel === "" ? (
                        <Loader size={40}/>
                    ) : (
                        <div>
                            <IconButton size='60' onClick={() => downloadXML(params.row)}>
                                <BsFiletypeXml size={30}/>
                            </IconButton>
                            <IconButton size='60' onClick={() => downloadExcel(params.row)}>
                                <BsFiletypeXls size={30}/>
                            </IconButton>
                        </div>
                    )
                )
                }
            </>
        )
    }
]

function CustomPagination(props) {
    const handlePageSizeChange = (event) => {
        props.onPageSizeChange(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        props.onPageChange(newPage - 1)
    }
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Select
                value={props.pageSize}
                onChange={handlePageSizeChange}
                style={{marginRight: '16px', marginTop: '-10px'}}
            >
                {props.pageSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color='primary'
                shape='circular'
                page={props.page + 1}
                count={props.rowCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple/>}
                onChange={handlePageChange}
            />
        </div>
    )
}

const Incentive = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentiveManagement)
    const authStore = useSelector(state => state.auth)

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [searchTerm, setSearchTerm] = useState([])
    const [sort, setSort] = useState('desc')
    const [sortColumn, setSortColumn] = useState('id')

    const handleSortModelChange = React.useCallback((sortModel) => {
        // Here you save the data you need from the sort model
        if (sortModel.length > 0) {
            setSortColumn(sortModel[0].field)
            setSort(sortModel[0].sort)
        } else {
            setSortColumn('id')
            setSort('desc')
        }
    }, [])


    const getDataParameter = () => {
        return {
            page: page + 1,
            pageSize,
            sort,
            sortColumn,
            searchTerm
        }
    }


    const handleFilter = val => {
        setSearchTerm(val.items)
    }

    useEffect(() => {
        dispatch(getIncentiveProcessAllGroup(getDataParameter()))
        console.log(store.data)
    }, [dispatch, authStore.selectedCompanyGroup, page, pageSize, sort, searchTerm])

    useEffect(() => {
        dispatch(getIncentiveProcessAllGroup(getDataParameter()))
        dispatch(getSchedulerList(1))
        console.log(store.data)
    }, [])

    const [selectedPeriods, setSelectedPeriods] = useState([])

    const generatePeriodOptions = () => {
        const options = []
        for (let i = 0; i < 4; i++) {
            const periodDate = subMonths(new Date(), i)
            const periodValue = formatDate(periodDate)
            options.push({value: periodValue, label: periodValue})
        }
        return options
    }

    const handleChange = (selectedOptions) => {
        setSelectedPeriods(selectedOptions || [])
    }

    const save = () => {
        dispatch(downloadInvoices(selectedPeriods.value))
    }

    return (
        <>
            <Card>
                <div style={{height: 540, width: '100%'}}>
                    <DataGrid
                        rows={store.data}
                        columns={columns}
                        rowCount={store.total}
                        pageSize={pageSize}
                        paginationMode="server"
                        sortingMode='server'
                        filterMode='server'
                        onFilterModelChange={handleFilter}
                        onSortModelChange={handleSortModelChange}
                        onPageChange={(params) => {
                            setPage(params.page)
                        }}
                        onPageSizeChange={(params) => {
                            setPageSize(params.pageSize)
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        slots={{
                            pagination: () => <CustomPagination
                                page={page}
                                pageSize={pageSize}
                                rowCount={store.total}
                                pageSizeOptions={[5, 10, 25]}
                                onPageChange={(newPage) => {
                                    setPage(newPage)
                                }}
                                onPageSizeChange={(newPageSize) => {
                                    setPageSize(newPageSize)
                                }}
                            />
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </Card>
            <Card className='mt-5' style={{minHeight: 240}}><Row className='d-flex align-items-center p-1'>
                <Label className='form-label' for='period'>
                    <span className='bold ps-sm-2'>Fatura Listesi İndir</span>
                    <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                        <Row>
                            <Col>
                                Dönem
                            </Col>
                            <Col>
                                İşlemler
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    isClearable={false}
                                    options={generatePeriodOptions()}
                                    className='react-select'
                                    classNamePrefix='select'
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col>
                                <Button type='button' className='me-1' color='primary' onClick={save}>
                                    İndir
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Label>
            </Row>
            </Card>
            <Card className='mt-5'><Row className='d-flex align-items-center p-1'>
                <Label className='form-label' for='period'>
                    <span className='bold ps-sm-2'>CronJob Durumu</span>
                    {store.schedulerData.length !== 0 ? (
                        (
                            <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                                <Table responsive>
                                    <thead>
                                    <th scope='col' className='text-nowrap'> Tarih</th>
                                    <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                                    <th scope='col' className='text-nowrap'> Sonuç</th>
                                    <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                                    <th scope='col' className='text-nowrap'> İşlemler</th>
                                    </thead>
                                    <tbody>
                                    {store.schedulerData.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-nowrap'>{item.date}</td>
                                            <td className='text-nowrap'>{item.errorMessage}</td>
                                            <td className='text-nowrap'>{item.status}</td>
                                            <td className='text-nowrap'>
                                                <IconButton size='60'
                                                            onClick={() => dispatch(downloadSchedulerExcel(item.id))}>
                                                    <BsFiletypeXls size={30}/>
                                                </IconButton>
                                            </td>

                                            {
                                                item.errorMessage.includes("Başarılı") ? <>
                                                    <td className='text-nowrap'>-</td>
                                                </> : (<>
                                                    <td className='text-nowrap'>
                                                        <IconButton size='60' disabled={store.retryButtonDisabled}
                                                                    onClick={() => dispatch(reTry(item.id))}>
                                                            <BsArrowCounterclockwise size={30}/>
                                                        </IconButton>
                                                    </td>
                                                </>)
                                            }

                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>)
                    ) : (<div>Sonuç Bulunamadı</div>)}
                </Label>
            </Row>
            </Card>
        </>
    )
}

export default Incentive
