// ** Third Party Components
import { useSelector } from 'react-redux'

// ** Reactstrap Imports
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
// import { useEffect, useRef } from 'react'
import InnerHTML from 'dangerously-set-html-content'

// ** Store & Actions

const LoginPopup = ({ open, toggleSidebar }) => {
  const store = useSelector(state => state.workAccident)
  const getHtml = () => {
    if (store.toggleHtmlPopup) {
      if (store.toggleHtmlPopup?.html !== null) {
        return store.toggleHtmlPopup?.html.replace('width="780"', 'width="100%"')
      }
    }
    return "<div></div>"
  }
  return (
    <>
      <Modal isOpen={open} toggle={() => toggleSidebar()} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => toggleSidebar()}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>İş Kazası ekranı</h1>
          </div>
          <InnerHTML html={getHtml()} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default LoginPopup
