// ** Reactstrap Imports
import Select from 'react-select'
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row, ListGroup, ListGroupItem } from 'reactstrap'

import { Fragment, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FileText, X, DownloadCloud } from 'react-feather'
// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { useDispatch /*, useSelector*/ } from 'react-redux'
import { addPayrollControlFile, addPayrollPeriodData, filePopupState, payrollControl } from '../store'

const FilePopup = ({ open }) => {
  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: result => {
      const file = result[0]
      dispatch(addPayrollControlFile(file))
      setFiles([...result.map(file => Object.assign(file))])
    }
  })
  const renderFilePreview = file => {
    if (file.type.startsWith('image')) {
      return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />
    } else {
      return <FileText size='28' />
    }
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
    }
  }

  const fileList = files.map((file, index) => (
    <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
      <div className='file-details d-flex align-items-center'>
        <div className='file-preview me-1'>{renderFilePreview(file)}</div>
        <div>
          <p className='file-name mb-0'>{file.name}</p>
          <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const closePopup = () => {
    dispatch(filePopupState(false))
    setFiles([])
  }

  return (
    <>
      <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>

        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Bordro Kontrol Et</h1>
            <p>Bordro Kontrol Ekranı</p>
          </div>
          <Fragment>
            <Row>
              <Col sm='12'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div className='d-flex align-items-center justify-content-center flex-column'>
                    <DownloadCloud size={64} />
                    <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                    <p className='text-secondary'>                      <a href='/' onClick={e => e.preventDefault()}>
                      Araştır
                    </a>{' '}
                    </p>
                  </div>
                </div>
                {files.length ? (
                  <Fragment>
                    <ListGroup className='my-2'>{fileList}</ListGroup>
                  </Fragment>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <div className='mb-1'>
                  <Label className='form-label' for='period'>
                    <span className='bold'>Dönem</span> <span className='text-danger'>*</span>
                  </Label>
                  <Select
                    isClearable={false}
                    options={[{ value: 2, label: 'Seçiniz' }, { value: 0, label: 'Önceki Dönem' }, { value: 1, label: 'Bulunduğumuz Dönem' }]}
                    className='react-select'
                    classNamePrefix='select'
                    id='period'
                    onChange={data => {
                      dispatch(addPayrollPeriodData(data.value))
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <Button type='button' className='me-1' color='primary' onClick={() => {
                  dispatch(payrollControl(true))
                }}>
                  Kontrol Et
                </Button>
                <Button type='reset' color='secondary' outline onClick={closePopup} >
                  İptal
                </Button>
              </Col>
            </Row>
          </Fragment>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FilePopup
