// ** React Import
import {useEffect, useState} from 'react'

// ** Third Party Components
import Select from 'react-select'

// ** Reactstrap Imports
import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useDispatch, useSelector} from 'react-redux'
import {showHideMinimumSupportPopup, minimumSupportListCreate} from './store'


const MinimumSupportPopup = () => {
    // ** States

    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentive)
    const authStore = useSelector(state => state.auth)

    const reset = () => {

    }

    useEffect(() => {
        reset()
    }, [store.minimumSupportPopupOpen])

    const popupClose = () => {
        dispatch(showHideMinimumSupportPopup(false))
        reset()
    }

    const getYearOptions = () => {
        const currentYear = new Date().getFullYear()
        return [
            {value: currentYear, label: currentYear},
            {value: currentYear - 1, label: currentYear - 1}
        ]
    }

    const getMonthOptions = (year) => {
        const currentMonth = new Date().getMonth() + 1
        const maxMonth = year === new Date().getFullYear() ? currentMonth - 1 : 12
        const options = []
        for (let i = 1; i <= maxMonth; i++) {
            options.push({value: i, label: i})
        }
        return options
    }

    const [yearOptions, setYearOptions] = useState([])
    const [monthOptions, setMonthOptions] = useState([])

    const [yearValue, setYearValue] = useState([])
    const [monthValue, setMonthValue] = useState([])

    useEffect(() => {
        setYearOptions(getYearOptions())
    }, [])

    const handleYearChange = (selectedYear) => {
        setYearValue(selectedYear)
        if (selectedYear) {
            setMonthOptions(getMonthOptions(selectedYear.value))
        } else {
            setMonthOptions([])
        }
    }

    return (
        <>
            <Modal isOpen={store.minimumSupportPopupOpen} toggle={popupClose}
                   className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>

                    <div className='mb-1'>
                        <Label className='form-label' for='companyGroupId'>
                            Yıl Seçiniz <span className='text-danger'>*</span>
                        </Label>
                        <Select
                            isClearable={false}
                            options={yearOptions}
                            onChange={handleYearChange}
                            className='react-select'
                            classNamePrefix='select'
                            value={yearValue}
                        />
                    </div>

                    <div className='mb-1'>
                        <Label className='form-label' for='companyGroupId'>
                            Ay Seçiniz <span className='text-danger'>*</span>
                        </Label>
                        <Select
                            isClearable={false}
                            options={monthOptions}
                            className='react-select'
                            classNamePrefix='select'
                            value={monthValue}
                            onChange={(item) => setMonthValue(item)}
                        />
                    </div>

                    <Button className='me-1' color='primary'
                            onClick={() => dispatch(minimumSupportListCreate({
                                CompanyGroupId: authStore.selectedCompanyGroup,
                                Year: yearValue.value,
                                Month: monthValue.value
                            }))}>
                        Kaydet
                    </Button>
                    <Button type='reset' color='secondary' outline onClick={popupClose}>
                        İptal
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default MinimumSupportPopup
