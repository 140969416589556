import '@styles/react/libs/flatpickr/flatpickr.scss'
import Cleave from 'cleave.js/react'
import Flatpickr from 'react-flatpickr'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap'
import {outSgkWorkerDeclarationApi, toggleSgkSingularOutPopupClose} from '../store'
// ** Configs
import {useEffect, useState} from 'react'
import {sendDateFormat} from "@utils"
import axios from "axios"

const SidebarSingularSgkOut = ({open}) => {

    const store = useSelector(state => state.sgkWorkerDeclaration)
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')

    const [postData, setPostData] = useState(null)

    const [continuePopup, setContinuePopup] = useState(false)

    const continuePopupClose = () => {
        setContinuePopup(false)
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            tckn: 0,
            nameSurname: "",
            jobCode: "",
            outReason: "",
            cgbis: "",
            oDocType: "",
            oDeservedFee: "",
            oBonus: "",
            oMissingDay: "",
            oMissingDayReason: "",
            bDocType: "",
            bDeservedFee: "",
            bBonus: "",
            bMissingDay: "",
            bMissingDayReason: "",
            outDate: "",
            companyId: ""
        }
    })

    const popupClose = () => {
        dispatch(toggleSgkSingularOutPopupClose(false))
        reset()
    }

    const onSubmit = async (data) => {
        setPostData(data)
        const response = await axios.post('/api/CompanyWorker/sgkSingularOutCheck', {
            workEndDate: data.outDate ? sendDateFormat(data.outDate) : null
        })

        if (response.data) {
            setContinuePopup(true)
            popupClose()
        } else {
            dispatch(
                outSgkWorkerDeclarationApi({
                    tckn: data.identificationNumber ? data.identificationNumber : 0,
                    jobCode: data.jobCode ? data.jobCode.value.toString() : "",
                    outReason: data.outReason ? data.outReason.value.toString() : "",
                    cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                    oDocType: data.oDocType ? data.oDocType.value : -1,
                    oDeservedFee: data.oDeservedFee ? data.oDeservedFee.replace(',', '') : -1,
                    oBonus: data.oBonus ? data.oBonus : -1,
                    oMissingDay: data.oMissingDay ? data.oMissingDay : -1,
                    oMissingDayReason: data.oMissingDayReason ? data.oMissingDayReason.value : -1,
                    bDocType: data.bDocType ? data.bDocType.value : -1,
                    bDeservedFee: data.bDeservedFee ? data.bDeservedFee.replace(',', '') : -1,
                    bBonus: data.bBonus ? data.bBonus : -1,
                    bMissingDay: data.bMissingDay ? data.bMissingDay : -1,
                    bMissingDayReason: data.bMissingDayReason ? data.bMissingDayReason.value : -1,
                    outDate: data.outDate ? sendDateFormat(data.outDate) : null,
                    companyId: data.company ? data.company.value : 0
                })
            )
        }
    }

    const continueOnSubmit = () => {
        const data = postData
        dispatch(
            outSgkWorkerDeclarationApi({
                tckn: data.identificationNumber ? data.identificationNumber : 0,
                jobCode: data.jobCode ? data.jobCode.value.toString() : "",
                outReason: data.outReason ? data.outReason.value.toString() : "",
                cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                oDocType: data.oDocType ? data.oDocType.value : -1,
                oDeservedFee: data.oDeservedFee ? data.oDeservedFee.replace(',', '') : -1,
                oBonus: data.oBonus ? data.oBonus : -1,
                oMissingDay: data.oMissingDay ? data.oMissingDay : -1,
                oMissingDayReason: data.oMissingDayReason ? data.oMissingDayReason.value : -1,
                bDocType: data.bDocType ? data.bDocType.value : -1,
                bDeservedFee: data.bDeservedFee ? data.bDeservedFee.replace(',', '') : -1,
                bBonus: data.bBonus ? data.bBonus : -1,
                bMissingDay: data.bMissingDay ? data.bMissingDay : -1,
                bMissingDayReason: data.bMissingDayReason ? data.bMissingDayReason.value : -1,
                outDate: data.outDate ? sendDateFormat(data.outDate) : null,
                companyId: data.company ? data.company.value : 0
            })
        )
        setContinuePopup(false)
    }

    useEffect(() => {
        reset()
    }, [store.singularPopupStatus])

    const fetchOptions = async (input) => {
        if (!input) {
            setOptions([])
            return
        }

        try {
            const response = await axios.get(`/api/CommonValue/Job`, {
                params: {Query: input}
            })

            const fetchedOptions = response.data.map((item) => ({
                value: item.value,
                label: `${item.value} - ${item.name}`
            }))

            setOptions(fetchedOptions)
        } catch (error) {
            console.error('Error fetching options:', error)
        }
    }

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
        fetchOptions(newValue)
    }

    const optionsoDeservedFee = {numeral: true, numeralThousandsGroupStyle: 'thousand'}
    const optionsoMissingDay = {blocks: [2], numericOnly: true}
    const optionsTcKimlikNo = {blocks: [11], numericOnly: true}

    const flatpickrConfig = {
        disable: [
            function(date) {
                // return true to disable
                return date > new Date()
            }
        ]
    }

    return (
        <>
            <Modal isOpen={open} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>SGK</h1>
                        <p>Tekil Çıkış Bildirimi</p>
                    </div>

                    <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-1'>
                            <Label className='form-label' for='company'>
                                Sicil <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='company'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.companyList}
                                        className='react-select'
                                        classNamePrefix='select'
                                        {...field}
                                    />

                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='identificationNumber'>
                                T.C. Kimlik No <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='identificationNumber'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='identificationNumber' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsTcKimlikNo}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='jobCode'>
                                Meslek Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='jobCode'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        className='react-select'
                                        classNamePrefix='select'
                                        options={options}
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        placeholder="Aramak için yazın..."
                                        isClearable
                                        {...field}
                                    />
                                )}
                            />
                            <Input style={{display: 'none'}} value={store.singularOutTckn} name="tckn" id='tckn'
                                   placeholder='' invalid={errors.name && true}/>
                            <Input style={{display: 'none'}} value={store.singularOutNameSurname} name="nameSurname"
                                   id='nameSurname' placeholder='' invalid={errors.name && true}/>
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='outReason'>
                                İşten Çıkış Nedeni <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='outReason'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.ReasonForLeavingData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='cgbis'>
                                CGBIS Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='cgbis'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.CSGBBusinessDepartmentsData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='oDocType'>
                                Önceki Dönem Belge Türü <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='oDocType'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.WorkerOutDocumentTypesData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='oDeservedFee'>
                                Önceki Dönem Hakedilen Ücret <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='oDeservedFee'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='oDeservedFee' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsoDeservedFee}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='oBonus'>
                                Önceki Dönem Prim İkramiye <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='oBonus'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='oBonus' placeholder='' type='text'
                                            invalid={errors.name && true} {...field} options={optionsoDeservedFee}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='oMissingDay'>
                                Önceki Dönem Eksik Gün Sayısı <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='oMissingDay'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='oMissingDay' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsoMissingDay}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='oMissingDayReason'>
                                Önceki Dönem Eksik Gün Nedeni <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='oMissingDayReason'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.MissingDayReasonData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-1'>
                            <Label className='form-label' for='bDocType'>
                                Bulunduğumuz Dönem Belge Türü <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='bDocType'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.WorkerOutDocumentTypesData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='bDeservedFee'>
                                Bulunduğumuz Dönem Hakedilen Ücret <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='bDeservedFee'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='bDeservedFee' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsoDeservedFee}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='bBonus'>
                                Bulunduğumuz Dönem Prim İkramiye <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='bBonus'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='bBonus' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsoDeservedFee}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='bMissingDay'>
                                Bulunduğumuz Dönem Eksik Gün Sayısı <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='bMissingDay'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='bMissingDay' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsoMissingDay}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='bMissingDayReason'>
                                Bulunduğumuz Dönem Eksik Gün Nedeni <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='bMissingDayReason'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.MissingDayReasonData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-1'>
                            <Label className='form-label' for='outDate'>
                                Çıkış Tarihi <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='outDate'
                                control={control}
                                render={({field}) => (
                                    <Flatpickr className='form-control' options={flatpickrConfig}
                                               id='outDate' {...field} />
                                )}
                            />
                        </div>
                        <Button type='submit' className='me-1' color='primary'>
                            Kaydet
                        </Button>
                        <Button type='reset' color='secondary' outline onClick={popupClose}>
                            İptal
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={continuePopup} toggle={continuePopupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={continuePopupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Dikkat</h1>
                        <p>İşten Çıkış İşleminiz Cezaya Tabii Tutulacaktır. Devam Etmek İstiyor Musunuz?</p>
                    </div>
                    <Button type='submit' className='me-1' color='primary' onClick={continueOnSubmit}>
                        Devam Et
                    </Button>
                    <Button type='reset' color='secondary' outline onClick={continuePopupClose}>
                        İptal
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}

export default SidebarSingularSgkOut