// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { editRoute, getRoutes, getUsers, selectAuthUser, selectRoute, SetPathAuth } from './store'

// ** Reactstrap Imports
import Select from 'react-select'
import {
  Button, Card, CardBody, Col, Label, Modal, ModalBody,
  ModalHeader, Row, Spinner
} from 'reactstrap'

// ** Third Party Components
import { Download } from 'react-feather'

const RoleCards = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.userFileManager)
  // ** States
  const [show, setShow] = useState(false)
  // ** Hooks

  useEffect(() => {
    dispatch(getRoutes(store.path))
    dispatch(getUsers(1))
  }, [])

  const onReset = () => {
    setShow(false)
  }

  return (
    <Fragment>
      <Row>
        {store.pathLoading ? <div className='d-flex justify-content-center my-1'>
          <Spinner />
        </div> : store.paths.map((item, index) => {
          return (
            <Col key={index} xl={3} md={6}>
              <Card style={{ backgroundColor: (item.systemPath ? "#7367f01a" : "") }}>
                <CardBody>
                  <div className='d-flex justify-content-between align-items-end mt-1 pt-25'>
                    <div className='role-heading'>
                      <h4 className='fw-bolder' style={{ cursor: "pointer" }} onClick={() => dispatch(selectRoute(item.id))}>{item.fileName}</h4>
                      {!item.systemPath ? <a
                        className='role-edit-modal text-primary'
                        onClick={e => {
                          e.preventDefault()
                          setShow(true)
                          dispatch(editRoute(item.id))
                        }}
                      >
                        <small onClick={() => dispatch(getUsers(1))} className='fw-bolder'>Edit Role</small>
                      </a> : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        })
        }
        <div className='divider'>
          <div className='divider-text'>Dosyalarım</div>
        </div>
        {store.pathLoading ? <div className='d-flex justify-content-center my-1'>
          <Spinner />
        </div> : store.files.map((item, index) => {
          return (
            <Col key={index} xl={3} md={6}>
              <Card>
                <CardBody>
                  <div className='d-flex justify-content-between align-items-end mt-1 pt-25'>
                    <div className='role-heading'>
                      <h4 className='fw-bolder'>{item.split('/')[item.split('/').length - 1]}</h4>
                    </div>
                    <a className='text-body' onClick={() => window.open(item)}>
                      <Download className='font-medium-5' />
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className='modal-dialog-centered modal-lg'
      >
        <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
        <ModalBody className='px-5 pb-5'>
          <div className='text-center mb-4'>
            <h1>Klasöre Erişim Ver</h1>
          </div>
          <Row>
            <Col xs={12}>
              <Label className='form-label' for='roleName'>
                Kullanıcılar
              </Label>
              <Select
                isClearable={false}
                isMulti
                onChange={(item) => dispatch(selectAuthUser(item))}
                value={store.users?.filter(x => store.authSelectedData.includes(x.value))}
                name='colors'
                options={store.users}
                className='react-select'
                classNamePrefix='select'
              />
            </Col>
            <Col className='text-center mt-2' xs={12}>
              <Button onClick={() => {
                dispatch(SetPathAuth(store.authSelectedData))
                setShow(false)
              }} type='submit' color='primary' className='me-1'>
                Kaydet
              </Button>
              <Button type='reset' outline onClick={onReset}>
                İptal
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default RoleCards
