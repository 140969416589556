import {Eye, Trash2, Key} from 'react-feather'
import {store} from '@store/store'
import {deleteCompany, getCompanyDetails, modalShow, showLoginPopup} from '../store'
import * as React from 'react'

export const columns = [
    {
        name: 'Grup Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'groupName',
        selector: row => row.groupName,
        cell: row => row.groupName
    },
    {
        name: 'Firma Adı',
        sortable: true,
        minWidth: '172px',
        sortField: 'name',
        selector: row => row.name,
        cell: row => row.name
    },
    {
        name: 'Sicil Numarası',
        minWidth: '138px',
        sortable: true,
        sortField: 'registirationNumber',
        selector: row => row.registirationNumber,
        cell: row => row.registirationNumber
    },
    {
        name: 'Vergi Numarası',
        minWidth: '230px',
        sortable: true,
        sortField: 'taxNumber',
        selector: row => row.taxNumber,
        cell: row => (row.taxNumber ? (`00${row.taxNumber}`).slice(-10) : '')
    },
    {
        name: 'İşlemler',
        minWidth: '100px',
        cell: row => (
            <div className='column-action'>
                <Key size={18} className='cursor-pointer me-1' onClick={() => store.dispatch(modalShow(row.id))}/>
                <Eye size={18} className='cursor-pointer me-1' onClick={() => store.dispatch(getCompanyDetails({
                    id: row.id,
                    name: 'FirmaDetay1',
                    text: row.name
                }))}/>
                <Trash2 size={18} className='cursor-pointer me-1' onClick={e => {
                    e.preventDefault()
                    store.dispatch(deleteCompany(row.id))
                }}/>

            </div>
        )
    },
    {
        name: 'SGK',
        minWidth: 100,
        sortable: false,
        cell: (row) => (
            <>
                <button onClick={() => store.dispatch(showLoginPopup({id: row.id, show: true}))}
                        className="btn btn-outline-primary">Giriş Yap
                </button>
            </>
        )
    }
]
