// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
export const getData = createAsyncThunk('payroll/getData', async (params, { getState }) => {
  const url = `/api/payroll/0/?PageSize=${params.pageSize}&page=${params.page}&id=0&CompanyId=&CompanyGroupId=${getState().auth.selectedCompanyGroup}`
  const response = await axios.get(url)
  return {
    params,
    data: response.data.payrolls,
    totalPages: response.data.pageCount
  }
})

export const addPayrollControlFile = createAsyncThunk('payroll/addfile', async (data) => {
  return data
})

export const addPayrollPeriodData = createAsyncThunk('payroll/addperiod', async (data) => {
  return data
})

export const filePopupState = createAsyncThunk('payroll/filePopup', (status) => {
  return status
})

export const payrollControl = createAsyncThunk('payroll/control', async (status, { dispatch, getState }) => {
  if (status === true) {
    const fileData = getState().payroll.fileData
    const periodData = getState().payroll.filePeriod
    if (periodData === 2) {
      toast.error('Bordro Dönemi Seçiniz..')
      return
    }
    if (fileData === null) {
      toast.error('Dosya Seçiniz..')
      return
    }
    const data = new FormData()
    if (fileData !== null) {
      data.append('file', fileData, fileData.name)
    }
    data.append('period', periodData)
    data.append('companyGroupId', getState().auth.selectedCompanyGroup)

    const response = await axios.post(`/api/payroll/control`, data, {
      responseType: 'arraybuffer',
      'Content-Type': 'blob'
    })
    if (response.status === 200) {
      await dispatch(filePopupState(false))
    }

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)

    await dispatch(getData(getState().payroll.params))

    return link
  }
})

export const appPayrollSlice = createSlice({
  name: 'payroll',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    filePopupStatus: false,
    fileData: null,
    filePeriod: 2,
    responseExcel: null,
    controlPending: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.controlPending = false
      })
      .addCase(filePopupState.fulfilled, (state, action) => {
        state.filePopupStatus = action.payload
      })
      .addCase(addPayrollControlFile.fulfilled, (state, action) => {
        state.fileData = action.payload
      })
      .addCase(addPayrollPeriodData.fulfilled, (state, action) => {
        state.filePeriod = action.payload
      })
      .addCase(payrollControl.fulfilled, (state, action) => {
        state.responseExcel = action.payload
      })
  }
})

export default appPayrollSlice.reducer
