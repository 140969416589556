import { lazy } from 'react'

const CurrentAudit = lazy(() => import('../../views/pages/current-audit/index'))

const CurrentAuditRoutes = [
  {
    path: '/cari-denetim',
    element: <CurrentAudit />
  }
]

export default CurrentAuditRoutes