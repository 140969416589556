// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createTab } from '../../../../redux/tabManagement'
// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('approvedVisitList/getData', async (params, { getState }) => {
  params.visitType = 0
  params.CompanyGroupId = params.CompanyGroupId = getState().auth.selectedCompanyGroup
  const url = `/api/visits/0/`
  const response = await axios.get(url, { params })
  return {
    params,
    data: response.data.visits,
    totalPages: response.data.pageCount
  }
})

export const getDetail = createAsyncThunk('approvedVisit/getDetail', async (data) => {
  const response = await axios.get(`/api/visits/getApprovedVisitDetail?medulaReportId=${data.reportId}`)
  return { popupStatus: true, responseData: response }
})

export const ToggleVisitHtmlImagePopup = createAsyncThunk('approvedVisit/ToggleVisitHtmlImagePopup', (data) => {
  return data
})

export const getHtmlFile = createAsyncThunk('approvedVisit/getHtmlFile', async (data, { getState }) => {
  const response = await axios.get(`/api/visits/HtmlFile`, {
      responseType: 'arraybuffer',
      'Content-Type': 'blob',
      params: { ReportTrackingNo: getState().approvedVisits.toggleVisitHtmlImagePopup.visitReportNumber, VisitType: 0}
  })
  if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'HtmlFile.html')
      document.body.appendChild(link)
      link.click()
  }

})

export const detailPopupState = createAsyncThunk('approvedVisit/detailPopupState', (data, { dispatch }) => {
  dispatch(getDetail(data))
})

export const detailPopupStateClose = createAsyncThunk('approvedVisit/detailPopupStateClose', (data) => {
  return data
})

export const approvedReportCancel = createAsyncThunk('approvedVisit/approvedReportCancel', async (data, { dispatch, getState }) => {
  // data.companyId = 7
  data.CompanyGroupId = getState().auth.selectedCompanyGroup
  const response = await axios.post('/api/visits/cancelDeclaration', data)
  await dispatch(getData(getState().approvedVisits.params))
  return response
})
export const getCompanyDetails = createAsyncThunk('user/getCompanyDetails', (data, {dispatch}) => {
  dispatch(createTab(data))
})
export const exportExcel = createAsyncThunk('approvedVisit/exportExcel', async (data, { getState }) => {
    console.log(data)
    const response = await axios.get(`/api/visits/approvedExport`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: { CompanyGroupId: getState().auth.selectedCompanyGroup, Q: getState().workAccident.params.q }
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'onayli_vizite_listesi.xlsx')
        document.body.appendChild(link)
        link.click()
    }

})
export const approvedVisitsSlice = createSlice({
  name: 'approvedVisits',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    detailPopupStatus: false,
    detailVisitData: [],
    toggleVisitHtmlImagePopup: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(detailPopupStateClose.fulfilled, (state, action) => {
        state.detailPopupStatus = action.payload
      })
      .addCase(getDetail.fulfilled, (state, action) => {
        state.detailPopupStatus = action.payload.popupStatus
        state.detailVisitData = action.payload.responseData
      })
      .addCase(ToggleVisitHtmlImagePopup.fulfilled, (state, action) => {
        state.toggleVisitHtmlImagePopup = action.payload
      })
  }
})

export default approvedVisitsSlice.reducer
