// ** Third Party Components
import { useDispatch, useSelector } from 'react-redux'

// ** Reactstrap Imports
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import { getHtmlFile } from '../store'
// import { useEffect, useRef } from 'react'

// ** Store & Actions

const VisitHtmlImagePopup = ({ open, toggleSidebar }) => {
  const store = useSelector(state => state.approvedVisits)
  const dispatch = useDispatch()

  return (
    <>
      <Modal isOpen={open} toggle={() => toggleSidebar()} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => toggleSidebar()}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Vizite Raporu</h1>
            <br />
            <img src={store.toggleVisitHtmlImagePopup?.image} width='100%' />
          </div>
        </ModalBody>
        <ModalFooter className='d-flex mt-2 justify-content-end'>
          <Button className='me-1' color='primary' onClick={() => dispatch(getHtmlFile(1))}>Html İndir</Button>
          <Button color='secondary' onClick={() => toggleSidebar()} outline >İptal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default VisitHtmlImagePopup
