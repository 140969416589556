// ** React Imports
import { useEffect, useState } from "react"
import { useLocation, Outlet, useNavigate } from "react-router-dom"

// ** Store & Actions
import { useSelector, useDispatch } from "react-redux"
import { clearTabs } from "../redux/tabManagement"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"
import { getUserData } from "@utils"

// ** Menu Items Array
import { getNavigation } from "@src/navigation/vertical"

const VerticalLayout = (props) => {
  const [menuData, setMenuData] = useState([])
  const authStore = useSelector(state => state.auth)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // ** For ServerSide navigation
  useEffect(() => {
    const filterMenuData = []
    const newNavigation = getNavigation()
    newNavigation.forEach(element => {
      if (element.moduleId === -2) {
        filterMenuData.push(element)
      }
    })
    if (getUserData() !== null && getUserData().authModuleList) {
      const moduleList = getUserData().authModuleList.filter(x => x.companyGroupId === authStore.selectedCompanyGroup)
      let moduleId = -1
      if (moduleList.length > 0) {
        const moduleIdList = moduleList[0].moduleId
        newNavigation.filter(x => x.moduleId !== -2).forEach(element => {
          if (moduleIdList.includes(element.moduleId)) {
            filterMenuData.push(element)
          }
          if (element.children) {
            element.children = element.children.filter(x => moduleIdList.includes(x.moduleId))
            if (element.children.length > 0) {
              filterMenuData.push(element)
            }
          }
          if (location.pathname === element.navLink) {
            moduleId = element.moduleId
          } else if (element.children && element.children.filter(x => x.navLink === location.pathname).length > 0) {
            moduleId = element.children.filter(x => x.navLink === location.pathname)[0].moduleId
          }
        })
        if (!moduleIdList.includes(moduleId)) {
          navigate("/home")
          dispatch(clearTabs())
        }
      } else {
        navigate("/home")
        dispatch(clearTabs())
      }
    }
    setMenuData(filterMenuData)
  }, [authStore.selectedCompanyGroup])

  return (
    <Layout menuData={menuData} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
