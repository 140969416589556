import {useDispatch, useSelector} from 'react-redux'
import {getSchedulerList, downloadSchedulerExcel, reTry, downloadCompaniesExcel} from './store'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'

import {
    Card,
    Typography,
    CircularProgress,
    Box,
    IconButton
} from '@mui/material'
import {
    Label, Row, Table, Col
} from 'reactstrap'
import PropTypes from 'prop-types'

import {BsFiletypeXls, BsArrowCounterclockwise} from 'react-icons/bs'
import {CheckCircle} from "react-feather"

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
}

const CurrentAudit = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentiveManagement)

    const [period, setPeriod] = useState(null)

    useEffect(() => {
        dispatch(getSchedulerList(2))
    }, [])

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired
    }

    return (
        <>
            <Card className='mt-5'><Row className='d-flex align-items-center p-1'>
                <Label className='form-label' for='period'>
                    <span className='bold ps-sm-2'>Cari Denetim</span>
                    {store.schedulerData.length !== 0 ? (
                        (
                            <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                                <Table responsive>
                                    <thead>
                                    <th scope='col' className='text-nowrap'> Tarih</th>
                                    <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                                    <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                                    <th scope='col' className='text-nowrap'> İşlemler</th>
                                    </thead>
                                    <tbody>
                                    {store.schedulerData.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-nowrap'>{item.date}</td>
                                            <td className='text-nowrap'>
                                                {item.succesPercentInt >= 100 ? (
                                                    <CheckCircle color="green" size={40}/>) : (
                                                    <CircularProgressWithLabel
                                                        value={parseInt(item.succesPercentInt)}/>)
                                                }
                                            </td>
                                            {
                                                item.succesPercentInt >= 100 ? (<>
                                                    <td className='text-nowrap'>
                                                        <IconButton size='60'
                                                                    onClick={() => dispatch(downloadSchedulerExcel())}>
                                                            <BsFiletypeXls size={30}/>
                                                        </IconButton>
                                                    </td>
                                                </>) : (<>
                                                    <td className='text-nowrap'>-</td>
                                                </>)
                                            }
                                            {
                                                item.schedulerStatus !== 1 ? (<>
                                                    <td className='text-nowrap'>
                                                        <IconButton size='60' disabled={store.retryButtonDisabled}
                                                                    onClick={() => dispatch(reTry(item.id))}>
                                                            <BsArrowCounterclockwise size={30}/>
                                                        </IconButton>
                                                    </td>
                                                </>) : (<>
                                                <td className='text-nowrap'>-</td>
                                                </>)
                                            }

                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>)
                    ) : (<div>Sonuç Bulunamadı</div>)}
                </Label>
            </Row>
            </Card>
            <Card className='mt-5' style={{minHeight: 240}}><Row className='d-flex align-items-center p-1'>
                <Label className='form-label' for='period'>
                    <span className='bold ps-sm-2'>XML İletmeyen Firmalar</span>
                    <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                        <Row>
                            <Col>
                                Dönem
                            </Col>
                            <Col>
                                İşlemler
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    isClearable={false}
                                    options={[
                                        {label: "1 Ay", value: "1"},
                                        {label: "3 Ay", value: "3"},
                                        {label: "6 Ay", value: "6"}
                                    ]}
                                    className='react-select'
                                    classNamePrefix='select'
                                    value={period}
                                    onChange={(data) => setPeriod(data)}
                                />
                            </Col>
                            <Col>
                                <IconButton size='60'
                                            onClick={() => dispatch(downloadCompaniesExcel(period))}>
                                    <BsFiletypeXls size={30}/>
                                </IconButton>
                            </Col>
                        </Row>
                    </div>
                </Label>
            </Row>
            </Card>
        </>
    )
}

export default CurrentAudit
