import { useForm } from 'react-hook-form'
import { Button, Label, Form, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { editData, toggleUpdatePopupClose} from './store'
import { useDispatch, useSelector } from 'react-redux'
import '@styles/react/libs/flatpickr/flatpickr.scss'


const SidebarEditCompanyGroup = ({open}) => {

  const store = useSelector(state => state.companyGroups)
  const dispatch = useDispatch()

  const { 
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { name: store.name, id:store.id } })
  const onSubmit = data => {
    dispatch(
        editData({
        name: data.name,
        id : data.id
      })
    )
    dispatch(toggleUpdatePopupClose(false))
  }

  return (
    <>
      <Modal isOpen={open} toggle={() => dispatch(toggleUpdatePopupClose(false))} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => dispatch(toggleUpdatePopupClose(false))}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Grup</h1>
            <p>Grup güncelleme ekranı</p>
          </div>

          <Form style={{ }} onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='name'>
                Grup Adı<span className='text-danger'>*</span>
              </Label>
              <Input id='name' name='name' value={store.selectedCompanyGroupName} placeholder='' invalid={errors.name && true} />
              <Input style={{ display: 'none' }} value={store.selectedCompanyGroupId} id='id' name='id' placeholder='' invalid={errors.name && true} />
            </div> 
            <Button type='submit' className='me-1' color='primary'>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' outline onClick={() => dispatch(toggleUpdatePopupClose(false))} >
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarEditCompanyGroup