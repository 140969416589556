import {useDispatch, useSelector} from 'react-redux'
import {getData, handleOpenNewTab, handleDownload, createLog, createPoint} from '../store'
import React, {useEffect, useState} from 'react'
import {store} from '@store/store'
import '../../../../assets/css/extra.css'

import {DataGrid, trTR} from '@mui/x-data-grid'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import {Select, MenuItem} from '@mui/material'
import {Image, Download, Star} from 'react-feather'
import {CardHeader, CardTitle, Card} from "reactstrap"

const colors = ['#E96868', '#F5AF6E', '#C77DD9', '#81ADE1', '#EEE1A1', '#F1ABAB', '#4FA6BF', '#F28BE1', '#2B50AE', '#359E3A']

const RatingCell = ({value, row}) => {
    return (
        value !== 0 ? [...Array(5)].map((_, index) => (
            <Star
                key={index}
                size={20}
                className={`me-1 ${index < value ? 'text-warning' : ''}`}
            />
        )) : <div className='column-action'>
            {[...Array(5)].map((_, index) => (
                <Star
                    key={index}
                    size={20}
                    onClick={() => {
                        store.dispatch(createLog({
                            AnnouncementId: row.id,
                            AnnouncementType: 5
                        }))
                        store.dispatch(createPoint({Point: index + 1, AnnouncementId: row.id}))
                    }}
                    className={`cursor-pointer me-1 ${index < value ? 'text-warning' : ''}`}
                />
            ))}
        </div>
    )
}

let randomNum = Math.floor(Math.random() * 11)
const ActionCellView = (data) => {
    randomNum = Math.floor(Math.random() * 11)
    return (
        <div className='column-action'>
            <Image size={30} className={`cursor-pointer me-1`} style={{color: colors[randomNum]}} onClick={() => {
                store.dispatch(createLog({
                    AnnouncementId: data.row.id,
                    AnnouncementType: 3
                }))
                store.dispatch(handleOpenNewTab(data.row.fileView))
            }}/>
        </div>
    )
}

const ActionCellDownload = (params) => {
    return (
        <div className='column-action'>
            <Download size={30} className='cursor-pointer me-1'
                      style={{color: colors[randomNum]}}
                      onClick={() => {
                          store.dispatch(createLog({
                              AnnouncementId: params.row.id,
                              AnnouncementType: 4
                          }))
                          store.dispatch(handleDownload(params.row.file))
                      }}/>
        </div>
    )
}


const columns = [
    {
        field: 'id',
        sortable: false,
        filterable: false,
        maxWidth: 100,
        flex: 1,
        headerClassName: 'headerStyle',
        renderHeader: () => (<strong>Sıra No</strong>)
    },
    {
        field: 'code',
        sortable: false,
        filterable: false,
        maxWidth: 200,
        flex: 1,
        headerClassName: 'headerStyle',
        renderHeader: () => (<strong>Duyuru Kodu</strong>)
    },
    {
        field: 'title',
        renderHeader: () => (<strong>Başlık</strong>),
        minWidth: 350,
        sortable: false,
        filterable: false,
        flex: 1,
        headerClassName: 'headerStyle'
    },
    {
        field: 'createdAt',
        renderHeader: () => (<strong>Oluşturma Tarihi</strong>),
        maxWidth: 200,
        sortable: false,
        filterable: false,
        flex: 1,
        headerClassName: 'headerStyle'
    },
    {
        filterable: false,
        field: 'name',
        renderHeader: () => (<strong>Görüntüle</strong>),
        sortable: false,
        flex: 1,
        maxWidth: 100,
        headerClassName: 'headerStyle',
        renderCell: params => (
            <ActionCellView {...params} />
        )
    },
    {
        renderHeader: () => (<strong>İndir</strong>),
        sortable: false,
        filterable: false,
        flex: 1,
        maxWidth: 100,
        headerClassName: 'headerStyle',
        renderCell: params => (
            <ActionCellDownload {...params} />
        )
    },
    {
        renderHeader: () => (<strong>Puan</strong>),
        field: 'point',
        sortable: false,
        filterable: false,
        flex: 1,
        maxWidth: 200,
        headerClassName: 'headerStyle',
        renderCell: (params) => (
            <RatingCell value={params.row.point} row={params.row}/>
        )
    }
]

function CustomPagination(props) {
    const handlePageSizeChange = (event) => {
        props.onPageSizeChange(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        props.onPageChange(newPage - 1)
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Select
                value={props.pageSize}
                onChange={handlePageSizeChange}
                style={{marginRight: '16px', marginTop: '-10px'}}
            >
                {props.pageSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color='primary'
                shape='circular'
                page={props.page + 1}
                count={props.rowCount}
                renderItem={(props2) => <PaginationItem {...props2} disableRipple/>}
                onChange={handlePageChange}
            />
        </div>
    )
}

const ListAnnouncements = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.announcements)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(25)

    useEffect(() => {
        dispatch(getData({Page: page, PageSize: pageSize}))
    }, [])

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle tag='h4'>Duyurular</CardTitle>
                </CardHeader>
            </Card>
            <Card>
                <div style={{height: 540, width: '100%'}}>
                    <DataGrid
                        rows={store.data}
                        columns={columns}
                        rowCount={store.total}
                        pageSize={pageSize}
                        paginationMode="server"
                        sortingMode='server'
                        filterMode='server'
                        onPageChange={(params) => {
                            setPage(params.page)
                        }}
                        onPageSizeChange={(params) => {
                            setPageSize(params.pageSize)
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        slots={{
                            pagination: () => <CustomPagination
                                page={page}
                                pageSize={pageSize}
                                rowCount={store.total}
                                pageSizeOptions={[5, 10, 25]}
                                onPageChange={(newPage) => {
                                    setPage(newPage)
                                }}
                                onPageSizeChange={(newPageSize) => {
                                    setPageSize(newPageSize)
                                }}
                            />
                        }}
                        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </Card>
        </>
    )
}

export default ListAnnouncements
