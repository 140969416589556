// ** Icons Imports
import { Settings, Trash2 } from "react-feather"

// ** Reactstrap Imports
import { Button } from "reactstrap"

import { store } from "@store/store"
import { DissmisalUser } from "./store"

// const statusObj = {
//   WaitingLayOffSyncToService: {
//     class: 'light-warning'
//   },
//   Synchronized: {
//     class: 'light-success'
//   }
// }

export const columns = [
  {
    name: "Kimlik Numarası",
    sortable: true,
    minWidth: "300px",
    sortField: "identificationNumber",
    selector: row => row.identificationNumber,
    cell: row => row.identificationNumber
  },
  {
    name: "İsim",
    sortable: true,
    minWidth: "172px",
    sortField: "name",
    selector: row => row.name,
    cell: row => row.name
  },
  {
    name: "Soyisim",
    minWidth: "138px",
    sortable: true,
    sortField: "surname",
    selector: row => row.surname,
    cell: row => row.surname
  },
  {
    name: "Ana Adı",
    minWidth: "230px",
    sortable: true,
    sortField: "motherName",
    selector: row => row.motherName,
    cell: row => row.motherName
  },
  {
    name: "Baba Adı",
    minWidth: "138px",
    sortable: true,
    sortField: "fatherName",
    selector: row => row.fatherName,
    cell: row => row.fatherName
  },
  {
    name: "Giriş Tarihi",
    minWidth: "138px",
    sortable: true,
    sortField: "employmentDateView",
    selector: row => row.employmentDateView,
    cell: row => row.employmentDateView
  },
  {
    name: "Durum",
    minWidth: "138px",
    sortable: false,
    sortField: "employmentDateView",
    selector: row => row.syncStatusName,
    cell: row => row.syncStatusName
  },
  {
    name: "İşlemler",
    minWidth: "200px",
    cell: row => (
      <Button style={{ width: "200px", height: "50px" }} className="add-new-user me-1" color="danger" onClick={e => {
        e.preventDefault()
        store.dispatch(DissmisalUser({
          id: row.id,
          identificationNumber: row.identificationNumber,
          isPoliceHotelWorkerDeclarationEmploye: row.isPoliceHotelWorkerDeclarationEmploye
        }))
      }}
      >
        Çıkış Bildir
      </Button>
    )
  }
]
