// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('EdeclarationByServices/getData', async params => {
  const url = `/api/edeclarations/${params.id}/byservices?PageSize=${params.pageSize}&page=${params.page}`
  const response = await axios.get(url)
  return {
    params,
    data: response.data.services,
    totalPages: response.data.count
  }
})


export const appEdeclarationByServiceSlice = createSlice({
  name: 'appEdeclarationByServices',
  initialState: {
    data: [],
    dataServices: [],
    total: 1,
    params: {},
    allData: [],
    companyGroupSelectData: [],
    companyPasswords: [],
    selectedEdeclarationByService: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appEdeclarationByServiceSlice.reducer
