import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import { getHtmlFile } from '../store'
const OutDeclarationHtmlPopup = ({ open, toggleSidebar }) => {
    const store = useSelector(state => state.workerlist)
    const dispatch = useDispatch()

    return (
        <>
            <Modal isOpen={open} toggle={() => toggleSidebar()} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={() => toggleSidebar()}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Çıkış Bildirgesi</h1>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: store.toggleOutHtmlImagePopup?.html }} />
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex mt-2 justify-content-end'>
                    <Button className='me-1' color='primary' onClick={() => dispatch(getHtmlFile(store.toggleOutHtmlImagePopup?.id))}>Html İndir</Button>
                    <Button color='secondary' onClick={() => toggleSidebar()} outline >İptal</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default OutDeclarationHtmlPopup
