// ** React Import

// ** Third Party Components
import { Controller, useForm } from 'react-hook-form'

// ** Reactstrap Imports
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { getUserData } from "@utils"
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { addRoute, showLoading } from './store'

const SidebarNewUsers = ({ open, toggleSidebar }) => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.userFileManager)
  const user = getUserData()


  // ** Vars
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()
  // ** Function to handle form submit
  const onSubmit = (data) => {
    if (!data.folderName) {
      toast.error("Klasör Adı boş olmamalı.")
      return
    }
    toggleSidebar()
    dispatch(
      addRoute({
        CompanyId: user.companyId,
        Path: `${data.folderName}`,
        PathId: store.path.toString(),
        OldPath: ""
      })
    )
    dispatch(showLoading(true))
    reset()
  }

  return (
    <>
      <Modal isOpen={open} toggle={() => toggleSidebar()} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => toggleSidebar()}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Klasör Oluştur</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='folderName'>
                Dosya Adı <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='folderName'
                control={control}
                render={({ field }) => (
                  <Input id='folderName' placeholder='' invalid={errors.folderName && true} {...field} />
                )}
              />
            </div>
            <Button type='submit' className='me-1' color='primary'>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' onClick={() => toggleSidebar()} outline >
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarNewUsers
