// ** React Imports
import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
// ** Reactstrap Imports
import {
  Card, CardBody, CardHeader, CardTitle, Col, Row
} from 'reactstrap'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'

const UserDetail = (props) => {

  const [userData, setUserData] = useState(null)
  useEffect(() => {
    axios.get(`/api/users/${props.data}`).then(item => {
      setUserData(item.data)
    })
  }, [])

  useEffect(() => {
      }, [userData])

  const headerStyle = {
    fontWeight: 'bold',
    marginLeft: 20
  }

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle tag='h4'>
            <h1>DETAY SAYFASI</h1>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md='3'>
              {userData?.profilePhoto !== "" ? <Avatar
                className='me-1'
                contentStyles={{
                  borderRadius: 0,
                  fontSize: 'calc(48px)',
                  width: '100%',
                  height: '100%'
                }}
                style={{
                  height: '110px',
                  width: '110px'
                }}
                img={`${process.env.REACT_APP_API_URL}/${userData?.profilePhoto}`} width='32' height='32' /> : <Avatar
                initials
                className='rounded mt-3 mb-2'
                contentStyles={{
                  borderRadius: 0,
                  fontSize: 'calc(48px)',
                  width: '100%',
                  height: '100%'
                }}
                style={{
                  height: '110px',
                  width: '110px'
                }}
                color={userData?.avatarColor || 'light-primary'}
                content={`${userData?.firstName} ${userData?.lastName}` || 'John Doe'}
              />
              }
            </Col>
            {<Col md='8'>
              <p><span style={headerStyle}>Ad</span> : {userData?.firstName}</p>
              <p><span style={headerStyle}>Soyad</span> : {userData?.lastName}</p>
              <p><span style={headerStyle}>EMail</span> : {userData?.email}</p>
              <p><span style={headerStyle}>Kayıt Numarası</span> : {userData?.registrationNumber}</p>
              <p><span style={headerStyle}>Cinsiyet</span> : {userData?.gender}</p>
              <p><span style={headerStyle}>Doğum Tarihi</span> : {userData?.birthdate}</p>
              <p><span style={headerStyle}>T.C. Kimlik Numarası</span> : {userData?.identityNumber}</p>
              <p><span style={headerStyle}>Açıklama</span> : {userData?.description}</p>
              <p><span style={headerStyle}>Roller</span> : {userData?.roles}</p>
              <p><span style={headerStyle}>Sorumlu Firmalar</span> : {userData?.companies}</p>
            </Col>}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default UserDetail