// // ** React Imports
// // ** Icons Imports
// import { Settings, FileText, Trash2 } from 'react-feather'

// // ** Reactstrap Imports
import { File } from 'react-feather'
import { Button } from 'reactstrap'

export const columns = [
  {
    name: 'İşyeri Adı ve Sicil No',
    sortable: false,
    minWidth: '200px',
    selector: row => row.companyName,
    cell: row => (
        <div className='d-flex justify-content-left align-items-center'>
          <div className='d-flex flex-column'>
            <a className='user_name text-truncate text-body'>
              <span className='fw-bolder'>{row.companyName}</span>
            </a>
            <small className='text-truncate text-muted mb-0'>{row.registrationNumber}</small>
          </div>
        </div>
    )
  },
  {
    name: 'SIRA NO',
    sortable: true,
    // minWidth: '100px',
    sortField: 'sequenceNo',
    selector: row => row.sequenceNo,
    cell: row => row.sequenceNo
  },
  {
    name: 'DÖNEM',
    sortable: true,
    // minWidth: '100px',
    sortField: 'period',
    selector: row => row.period,
    cell: row => row.period
  },
  {
    name: 'ONAY TARİHİ',
    // minWidth: '138px',
    sortable: true,
    sortField: 'approvalDate',
    selector: row => row.approvalDate,
    cell: row => row.approvalDate
  },
  {
    name: 'MAHİYET',
    // minWidth: '230px',
    sortable: true,
    sortField: 'nature',
    selector: row => row.nature,
    cell: row => row.nature
  },
  {
    name: 'BT',
    // minWidth: '230px',
    sortable: true,
    sortField: 'bt',
    selector: row => row.bt,
    cell: row => row.bt
  },
  {
    name: 'KANUN',
    // minWidth: '230px',
    sortable: true,
    sortField: 'law',
    selector: row => row.law,
    cell: row => row.law
  },
  {
    name: 'YÖNTEM',
    // minWidth: '230px',
    sortable: true,
    sortField: 'method',
    selector: row => row.method,
    cell: row => row.method
  },
  {
    name: 'PER',
    // minWidth: '230px',
    sortable: true,
    sortField: 'primaryEarnings',
    selector: row => row.primaryEarnings,
    cell: row => row.primaryEarnings
  },
  {
    name: 'GÜN',
    // minWidth: '230px',
    sortable: true,
    sortField: 'day',
    selector: row => row.day,
    cell: row => row.day
  },
  {
    name: 'PEK',
    // minWidth: '230px',
    sortable: true,
    sortField: 'pek',
    selector: row => row.pek,
    cell: row => row.pek
  },
  {
    name: 'PRİM',
    // minWidth: '230px',
    sortable: true,
    sortField: 'premium',
    selector: row => row.premium,
    cell: row => row.premium
  },
  {
    name: '5510',
    // minWidth: '230px',
    sortable: true,
    sortField: 'fiveThousandFiveHundredTen',
    selector: row => row.fiveThousandFiveHundredTen,
    cell: row => row.fiveThousandFiveHundredTen
  },
  {
    name: 'TEŞVİK',
    // minWidth: '230px',
    sortable: true,
    sortField: 'premium',
    selector: row => row.premium,
    cell: row => row.premium
  },
  {
    name: 'NET PRİM',
    // minWidth: '230px',
    sortable: true,
    sortField: 'netPremium',
    selector: row => row.netPremium,
    cell: row => row.netPremium
  },
  {
    name: 'İŞSİZLİK',
    // minWidth: '230px',
    sortable: true,
    sortField: 'unemployment',
    selector: row => row.unemployment,
    cell: row => row.unemployment
  },
  {
    name: 'İŞS. İND.',
    // minWidth: '230px',
    sortable: true,
    sortField: 'unemploymentReduction',
    selector: row => row.unemploymentReduction,
    cell: row => row.unemploymentReduction
  },
  {
    name: 'NET ÖDENECEK',
    // minWidth: '230px',
    sortable: true,
    sortField: 'netAllowance',
    selector: row => row.netAllowance,
    cell: row => row.netAllowance
  },
  {
    name: '+DAMGA V.',
    // minWidth: '230px',
    sortable: true,
    sortField: 'stampDuty',
    selector: row => row.stampDuty,
    cell: row => row.stampDuty
  },
  {
    name: 'TAHAKKUK FİŞİ',
    // minWidth: '230px',
    sortField: 'accrualyFileName',
    selector: row => row.accrualyFileName,
    cell: row => (
      <Button.Ripple onClick={() => window.open(`${row.accrualyFileName}`)} className='btn-icon' outline color='primary'>
        <File size={16} />
      </Button.Ripple>
    )
  },
  {
    name: 'HİZMET LİSTESİ',
    // minWidth: '230px',
    sortField: 'serviceVoucherFileRoot',
    selector: row => row.serviceVoucherFileRoot,
    cell: row => (
      <>
        <Button.Ripple onClick={() => window.open(`${row.serviceVoucherFileRoot}`)} className='btn-icon' outline color='primary'>
          <File size={16} />
        </Button.Ripple>
      </>
    )
  }
]
