// ** React Import
import React, {Fragment} from 'react'

// ** Third Party Components

// ** Reactstrap Imports
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap'

import {useDispatch, useSelector} from 'react-redux'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {removeAnnouncement, removePopupStatus} from "../store"

const DeletePopup = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.announcements)
    const popupClose = () => {
        dispatch(removePopupStatus(false))
    }

    return (
        <>
            <Modal isOpen={store.toggleRemovePopup} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent text-center' toggle={popupClose}>
                    <h1 className='mb-1'>Silme</h1>
                </ModalHeader>
                <ModalBody >
                    <p>Bu duyuruyu silmek istediğinize emin misiniz?</p>
                </ModalBody>
                <ModalFooter>
                        <Button type='button'  color='primary' onClick={() => {
                            dispatch(removeAnnouncement(store.editData?.id))
                        }}>
                            Sil
                        </Button>
                        <Button type='button'  onClick={popupClose}>
                            İptal
                        </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default DeletePopup
