import { useForm, Controller } from 'react-hook-form'
import { useEffect } from "react"
import Flatpickr from 'react-flatpickr'
import { Button, Label, FormText, Form, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { addCompanyGroup } from "./store"
import { useDispatch, useSelector } from 'react-redux'
import '@styles/react/libs/flatpickr/flatpickr.scss'


const SidebarNewUsers = ({ open, toggleSidebar }) => {
  const dispatch = useDispatch()
  const store = useSelector((state) => state.companyGroups)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({})

  const onSubmit = data => {
    dispatch(
      addCompanyGroup({
        name: data.name
      })
    )
  }

  const closePopup = () => {
    reset()
    toggleSidebar()
  }

  useEffect(() => {
    reset()
  }, [store.createPopupOpen])

  return (
    <>
      <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Grup</h1>
            <p>Grup ekleme ekranı</p>
          </div>

          <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='name'>
                Grup Adı <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <Input id='name' placeholder='' invalid={errors.name && true} {...field} />
                )}
              />
            </div>
            <Button type='submit' disabled={store.toggleDisableButton} className='me-1' color='primary'>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' outline onClick={closePopup}>
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarNewUsers
