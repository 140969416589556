import { useEffect } from 'react'
import flatpickrConfig from "@configs/flatpickrConfig"
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Flatpickr from 'react-flatpickr'
import Cleave from 'cleave.js/react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { manualVisitDeclaration, toggleManualVisitDeclarationPopupState, getCompanyList } from '../store'

// ** Utils
import { sendDateFormat } from "@utils"

const optionsTcKimlikNo = { blocks: [11], numericOnly: true }

const SidebarManualVisitDeclaration = ({ open }) => {

  const dispatch = useDispatch()
  const store = useSelector(state => state.visit)
  const authStore = useSelector(state => state.auth)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()
  const onSubmit = data => {
    dispatch(manualVisitDeclaration({
      identificationNumber: data.identificationNumber ?? "",
      reportStartDate: data.reportStartDate ? sendDateFormat(data.reportStartDate) : "",
      workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : "",
      qualityState: data.qualityState ? data.qualityState.value : "",
      companyId: data.company ? data.company.value : 0
    }))
  }
  const popupClose = () => {
    dispatch(toggleManualVisitDeclarationPopupState(false))
    reset()
  }

  useEffect(() => {
    dispatch(getCompanyList(1))
  }, [])

  useEffect(() => {
    dispatch(getCompanyList(1))
  }, [authStore.selectedCompanyGroup])

  useEffect(() => {
    popupClose()
  }, [store.formReset])


  return (
    <>
      <Modal isOpen={open} toggle={popupClose} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Vizite</h1>
            <p>Manuel Bildirim</p>
          </div>

          <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='qualityState'>
                Sicil <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='company'
                control={control}
                render={({ field }) => (
                  <Select
                    isClearable={false}
                    options={store.companyList}
                    className='react-select'
                    classNamePrefix='select'
                    {...field}
                  />

                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='identificationNumber'>
                T.C. Kimlik No <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='identificationNumber'
                control={control}
                render={({ field }) => (
                  <Cleave className='form-control' id='identificationNumber' placeholder='' invalid={errors.name && true} {...field} options={optionsTcKimlikNo} />
                )}
              />
              <Controller
                name='companyId'
                control={control}
                render={({ field }) => (
                  <Input id='companyId' placeholder='' hidden value={7} invalid={errors.name && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='reportStartDate'>
                Rapor Başlangıç Tarihi <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='reportStartDate'
                control={control}
                render={({ field }) => (
                  <Flatpickr className='form-control' options={flatpickrConfig} id='reportStartDate' {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='workStartDate'>
                İşe Başlama Tarihi <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='workStartDate'
                control={control}
                render={({ field }) => (
                  <Flatpickr className='form-control' options={flatpickrConfig} id='workStartDate' {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='qualityState'>
                Nitelik Durumu (İstirahat Döneminde çalıştı mı?) <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='qualityState'
                control={control}
                render={({ field }) => (
                  <Select
                    isClearable={false}
                    options={[{ value: 'E', label: 'Evet' }, { value: 'H', label: 'Hayır' }]}
                    className='react-select'
                    classNamePrefix='select'
                    {...field}
                  />

                )}
              />
            </div>

            <Button type='submit' className='me-1' color='primary'>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' outline onClick={popupClose} >
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarManualVisitDeclaration