import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import toast from "react-hot-toast"

export const createPopupStatus = createAsyncThunk("announcements/createPopupStatus", async (data) => {
    return data
})

export const removePopupStatus = createAsyncThunk("announcements/removePopupStatus", async (data) => {
    return data
})
export const editDataSet = createAsyncThunk("announcements/editDataSet", async (data) => {
    return data
})

//---------Duyuru İşlemleri---------
export const createLog = createAsyncThunk("announcements/createLog", async (data) => {
    await axios.put("/api/announcements/create-log", data)
})
export const handleOpenNewTab = createAsyncThunk('announcements/handleOpenNewTab', async (url) => {
    window.open(url, '_blank')
})
export const handleDownload = createAsyncThunk('announcements/handleDownload', async (url) => {
    const link = document.createElement('a')
    link.setAttribute('href', `${url}?download=1`)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.setAttribute('download', "announcements.xlsx")
    link.click()
})
export const getData = createAsyncThunk('announcements/getData', async (params) => {
    const response = await axios.get("/api/announcements", {params})
    return {
        params,
        data: response.data.announcements,
        totalPages: response.data.pageCount
    }
})
export const getStatistic = createAsyncThunk('announcements/getStatistic', async () => {
    const response = await axios.get("/api/announcements/login/statistic")
    return response.data
})
export const removeAnnouncement = createAsyncThunk('announcements/removeAnnouncement', async (params, {dispatch}) => {
    const response = await axios.delete(`/api/announcements/${params}`)
    if (response && response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getData({Page: 1, PageSize: 25}))
        return false
    }
    return false
})
export const addAnnouncement = createAsyncThunk("announcements/addAnnouncement", async (data, {dispatch}) => {
    if (data.file.length === 0) {
        toast.error('Dosya Seçiniz..')
        return
    }
    const fdata = new FormData()
    fdata.append('file', data.file[0], data.file[0].name)
    fdata.append('title', data.title)
    fdata.append('id', data.id)


    const response = await axios.post("/api/announcements", fdata, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob'
    })
    if (response && response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getData({Page: 1, PageSize: 25}))
        return false
    }
})
export const createPoint = createAsyncThunk("announcements/createPoint", async (data, {dispatch, getState}) => {
    await axios.put("/api/announcements/point", data)
    toast.success('Puanınız kaydedildi.')
    dispatch(getData(getState().announcements.params))
})
//---------Duyuru İşlemleri---------

export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState: {
        data: [],
        total: 1,
        params: {},
        toggleCreatePopup: false,
        toggleRemovePopup: false,
        editData: null,
        statisticData: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(createPopupStatus.fulfilled, (state, action) => {
                state.toggleCreatePopup = action.payload
                state.editData = null
            })
            .addCase(addAnnouncement.fulfilled, (state, action) => {
                state.toggleCreatePopup = action.payload
            })
            .addCase(editDataSet.fulfilled, (state, action) => {
                state.editData = action.payload
            })
            .addCase(removePopupStatus.fulfilled, (state, action) => {
                state.toggleRemovePopup = action.payload
                state.editData = null
            })
            .addCase(getStatistic.fulfilled, (state, action) => {
                state.statisticData = action.payload
            })
    }
})

export default announcementsSlice.reducer
