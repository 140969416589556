import axios from 'axios'
import toast from "react-hot-toast"
import jwtDefaultConfig from './jwtDefaultConfig'
// ** Third Party Components
import { ChevronRight, X, XCircle } from 'react-feather'
// ** Custom Components
import Avatar from '@components/avatar'

const ToastContent = ({ t, title, errors, error }) => {
  return (
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color='danger' icon={<XCircle size={12} />} />
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h6>{title}</h6>
          <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
        </div>
        {
          errors.map(([key, value]) => {
            console.log(key)
            return (<><p style={{ lineHeight: "1" }}><ChevronRight size={12} />{value.join()}</p></>)
          })
        }
        {error}
      </div>
    </div>
  )
}

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            if (this.getRefreshToken() === undefined) {
              window.location = '/login'
            }
            this.refreshToken().then(res => {
              if (res.data.data === null) {
                window.location = '/login'
              }
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(res.data.data.token)
              this.setRefreshToken(res.data.data.refreshToken)

              this.onAccessTokenFetched(res.data.data.token)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        } else if (response && response.status === 400) {
          if (response.data.errors) {
            toast(t => (
              <ToastContent t={t} error={""} errors={Object.entries(response.data.errors)} title={"Lütfen Bilgileri Kontrol Edin."} />
            ))
          }
        } else if (response && response.status === 500) {
          toast(t => (
            <ToastContent t={t} errors={[]} error={response.data.error} title={"Lütfen Bilgileri Kontrol Edin."} />
          ))
        }

        return error
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.get(`${this.jwtConfig.refreshEndpoint}?refreshToken=${this.getRefreshToken()}`)
  }
}
