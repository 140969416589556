// ** Reactstrap Imports
import { Modal, ModalBody, ModalHeader, Row, Col, Label } from 'reactstrap'
import { Fragment} from 'react'
import ErrorTable from './ErrorPopupTable'
// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/react/libs/file-uploader/file-uploader.scss'
const ErrorPopup = ({ open, toggleErrorPopup }) => {

    return (
        <>
            <Modal isOpen={open} toggle={() => toggleErrorPopup()} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={() => toggleErrorPopup()}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Emniyet Bildirimi Hata Kontrol Ekranı</h1>
                    </div>
                    <Fragment>
                        <Row>
                            <Col sm='12'>
                                <ErrorTable />
                            </Col>
                        </Row>
                        <Col sm='12'>
                   
                        </Col>
                    </Fragment>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ErrorPopup
