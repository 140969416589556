// import Select from 'react-select'

// // ** Reactstrap Imports
import { Fragment, useState } from 'react'
import { Button, Col, Label, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'

// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import toast from 'react-hot-toast'
import { addIncentiveFile, buttonDisabled } from './store'
import alt_isveren_calisan_sayisi_ornek_excel from '../../../assets/incentive/alt_isveren_calisan_sayisi_ornek_excel.xlsx'

const FilePopup = ({ open, toggleFilePopup }) => {
  const dispatch = useDispatch()
  const store = useSelector(state => state.incentive)
  const authStore = useSelector(state => state.auth)

  const [selectedXmlFile, setXmlSelectedFile] = useState(null)
  const [selectedExcelFile, setExcelSelectedFile] = useState(null)

  function handleXmlFileSelection(event) {
    setXmlSelectedFile(event.target.files[0])
  }

  function handleExcelFileSelection(event) {
    setExcelSelectedFile(event.target.files[0])
  }


  const sendIncentiveData = () => {
    if (selectedXmlFile === null) {
      toast.error("Dosya boş olmamalı")
      return
    }
    const data = new FormData()
    data.append('File', selectedXmlFile, selectedXmlFile.name)
    if (selectedExcelFile) {
      data.append('SubContractorFile', selectedExcelFile, selectedExcelFile.name)
    }
    data.append(`CompanyGroupId`, authStore.selectedCompanyGroup)

    if (store.selectedCompanyId) {
      data.append(`CompanyId`, store.selectedCompanyId.value)
    }
    dispatch(buttonDisabled(true))
    dispatch(addIncentiveFile(data))

  }

  const _downloadExcel = () => {
    const link = document.createElement('a')
    const filename = 'alt_isveren_calisan_sayisi_ornek_excel.xlsx'
    link.setAttribute('href', alt_isveren_calisan_sayisi_ornek_excel)
    link.setAttribute('download', filename)
    link.click()
  }

  const closePopup = () => {
    setXmlSelectedFile(null)
    setExcelSelectedFile(null)
    toggleFilePopup()
  }

  return (
    <>
      <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Teşvik Kontrol Xml Ekle</h1>
          </div>
          <Fragment>
          <Row>
           <Col lg={12} md={12}>
            <Button.Ripple block outline color='warning' onClick={() => _downloadExcel()}>
             Örnek Excel İndir
             </Button.Ripple>
           </Col>
          </Row>
              <Row>
              <div className='form-password-toggle col-md-6 mb-1'>
                <Label className='form-label'>
                XML File Upload
                </Label>
                <Input type='file' onChange={handleXmlFileSelection} />
              </div>
              <div className='form-password-toggle col-md-6 mb-1'>
                <Label className='form-label'>
                Alt Taşeron Excel
                </Label>
                <Input type='file' onChange={handleExcelFileSelection} />
              </div>
            </Row>
            <Row className='d-flex align-items-right'>
              <Col>
                <Button type='button' disabled={store.fileButtonDisabled} className='me-1' color='primary' onClick={sendIncentiveData}>
                  Kaydet
                </Button>
                <Button type='reset' color='secondary' outline onClick={closePopup} >
                  İptal
                </Button>
              </Col>
            </Row>
          </Fragment>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FilePopup
