// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('incentiveCalculationTable/getData', async (params) => {
    let url = `/api/Incentive/GetIncentiveCalculationTable?PageSize=${params.pageSize}&page=${params.page}`
    if (params.sortColumn !== 'id') {
      url += `&Sort=${params.sort}&SortColumn=${params.sortColumn}`
    }
    if (params.q !== '') {
      url += `&Q=${params.q}`
    }
    const response = await axios.get(url)
    console.log(response)
    return {
      params,
      data: response.data.processList,
      totalPages: response.data.pageCount
    }
  })

  export const incentiveBillingSlice = createSlice({
    name: 'incentiveBilling',
    initialState: {
      data: [],
      total: 1,
      params: {}
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getData.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
        })
    }
  })

  export default incentiveBillingSlice.reducer