// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getHtmlFile = createAsyncThunk("workerList/getHtmlFile", async (data) => {
    const response = await axios.get(`/api/companyworkerlog/HtmlFile`, {
        responseType: "arraybuffer",
        "Content-Type": "blob",
        params: {Id: data}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "HtmlFile.html")
        document.body.appendChild(link)
        link.click()
    }

})

export const exportExcel = createAsyncThunk('workerList/exportExcel', async (data, {getState}) => {
    const response = await axios.get(`/api/CompanyWorker/Export`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {
            CompanyGroupId: getState().auth.selectedCompanyGroup,
            Q: data.filter.q,
            IsActive: data.activeTab === "1",
            IdentificationNumber: data.filter.IdentificationNumber,
            Name: data.filter.Name,
            Surname: data.filter.Surname,
            CompanyName: data.filter.CompanyName,
            CompanyRegistrationNumber: data.filter.CompanyRegistrationNumber,
            JobName: data.filter.JobName,
            JobCode: data.filter.JobCode
        }
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'personel_listesi.xlsx')
        document.body.appendChild(link)
        link.click()
    }

})

export const ToggleHtmlImagePopup = createAsyncThunk("workerList/ToggleHtmlImagePopup", (data) => {
    return data
})
export const ToggleOutHtmlImagePopup = createAsyncThunk("workerList/ToggleOutHtmlImagePopup", (data) => {
    return data
})
export const getData = createAsyncThunk('workerList/getData', async (params) => {
    const url = `/api/companyworkerlog`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.companyWorkerLogSpecialList,
        totalPages: response.data.pageCount
    }
})

export const getDataAllWorker = createAsyncThunk('workerList/getDataAllWorker', async (params) => {
    params.IsActive = false
    const response1 = await axios.get(`api/companyworker`, {params})
    return {
        params,
        dataAllWorker: response1.data.companyWorkers,
        totalAllPages: response1.data.pageCount
    }
})

export const getDataActiveWorker = createAsyncThunk('workerList/getDataActiveWorker', async (params) => {
    params.IsActive = true
    const response2 = await axios.get(`api/companyworker`, {params})
    return {
        params,
        dataActiveWorker: response2.data.companyWorkers,
        totalActivePages: response2.data.pageCount
    }
})

export const appCompanySlice = createSlice({
    name: 'appCompanies',
    initialState: {
        data: [],
        dataAllWorker: [],
        dataActiveWorker: [],
        total: 1,
        alltotal: 1,
        activetotal: 1,
        params: {},
        paramsAll: {},
        paramsActive: {},
        allData: [],
        companyGroupSelectData: [],
        companyPasswords: [],
        selectedCompany: null,
        fileType: "1",
        singularOutTckn: 0,
        singularOutNameSurname: "",
        singularPopupStatus: false,
        singularEntryPopupStatus: false,
        toggleHtmlImagePopup: null,
        toggleOutHtmlImagePopup: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getDataActiveWorker.fulfilled, (state, action) => {
                state.dataActiveWorker = action.payload.dataActiveWorker
                state.paramsActive = action.payload.params
                state.totalActive = action.payload.totalActivePages
            })
            .addCase(getDataAllWorker.fulfilled, (state, action) => {
                state.dataAllWorker = action.payload.dataAllWorker
                state.paramsAll = action.payload.params
                state.totalAll = action.payload.totalAllPages
            })
            .addCase(ToggleHtmlImagePopup.fulfilled, (state, action) => {
                state.toggleHtmlImagePopup = action.payload
            })
            .addCase(ToggleOutHtmlImagePopup.fulfilled, (state, action) => {
                state.toggleOutHtmlImagePopup = action.payload
            })
    }
})

export default appCompanySlice.reducer
