import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { createTab } from '../../../../redux/tabManagement'

export const toggleRolePopup = createAsyncThunk('api/toggleRolePopup', async (status) => {
  return status
})

export const updateRole = createAsyncThunk('usermanagement/updateRole', async (data, { dispatch, getState }) => {
  const response = await axios.put('/api/UserAuthorizationModule', { UpdateUserAuthorizationModules: getState().users.selectUserRoleList })
  if (response && response.status === 200) {
    toast.success('İşleminiz Başarılı')
    await dispatch(toggleRolePopup(false))
  }
})

export const selectUserRole = createAsyncThunk('usermanagement/selectRole', async (data, { getState }) => {
  const userRoleList = getState().users.selectUserRoleList
  const newUserRoleList = userRoleList.filter(x => x.id !== data.id)
  newUserRoleList.push({ id: data.id, authorizationState: data.status })
  return newUserRoleList
})

export const getRoleList = createAsyncThunk('usermanagement/getRoleList', async (id, { dispatch }) => {
  await dispatch(toggleRolePopup(true))
  const response = await axios.get(`/api/UserAuthorizationModule/${id}`)
  const selectUserRoleList = []
  response.data.forEach(element => {
    selectUserRoleList.push({ id: element.id, authorizationState: element.authorizationState })
  })
  return { userRoleList: response.data, selectUserRoleList }
})

export const getData = createAsyncThunk('usermanagement/getData', async params => {
  const url = `/api/users?PageSize=${params.perPage}&page=${params.page}&Q=${params.q}`
  const response = await axios.get(url)
  return {
    params,
    data: response.data.users,
    total: response.data.total
  }
})

export const getRolesData = createAsyncThunk('usermanagement/getRolesData', async () => {
  const response = await axios.get('/api/role')
  const selectData = []
  response.data.roles.map((item) => {
    selectData.push({ label: item.name, value: item.id })
  })
  return selectData
})

export const showHideSidebar = createAsyncThunk('user/closePopup', async (status) => {
  return status
})

export const showHideFilePopup = createAsyncThunk('user/closeFilePopup', async (status) => {
  return status
})

export const addUser = createAsyncThunk('api/users', async (data, { dispatch, getState }) => {
  const response = await axios.post('api/users', data)
  if (response && response.status === 200) {
    toast.success('İşleminiz Başarılı')
    await dispatch(getData(getState().users.params))
    await dispatch(showHideSidebar(false))
  }
})

export const selectGroup = createAsyncThunk('user/selectGroup', (data) => {
  const idList = []
  data.forEach(element => { idList.push(element.value) })
  return idList
})

export const addBulkUser = createAsyncThunk('api/addBulkUser', async (data, { dispatch, getState }) => {
  const response = await axios.post('api/users/bulkcreate', data)
  if (response.status === 200) {
    toast.success('İşleminiz Başarılı')
    await dispatch(showHideFilePopup(false))
    await dispatch(selectGroup([]))
  }

  await dispatch(getData(getState().users.params))
  return user
})

export const deleteUser = createAsyncThunk('api/users', async (data, { dispatch, getState }) => {
  await axios.delete(`/api/users/${data.id}`)
  await dispatch(getData(getState().users.params))
  return id
})

export const editData = createAsyncThunk('user/updateUser', async (data, { dispatch, getState }) => {
  const response = await axios.put('api/users', data)
  if (response && response.status === 200) {
    toast.success('İşleminiz Başarılı')
    await dispatch(getData(getState().users.params))
    await dispatch(showHideSidebar(false))
  }
})

export const getUser = createAsyncThunk('user/getUser', async id => {
  const response = await axios.get(`/api/users/${id}`)
  return response.data
})

export const getUserId = createAsyncThunk('user/getUserId', async id => {
  return id
})

export const getCompanyGroup = createAsyncThunk('user/getCompanyGroup', async () => {
  const response = await axios.get(`/api/companygroup`)
  const selectData = []
  response.data.companyGroups.map((item) => {
    selectData.push({ label: item.name, value: item.id })
  })
  return selectData
})

export const editUserCompany = createAsyncThunk('user/updateUser', async (data, { dispatch, getState }) => {
  data.companyId = data.companyId.value
  await axios.post('api/companyuser', data)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const getUserDetails = createAsyncThunk('user/getUserDetails', (data, { dispatch }) => {
  dispatch(createTab(data))
})

export const selectGender = createAsyncThunk('user/selectGender', (data) => {
  return data.value
})

export const selectRole = createAsyncThunk('user/selectRole', (data) => {
  return data.label
})

export const changeData = createAsyncThunk('user/changeData', (data) => {
  return data
})
export const profilePhoto = createAsyncThunk('user/profilePhoto', (data) => {
  return data
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: [],
    userRoleList: [],
    selectUserRoleList: [],
    total: 1,
    params: {},
    allData: [],
    toggleSidebar: false,
    toggleRolePopup: false,
    filePopupOpen: false,
    userData: {},
    profilePhoto: [],
    companyGroups: [],
    userId: 0,
    roleData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(showHideSidebar.fulfilled, (state, action) => {
        state.toggleSidebar = action.payload
        if (!action.payload) {
          state.userData = {}
          state.profilePhoto = []
        }
      })
      .addCase(showHideFilePopup.fulfilled, (state, action) => {
        state.filePopupOpen = action.payload
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.userId = action.payload
      })
      .addCase(getCompanyGroup.fulfilled, (state, action) => {
        state.companyGroups = action.payload
      })
      .addCase(getRolesData.fulfilled, (state, action) => {
        state.roleData = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userData = action.payload
      })
      .addCase(selectGroup.fulfilled, (state, action) => {
        state.userData.companyGroupIds = action.payload
      })
      .addCase(selectGender.fulfilled, (state, action) => {
        state.userData.gender = action.payload
      })
      .addCase(selectRole.fulfilled, (state, action) => {
        state.userData.roles = action.payload
      })
      .addCase(changeData.fulfilled, (state, action) => {
        state.userData[action.payload.label] = action.payload.value
      })
      .addCase(profilePhoto.fulfilled, (state, action) => {
        state.profilePhoto = action.payload
      })
      .addCase(toggleRolePopup.fulfilled, (state, action) => {
        state.toggleRolePopup = action.payload
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.userRoleList = action.payload.userRoleList
        state.selectUserRoleList = action.payload.selectUserRoleList
      })
      .addCase(selectUserRole.fulfilled, (state, action) => {
        state.selectUserRoleList = action.payload
      })
  }
})

export default userSlice.reducer