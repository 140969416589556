import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"
import toast from "react-hot-toast"

export const toggleRolePopup = createAsyncThunk("api/toggleRolePopup", async (status) => {
    return status
})

export const toggleCreatePopup = createAsyncThunk("companygroup/toggleCreatePopup", async (status) => {
    return status
})

export const toggleDisableButton = createAsyncThunk("companygroup/toggleDisableButton", async (status) => {
    return status
})

export const updateRole = createAsyncThunk("api/updateRole", async (data, {dispatch, getState}) => {
    const response = await axios.put("/api/CompanyGroupAuthorizationModule", {UpdateCompanyGroupAuthorizationModules: getState().companyGroups.selectCompanyRoleList})
    if (response && response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(toggleRolePopup(false))
    }
})

export const CreatePastPeriodAnalysis = createAsyncThunk("api/CreatePastPeriodAnalysis", async (data) => {
    const response = await axios.post("/api/CompanyGroup/CreatePastPeriodAnalysis", {companyGroupId: data})
    if (response && response.status === 200) {
        toast.success("İşleminiz Başlatıldı")
    }
})

export const ExportPastPeriodAnalysis = createAsyncThunk("api/ExportPastPeriodAnalysis", async (data) => {
    const response = await axios.post("/api/Incentive/PastPeriodAnalysisExport",
        {companyGroupId: data}, {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        })
    if (response && response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'sonuc.xlsx')
        document.body.appendChild(link)
        link.click()
        toast.success("İşleminiz Başarılı")
    }
})

export const selectRole = createAsyncThunk("api/selectRole", async (data, {getState}) => {
    const companyRoleList = getState().companyGroups.selectCompanyRoleList
    const newCompanyRoleList = companyRoleList.filter(x => x.id !== data.id)
    newCompanyRoleList.push({id: data.id, authorizationState: data.status})
    return newCompanyRoleList
})

export const getRoleList = createAsyncThunk("appCompanies/getRoleList", async (id, {dispatch}) => {
    console.log(id)
    await dispatch(toggleRolePopup(true))
    const response = await axios.get(`/api/CompanyGroupAuthorizationModule/${id}`)
    const selectCompanyRoleList = []
    response.data.forEach(element => {
        selectCompanyRoleList.push({id: element.id, authorizationState: element.authorizationState})
    })
    return {companyRoleList: response.data, selectCompanyRoleList}
})

export const getData = createAsyncThunk("companygroup/getData", async params => {
    const response = await axios.get(`/api/companygroup`, {params})
    return {
        params,
        data: response.data.companyGroups,
        totalPages: response.data.pageCount
    }
})

export const addCompanyGroup = createAsyncThunk("api/companygroup", async (data, {dispatch, getState}) => {
    await dispatch(toggleDisableButton(true))
    const response = await axios.post("/api/companygroup", data)
    await dispatch(toggleDisableButton(false))
    if (response.status === 200) {
        toast.success("İşleminiz Başarılı")
        await dispatch(getData(getState().companyGroups.params))
        dispatch(toggleCreatePopup(false))
    }
})

export const deleteCompanyGroup = createAsyncThunk("api/companygroup", async (data, {dispatch, getState}) => {
    await axios.delete("api/companygroup", {data})
    await dispatch(getData(getState().companyGroups.params))
    await dispatch(getAllData())
    return id
})

export const editData = createAsyncThunk("companygroup/updateCompanyGroup", async (data, {dispatch, getState}) => {
    await axios.put("api/companygroup", data)
    await dispatch(getData(getState().companyGroups.params))
    await dispatch(getAllData())
    return id
})

export const getCompanyGroup = createAsyncThunk("companygroup/getCompanyGroup", async id => {
    const response = await axios.get(`/api/companygroup/${id}`) //?id=$
    return response.data.companyGroup
})

export const toggleUpdatePopupClose = createAsyncThunk("companyGroup/closePopup", (status) => {
    return status
})

export const companyGroupSlice = createSlice({
    name: "companyGroup",
    initialState: {
        data: [],
        companyRoleList: [],
        selectCompanyRoleList: [],
        total: 1,
        params: {},
        allData: [],
        selectedCompanyGroupName: null,
        selectedCompanyGroupId: 0,
        toggleUpdatePopupClose: false,
        toggleRolePopup: false,
        toggleDisableButton: false,
        closePopup: 0,
        createPopupOpen: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(toggleUpdatePopupClose.fulfilled, (state, action) => {
                state.toggleUpdatePopupClose = action.payload
            })
            .addCase(toggleDisableButton.fulfilled, (state, action) => {
                state.toggleDisableButton = action.payload
            })
            .addCase(toggleCreatePopup.fulfilled, (state, action) => {
                state.createPopupOpen = action.payload
            })
            .addCase(getCompanyGroup.fulfilled, (state, action) => {
                state.selectedCompanyGroupName = action.payload.name
                state.selectedCompanyGroupId = action.payload.id
            })
            .addCase(getRoleList.fulfilled, (state, action) => {
                state.companyRoleList = action.payload.companyRoleList
                state.selectCompanyRoleList = action.payload.selectCompanyRoleList
            })
            .addCase(selectRole.fulfilled, (state, action) => {
                state.selectCompanyRoleList = action.payload
            })
            .addCase(toggleRolePopup.fulfilled, (state, action) => {
                state.toggleRolePopup = action.payload
            })
            .addCase(addCompanyGroup.fulfilled, (state, action) => {
                state.closePopup = action.payload
            })
    }
})

export default companyGroupSlice.reducer