import '@styles/react/libs/flatpickr/flatpickr.scss'
import Cleave from 'cleave.js/react'
import Flatpickr from 'react-flatpickr'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {Button, Form, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap'
import {entrySgkWorkerDeclarationApi, toggleSgkSingularEntryPopupClose} from '../store'
// ** Configs
import flatpickrConfig from "@configs/flatpickrConfig"
// ** Utils
import {sendDateFormat} from "@utils"
import {useEffect, useState} from 'react'
import axios from 'axios'

const SidebarSingularSgkEntry = ({open}) => {
    const store = useSelector(state => state.sgkWorkerDeclaration)
    const dispatch = useDispatch()
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [postData, setPostData] = useState(null)

    const [continuePopup, setContinuePopup] = useState(false)

    const continuePopupClose = () => {
        setContinuePopup(false)
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            identificationNumber: "",
            workStartDate: "",
            insuranceLever: "",
            disabilityCode: "",
            exLaw: "",
            educationStatus: "",
            graduationYear: "",
            graduationSection: "",
            workPartially: "",
            workPartiallyDays: "",
            cgbis: "",
            jobName: "",
            dutyCode: "",
            name: "",
            surname: "",
            companyId: ""
        }
    })
    const popupClose = () => {
        dispatch(toggleSgkSingularEntryPopupClose(false))
        reset()
    }

    const onSubmit = async (data) => {
        setPostData(data)
        const response = await axios.post('/api/CompanyWorker/sgkSingularEntryCheck', {
            workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null
        })

        if (response.data) {
            setContinuePopup(true)
            popupClose()
        } else {
            dispatch(
                entrySgkWorkerDeclarationApi({
                    identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                    workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                    insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                    disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                    exLaw: data.exLaw ? data.exLaw.value : "",
                    educationStatus: data.educationStatus ? data.educationStatus.value : "",
                    graduationYear: data.graduationYear ? data.graduationYear : 0,
                    graduationSection: data.graduationSection,
                    workPartially: data.workPartially ? data.workPartially.value : "",
                    workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                    cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                    jobName: data.jobName ? data.jobName.value.toString() : "",
                    dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                    name: data.name,
                    surname: data.surname,
                    companyId: data.company ? data.company.value : 0
                })
            )
        }
    }

    const continueOnSubmit = () => {
        const data = postData
        dispatch(
            entrySgkWorkerDeclarationApi({
                identificationNumber: data.identificationNumber ? data.identificationNumber : 0,
                workStartDate: data.workStartDate ? sendDateFormat(data.workStartDate) : null,
                insuranceLever: data.insuranceLever ? data.insuranceLever.value.toString() : -1,
                disabilityCode: data.disabilityCode ? data.disabilityCode.value : "",
                exLaw: data.exLaw ? data.exLaw.value : "",
                educationStatus: data.educationStatus ? data.educationStatus.value : "",
                graduationYear: data.graduationYear ? data.graduationYear : 0,
                graduationSection: data.graduationSection,
                workPartially: data.workPartially ? data.workPartially.value : "",
                workPartiallyDays: data.workPartiallyDays ? data.workPartiallyDays : -1,
                cgbis: data.cgbis ? data.cgbis.value.toString() : "",
                jobName: data.jobName ? data.jobName.value.toString() : "",
                dutyCode: data.dutyCode ? data.dutyCode.value.toString() : "",
                name: data.name,
                surname: data.surname,
                companyId: data.company ? data.company.value : 0
            })
        )
        setContinuePopup(false)
    }

    useEffect(() => {
        reset()
    }, [store.singularEntryPopupStatus])

    const fetchOptions = async (input) => {
        if (!input) {
            setOptions([])
            return
        }

        try {
            const response = await axios.get(`/api/CommonValue/Job`, {
                params: {Query: input}
            })

            const fetchedOptions = response.data.map((item) => ({
                value: item.value,
                label: `${item.value} - ${item.name}`
            }))

            setOptions(fetchedOptions)
        } catch (error) {
            console.error('Error fetching options:', error)
        }
    }

    const handleInputChange = (newValue) => {
        setInputValue(newValue)
        fetchOptions(newValue)
    }

    const optionsTcKimlikNo = {blocks: [11], numericOnly: true}
    const optionsgraduationYear = {blocks: [4], numericOnly: true}
    const optionsworkPartiallyDays = {blocks: [2], numericOnly: true}
    return (
        <>
            <Modal isOpen={open} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>SGK</h1>
                        <p>Tekil Giriş Bildirimi</p>
                    </div>

                    <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-1'>
                            <Label className='form-label' for='company'>
                                Sicil <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='company'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.companyList}
                                        className='react-select'
                                        classNamePrefix='select'
                                        {...field}
                                    />

                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='identificationNumber'>
                                T.C. Kimlik No <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='identificationNumber'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='identificationNumber' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsTcKimlikNo}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='workStartDate'>
                                Giriş Tarihi <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='workStartDate'
                                control={control}
                                render={({field}) => (
                                    <Flatpickr className='form-control' options={flatpickrConfig}
                                               id='workStartDate' {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='insuranceLever'>
                                Sigorta Kolu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='insuranceLever'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.InsuranceTypesData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='disabilityCode'>
                                Engellilik Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='disabilityCode'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={[{label: 'Evet', value: 'E'}, {label: 'Hayır', value: 'H'}]}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='exLaw'>
                                Eski Hükümlü <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='exLaw'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={[{label: 'Evet', value: 'E'}, {label: 'Hayır', value: 'H'}]}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='educationStatus'>
                                Öğrenim Durumu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='educationStatus'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.sgkEducationData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='graduationYear'>
                                Mezuniyet Yılı
                            </Label>
                            <Controller
                                name='graduationYear'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='graduationYear' placeholder=''
                                            invalid={errors.name && true} {...field} options={optionsgraduationYear}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='graduationSection'>
                                Mezuniyet Bölümü
                            </Label>
                            <Controller
                                name='graduationSection'
                                control={control}
                                render={({field}) => (
                                    <Input id='graduationSection' placeholder=''
                                           invalid={errors.name && true} {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='workPartially'>
                                Kısmi Süreli Çalışıyor Mu? <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='workPartially'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={[{value: 'Evet', label: 'Evet'}, {value: 'Hayır', label: 'Hayır'}]}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='workPartiallyDays'>
                                Çalışılacak Gün Sayısı <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='workPartiallyDays'
                                control={control}
                                render={({field}) => (
                                    <Cleave className='form-control' id='workPartiallyDays' placeholder=''
                                            invalid={errors.name && true} {...field}
                                            options={optionsworkPartiallyDays}/>
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='educationStatus'>
                                CGBIS İş Kolu<span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='cgbis'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.CSGBBusinessDepartmentsData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='jobName'>
                                Sigortalı Meslek Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='jobName'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        className='react-select'
                                        classNamePrefix='select'
                                        options={options}
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        placeholder="Aramak için yazın..."
                                        isClearable
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='dutyCode'>
                                Görev Kodu <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='dutyCode'
                                control={control}
                                render={({field}) => (
                                    <Select
                                        isClearable={false}
                                        options={store.DutyCodesData}
                                        className='react-select'
                                        classNamePrefix='select'
                                        invalid={errors.companyGroupId && true}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='name'>
                                Ad <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='name'
                                control={control}
                                render={({field}) => (
                                    <Input id='name' placeholder='' invalid={errors.name && true} {...field} />
                                )}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='form-label' for='surname'>
                                Soyad <span className='text-danger'>*</span>
                            </Label>
                            <Controller
                                name='surname'
                                control={control}
                                render={({field}) => (
                                    <Input id='surname' placeholder='' invalid={errors.name && true} {...field} />
                                )}
                            />
                        </div>

                        <Button type='submit' className='me-1' color='primary'>
                            Kaydet
                        </Button>
                        <Button type='reset' color='secondary' outline onClick={popupClose}>
                            İptal
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={continuePopup} toggle={continuePopupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={continuePopupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Dikkat</h1>
                        <p>İşe Giriş İşleminiz Cezaya Tabii Tutulacaktır. Devam Etmek İstiyor Musunuz?</p>
                    </div>
                    <Button type='submit' className='me-1' color='primary' onClick={continueOnSubmit}>
                        Devam Et
                    </Button>
                    <Button type='reset' color='secondary' outline onClick={continuePopupClose}>
                        İptal
                    </Button>
                </ModalBody>
            </Modal>
        </>
    )
}
export default SidebarSingularSgkEntry