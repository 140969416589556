// ** React Imports
import { Fragment, useEffect, useState } from 'react'
// ** Table Columns
import { columns } from './ErrorPopupTableColumns'
// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { getData } from './store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import { ChevronDown, Copy, File, FileText, Grid, Printer, Share } from 'react-feather'
import ReactPaginate from 'react-paginate'
// ** Reactstrap Imports
import { Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown
} from 'reactstrap'
// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
// ** Table Header
const CustomHeader = ({ store, handlePerPage, rowsPerPage, handleFilter, searchTerm }) => {
    // ** Converts table to CSV
    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ','
        const lineDelimiter = '\n'
        const keys = Object.keys(store.data[0])

        result = ''
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach(item => {
            let ctr = 0
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter

                result += item[key]

                ctr++
            })
            result += lineDelimiter
        })

        return result
    }

    // ** Downloads CSV
    function downloadCSV(array) {
        const link = document.createElement('a')
        let csv = convertArrayOfObjectsToCSV(array)
        if (csv === null) return

        const filename = 'export.csv'

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`
        }

        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
    }
    return (
        <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
                <Col xl='6' className='d-flex align-items-center p-0'>
                    <div className='d-flex align-items-center w-100'>
                        <label htmlFor='rows-per-page'>Toplam</label>
                        <Input
                            className='mx-50'
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            style={{ width: '5rem' }}>
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                        <label htmlFor='rows-per-page'>Kayıt Göster</label>
                    </div>
                </Col>
                <Col
                    xl='6'
                    className='d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1'
                >
                    <div className='d-flex align-items-center mb-sm-0 mb-1 me-1'>
                        <label className='mb-0' htmlFor='search-invoice'>
                            Ara:
                        </label>
                        <Input
                            id='search-invoice'
                            className='ms-50 w-100'
                            type='text'
                            value={searchTerm}
                            onChange={e => handleFilter(e.target.value)} />
                    </div>
                    <div className='d-flex align-items-center table-header-actions col-md-12'>
                        <UncontrolledDropdown className='me-1'>
                            <DropdownToggle color='secondary' caret outline>
                                <Share className='font-small-4 me-50' />
                                <span className='align-middle'>Dışa Aktar</span>
                            </DropdownToggle>
                            <DropdownMenu container="body">
                                <DropdownItem className='w-100'>
                                    <Printer className='font-small-4 me-50' />
                                    <span className='align-middle'>Print</span>
                                </DropdownItem>
                                <DropdownItem className='w-100' onClick={() => downloadCSV(store.data)}>
                                    <FileText className='font-small-4 me-50' />
                                    <span className='align-middle'>CSV</span>
                                </DropdownItem>
                                <DropdownItem className='w-100'>
                                    <Grid className='font-small-4 me-50' />
                                    <span className='align-middle'>Excel</span>
                                </DropdownItem>
                                <DropdownItem className='w-100'>
                                    <File className='font-small-4 me-50' />
                                    <span className='align-middle'>PDF</span>
                                </DropdownItem>
                                <DropdownItem className='w-100'>
                                    <Copy className='font-small-4 me-50' />
                                    <span className='align-middle'>Copy</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
          
                    </div>
                </Col>
            </Row>
        </div>
    )
}
const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.police)
    const authStore = useSelector(state => state.auth)
    // ** States
    const [sort, setSort] = useState('desc')
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [sortColumn, setSortColumn] = useState('id')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    // ** Function to toggle sidebar
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
    const getDataParameter = () => {
        return {
            sort,
            sortColumn
         }
    }
    // ** Get data on mount
    useEffect(() => {
        dispatch(
            getData(getDataParameter())
        )
    }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage])
    // ** Function in get data on page change
    const handlePagination = page => {
        dispatch(getData(getDataParameter()
        ))
        setCurrentPage(page.selected + 1)
    }
    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        dispatch(
            getData(getDataParameter())
        )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        dispatch(
            getData(getDataParameter())
        )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={store.total || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getData(getDataParameter())
        )
    }

    return (
        <Fragment>

            <Card className='overflow-hidden'>
                <div className='react-dataTable'>
                    <DataTable
                        noHeader
                        subHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        columns={columns}
                        onSort={handleSort}
                        sortIcon={<ChevronDown />}
                        className='react-dataTable'
                        paginationComponent={CustomPagination}
                        data={dataToRender()}
                        subHeaderComponent={
                            <CustomHeader
                                store={store}
                                searchTerm={searchTerm}
                                rowsPerPage={rowsPerPage}
                                handleFilter={handleFilter}
                                handlePerPage={handlePerPage}
                                toggleSidebar={toggleSidebar}
                 

                            />
                        }
                    />
                </div>
            </Card>

         </Fragment>
    )
}

export default UsersList
