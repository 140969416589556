import { lazy } from 'react'

const Home = lazy(() => import('../../views/Home'))

const DashboardRoutes = [
  {
    path: '/home',
    element: <Home />
  }

]

export default DashboardRoutes
