// ** React Import
import React, {Fragment, useEffect, useState} from 'react'

// ** Third Party Components
import Select from 'react-select'
import {useDropzone} from "react-dropzone"


// ** Reactstrap Imports
import {
    Button, Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap'

import {useDispatch, useSelector} from 'react-redux'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {DownloadCloud, FileText, X} from "react-feather"
import {addAnnouncement, createPopupStatus} from "../store"

const CreatePopup = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.announcements)

    const [files, setFiles] = useState([])
    const [title, setTitle] = useState("")
    const [editId, setEditId] = useState(0)

    useEffect(() => {
        setTitle("")
        setFiles([])
    }, [store.toggleCreatePopup])

    useEffect(() => {
        if (store.editData) {
            setTitle(store.editData?.title)
            setEditId(store.editData?.id)
        }
        setFiles([])
    }, [store.editData])

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop: result => {
            setFiles([...result.map(file => Object.assign(file))])
        }
    })
    const handleRemoveFile = file => {
        const uploadedFiles = files
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        setFiles([...filtered])
    }
    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28'/>
        } else {
            return <FileText size='28'/>
        }
    }
    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }

    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>{renderFilePreview(file)}</div>
                <div>
                    <p className='file-name mb-0'>{file.name}</p>
                    <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                <X size={14}/>
            </Button>
        </ListGroupItem>
    ))

    const popupClose = () => {
        dispatch(createPopupStatus(false))
    }

    return (
        <>
            <Modal isOpen={store.toggleCreatePopup} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Duyuru</h1>
                        <p>Yeni Duyuru ekleme ekranı</p>
                    </div>

                    <Row>
                        <Col sm='12'>
                            <div className='mb-1'>
                                <Label className='form-label' for='period'>
                                    <span className='bold'>Duyuru Başlığı</span> <span
                                    className='text-danger'>*</span>
                                </Label>
                                <Input onChange={(event) => setTitle(event.target.value)} value={title}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mb-1'>
                        <Col sm='12'>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className='d-flex align-items-center justify-content-center flex-column'>
                                    <DownloadCloud size={64}/>
                                    <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                                    <p className='text-secondary'><a href='/' onClick={e => e.preventDefault()}>
                                        Araştır
                                    </a>{' '}
                                    </p>
                                </div>
                            </div>
                            {files.length ? (
                                <Fragment>
                                    <ListGroup className='my-2'>{fileList}</ListGroup>
                                </Fragment>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='12'>
                            <Button type='button' className='me-1' color='primary' onClick={() => {
                                dispatch(addAnnouncement({
                                    file: files,
                                    title,
                                    id: editId
                                }))
                                setTitle("")
                                setFiles([])
                            }}>
                                Kaydet
                            </Button>
                            <Button type='button' className='me-1' onClick={popupClose}>
                                İptal
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default CreatePopup
