import '@styles/react/libs/flatpickr/flatpickr.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { approvedReportCancel, detailPopupStateClose } from '../store'


const SidebarEditUser = ({ open }) => {

  const store = useSelector(state => state.approvedVisits)
  const dispatch = useDispatch()

  const headerStyle = {
    fontWeight: 'bold',
    marginLeft: 20
  }

  const approveCancelTextStyle = {
    marginLeft: 20
  }

  const cancelButtonStyle = {
    marginLeft: 20,
    width: 200
  }


  return (
    <>
      <Modal isOpen={open} toggle={() => dispatch(detailPopupStateClose(false))} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={() => dispatch(detailPopupStateClose(false))}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Detay</h1>
            <p>Onaylı Vizite Detay Ekranı</p>
          </div>

          <Row>
            {<Col md='12'>
              <p><span style={headerStyle}>Bildirim ID</span> : {store?.detailVisitData?.data?.declarationId}</p>
              <p><span style={headerStyle}>Medular Rapor ID</span> : {store?.detailVisitData?.data?.medularReportId}</p>
              <p><span style={headerStyle}>Başlangıç Tarihi</span> : {store?.detailVisitData?.data?.startDate}</p>
              <p><span style={headerStyle}>Bitiş Tarihi</span> : {store?.detailVisitData?.data?.endDate}</p>
              <p><span style={headerStyle}>İşlem Tarihi</span> : {store?.detailVisitData?.data?.processDate}</p>
              <p><span style={headerStyle}>Çalışma Durumu</span> : {store?.detailVisitData?.data?.workDidntWork}</p>
              <p><span style={headerStyle}>Ödeme Durumu</span> : {store?.detailVisitData?.data?.isPayment}</p>
              <p><span style={headerStyle}>Onay İptal İçin Zaman Durumu</span> : {store?.detailVisitData?.data?.hasTimePassed}</p>
              <hr></hr>
              {store?.detailVisitData?.data?.isPayment === 'Yapılmadı' && store?.detailVisitData?.data?.hasTimePassed === 'İptal edilebilir' ? <Button style={approveCancelTextStyle} type='button' color='danger' onClick={() => dispatch(approvedReportCancel({ MedulaReportId: store?.detailVisitData?.data?.detail?.medularReportId.toString(), DeclarationId: store?.detailVisitData?.data?.detail?.declarationId.toString(), companyId: 7 }))} >
                Onay İptal Et
              </Button> : <p style={approveCancelTextStyle}>İptal işlemi yapılamaz</p>
              }
              <hr></hr>
            </Col>}
          </Row>

          <br></br>

          <Row className="justify-content-center">
            <Button style={cancelButtonStyle} type='button' color='primary' onClick={() => dispatch(detailPopupStateClose(false))} >
              Kapat
            </Button>
          </Row>


        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarEditUser