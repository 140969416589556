// import Select from 'react-select'

// // ** Reactstrap Imports
import {Fragment, useState} from 'react'
import Select from 'react-select'
import {Button, Col, Label, Input, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'

import {useDispatch, useSelector} from 'react-redux'

// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {onlyIncentiveWorkerIdentification} from './store'

const subMonths = (date, months) => {
    const newDate = new Date(date)
    const d = newDate.getDate()
    newDate.setMonth(newDate.getMonth() - months)

    // Ayın sonunda, eski tarih yeni aydaki maksimum gün sayısını aşıyorsa düzeltilmesi gerekir
    if (newDate.getDate() !== d) {
        newDate.setDate(0)
    }
    return newDate
}

// YYYYMM formatında tarih dönüştürme fonksiyonu
const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Ayları 2 basamaklı yapmak için
    return `${year}${month}`
}

const generatePeriodOptions = () => {
    const options = []
    const seen = new Set() // Aynı ayın tekrar edilmesini önlemek için Set kullanıyoruz
    for (let i = 0; i < 4; i++) {
        const periodDate = subMonths(new Date(), i)
        const periodValue = formatDate(periodDate)
        if (!seen.has(periodValue)) {
            options.push({ value: periodValue, label: periodValue })
            seen.add(periodValue)
        }
    }
    return options
}

const SelectPeriodPopup = ({open, toggleSelectPeriod}) => {
    const dispatch = useDispatch()
    // const store = useSelector(state => state.incentive)
    const authStore = useSelector(state => state.auth)

    const [selectedPeriods, setSelectedPeriods] = useState([])

    // const generatePeriodOptions = () => {
    //     const options = []
    //     for (let i = 0 i < 4 i++) {
    //         const periodDate = subMonths(new Date(), i)
    //         const periodValue = formatDate(periodDate)
    //         options.push({value: periodValue, label: periodValue})
    //     }
    //     return options
    // }

    const handleChange = (selectedOptions) => {
        setSelectedPeriods(selectedOptions || [])
    }

    const save = () => {
        const selectedValues = (selectedPeriods || []).map(option => option.value)
        dispatch(onlyIncentiveWorkerIdentification({
            companyGroupId: authStore.selectedCompanyGroup,
            periodList: selectedValues
        }))
    }

    const closePopup = () => {
        toggleSelectPeriod()
    }

    return (
        <>
            <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Period Seç</h1>
                    </div>
                    <Fragment>
                        <Row>
                            <div className='form-password-toggle col-md-12 mb-1'>
                                <Label className='form-label'>
                                    Period Seçiniz
                                </Label>
                                <Select
                                    isClearable={false}
                                    isMulti
                                    options={generatePeriodOptions()}
                                    className='react-select'
                                    classNamePrefix='select'
                                    onChange={handleChange}
                                />
                            </div>
                        </Row>
                        <Row className='d-flex align-items-right'>
                            <Col>
                                <Button type='button' className='me-1' color='primary' onClick={save}>
                                    Kaydet
                                </Button>
                                <Button type='reset' color='secondary' outline onClick={closePopup}>
                                    İptal
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </>
    )
}

export default SelectPeriodPopup
