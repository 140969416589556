// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createTab} from '../../../../redux/tabManagement'
// ** Axios Imports
import axios from 'axios'

export const ToggleHtmlPopup = createAsyncThunk('workaccidents/OpenHtmlPopup', (data) => {
    return data
})
export const ToggleVisitHtmlImagePopup = createAsyncThunk('workaccidents/ToggleVisitHtmlImagePopup', (data) => {
    return data
})
export const getData = createAsyncThunk('workaccidents/getData', async (params) => {
    const url = `/api/workaccidents`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.workAccidents,
        totalPages: response.data.pageCount,
        credential: response.data.credential
    }
})

export const getDataSubAgent = createAsyncThunk('workaccidents/getDataSubAgent', async (params) => {
    const url = `/api/workaccidents`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.workAccidents,
        totalPages: response.data.pageCount,
        credential: response.data.credential
    }
})

export const exportExcel = createAsyncThunk('workaccidents/exportExcel', async (data, {getState}) => {
    console.log(data)
    const response = await axios.get(`/api/workaccidents/Export`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {CompanyGroupId: getState().auth.selectedCompanyGroup, Q: getState().workAccident.params.q}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'iskazasi_listesi.xlsx')
        document.body.appendChild(link)
        link.click()
    }

})

export const getHtmlFile = createAsyncThunk('workaccidents/getHtmlFile', async (data, {getState}) => {
    const response = await axios.get(`/api/visits/HtmlFile`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {ReportTrackingNo: getState().workAccident.toggleVisitHtmlImagePopup.visitReportNumber}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'HtmlFile.html')
        document.body.appendChild(link)
        link.click()
    }

})

export const getCompanyDetails = createAsyncThunk('workaccidents/getCompanyDetails', (data, {dispatch}) => {
    dispatch(createTab(data))
})

export const getAllCompanies = createAsyncThunk('workaccidents/getAllCompaniesByGroupId', async (params, {getState}) => {
    const url = `/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`
    const response = await axios.get(url)
    return response.data
})

export const getDecryptedCompanyServiceAccess = async (selectedCompanyId) => {
    const url = '/api/CompanyServiceAccess'
    const payload = {
        params: {
            companyId: selectedCompanyId,
            serviceType: 0,
            decryptData: true
        }
    }
    const response = await axios.get(url, payload)
    return response.data
}

export const appWorkAccidentsSlice = createSlice({
    name: 'appedeclarations',
    initialState: {
        data: [],
        subAgentData: [],
        credential: null,
        toggleHtmlPopup: null,
        toggleVisitHtmlImagePopup: null,
        total: 1,
        params: {},
        allData: [],
        subAgentTotal: 1,
        subAgentParams: {},
        subAgentAllData: [],
        companyGroupSelectData: [],
        companyPasswords: [],
        selectedWorkAccidents: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
                state.credential = action.payload.credential
            })
            .addCase(getDataSubAgent.fulfilled, (state, action) => {
                state.subAgentData = action.payload.data
                state.subAgentParams = action.payload.params
                state.subAgentTotal = action.payload.totalPages
            })
            .addCase(ToggleHtmlPopup.fulfilled, (state, action) => {
                state.toggleHtmlPopup = action.payload
            })
            .addCase(ToggleVisitHtmlImagePopup.fulfilled, (state, action) => {
                state.toggleVisitHtmlImagePopup = action.payload
            })
            .addCase(getAllCompanies.fulfilled, (state, action) => {
                state.companyGroupSelectData = action.payload
            })
    }
})

export default appWorkAccidentsSlice.reducer
