// ** React Imports
import { Fragment } from 'react'

// ** Roles Components
import RoleCards from './RoleCards'

const Roles = () => {
    return (
        <Fragment>
            <RoleCards />
        </Fragment>
    )
}

export default Roles