import { lazy } from 'react'

const Edeclaration = lazy(() => import('../../views/pages/edeclaration/list/index'))
const Gendarmerie = lazy(() => import('../../views/pages/WorkerDeclaration/Gendarmerie/index'))
const Police = lazy(() => import('../../views/pages/WorkerDeclaration/Police/index'))
const WorkerEntryLayoffDeclaration = lazy(() => import('../../views/pages/WorkerDeclaration/Sgk/index'))
const WorkerAccident = lazy(() => import('../../views/pages/work-accident/list'))
const Visit = lazy(() => import('../../views/pages/visit/list'))
const ApprovedVisits = lazy(() => import('../../views/pages/visit-approved/list/index'))

const AutomationRoutes = [
  {
    path: '/e-bildirge',
    element: <Edeclaration />
  },

  {
    path: '/jandarma-bildirimi',
    element: <Gendarmerie />
  },
  {
    path: '/emniyet-bildirimi',
    element: <Police />
  },
  {
    path: '/is-kazasi',
    element: <WorkerAccident />
  },
  {
    path: '/visit',
    element: <Visit />
  },
  {
    path: '/approved-visit',
    element: <ApprovedVisits />
  },
  {
    path: '/ise-giris-cikis-bildirimi',
    element: <WorkerEntryLayoffDeclaration />
  }
]

export default AutomationRoutes
