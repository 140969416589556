// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
const fileManagerApiUrl = process.env.REACT_APP_CDN_API_URL

// ** Axios Imports
import axios from 'axios'

export const getUsers = createAsyncThunk('fileManager/getData', async (data, { getState }) => {
  const params = {}
  if (getState().auth.selectedCompanyGroup) {
    params.companyGroupId = getState().auth.selectedCompanyGroup
  } else {
    params.companyGroupId = 0
  }
  const response = await axios.get(`/api/users`, { params })
  const selectData = []
  response.data.users.forEach(element => {
    selectData.push({ label: `${element.firstAndLastName}`, value: element.id })
  })
  return selectData
})

export const getRoutes = createAsyncThunk('fileManager/getRoutes', async (pathId, { getState }) => {
  let systemPath = false
  const systemPathControl = getState().userFileManager.allPaths.filter(x => x.id === pathId)
  if (systemPathControl.length > 0) systemPath = systemPathControl[0].systemPath
  let url = `${fileManagerApiUrl}/file/paths?companyId=7&systemPath=${systemPath}`
  let fileList = []
  if (pathId > 0) {
    url += `&route=${pathId}`
    const responseFileList = await axios.get(`${fileManagerApiUrl}/file?companyId=7&route=${path}`)
    fileList = responseFileList.data.files
  }
  const response = await axios.get(url)
  return {
    paths: response.data.paths,
    files: fileList,
    pathLoading: false
  }
})

export const getRoutePath = createAsyncThunk('fileManager/getRoutePath', async (path, { getState }) => {
  let systemPath = false
  const systemPathControl = getState().userFileManager.allPaths.filter(x => x.id === path)
  if (systemPathControl.length > 0) systemPath = systemPathControl[0].systemPath
  const response = await axios.get(`${fileManagerApiUrl}/file/paths?companyId=7&route=${path}&systemPath=${systemPath}`)
  const responseFileList = await axios.get(`${fileManagerApiUrl}/file?companyId=7&route=${path}`)
  return {
    paths: response.data.paths,
    files: responseFileList.data.files,
    pathLoading: false
  }
})

export const addRoute = createAsyncThunk('fileManager/addRoute', async (request, { dispatch, getState }) => {
  await axios.post(`${fileManagerApiUrl}/file/createroute`, request)
  await dispatch(getRoutePath(getState().userFileManager.path))
  return { loading: false }
})

export const uploadFile = createAsyncThunk('fileManager/uploadFile', async (request, { dispatch, getState }) => {
  await axios.post(`${fileManagerApiUrl}/file`, request)
  await dispatch(getRoutePath(getState().userFileManager.path))
  return { loading: false }
})

export const showLoading = createAsyncThunk('fileManager/showLoading', async (request) => {
  return request
})

export const selectRoute = createAsyncThunk('fileManager/selectRoute', async (request, { dispatch, getState }) => {
  const systemPathControl = getState().userFileManager.paths.filter(x => x.id === request)
  let systemPath = false
  if (systemPathControl.length > 0) {
    systemPath = systemPathControl[0].systemPath
  }

  await dispatch(showLoading(true))
  await dispatch(getRoutePath(request))
  return {
    path: request,
    systemPathUploadButtonShowHide: systemPath
  }
})

export const selectAuthUser = createAsyncThunk('fileManager/selectAuthUser', async (request) => {
  const idList = []
  request.forEach(element => { idList.push(element.value) })
  return idList
})

export const editRoute = createAsyncThunk('fileManager/editRoute', async (request, { getState }) => {
  const authListData = getState().userFileManager.paths.filter(x => x.id === request)[0].authList
  const selectedData = []
  authListData.forEach(element => { selectedData.push(element.value) })
  return {
    pathId: request,
    selectedData
  }
})

export const routeBack = createAsyncThunk('fileManager/routeBack', async (data, { dispatch, getState }) => {
  const backPathIdList = getState().userFileManager.selectPaths.filter(x => x !== getState().userFileManager.path)
  let pathId = 0
  if (backPathIdList.length > 0) pathId = backPathIdList[backPathIdList.length - 1]
  await dispatch(showLoading(true))
  await dispatch(getRoutePath(pathId))
  return { pathId, backPathIdList }
})

export const SetPathAuth = createAsyncThunk('fileManager/SetPathAuth', async (data, { dispatch, getState }) => {
  await axios.post(`${fileManagerApiUrl}/file/auth`, { UserIds: data, PathId: getState().userFileManager.editPathId })
  await dispatch(getRoutePath(getState().userFileManager.path))
  return data
})


export const fileManagerSlice = createSlice({
  name: 'fileManager',
  initialState: {
    paths: [],
    allPaths: [],
    selectPaths: [],
    path: '0',
    editPathId: '0',
    files: [],
    total: 1,
    params: {},
    allData: [],
    authSelectedData: [],
    users: [],
    selected: null,
    pathLoading: true,
    systemPathUploadButtonShowHide: false
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getRoutes.fulfilled, (state, action) => {
        state.paths = action.payload.paths
        state.allPaths = [...state.allPaths, ...action.payload.paths]
        state.files = action.payload.files
        state.pathLoading = action.payload.pathLoading
      })
      .addCase(getRoutePath.fulfilled, (state, action) => {
        state.paths = action.payload.paths
        state.allPaths = [...state.allPaths, ...action.payload.paths]
        state.files = action.payload.files
        state.pathLoading = action.payload.pathLoading
      })
      .addCase(selectRoute.fulfilled, (state, action) => {
        state.path = action.payload.path
        state.systemPathUploadButtonShowHide = action.payload.systemPathUploadButtonShowHide
        state.selectPaths.push(action.payload.path)
      })
      .addCase(routeBack.fulfilled, (state, action) => {
        state.path = action.payload.pathId
        state.selectPaths = action.payload.backPathIdList
      })
      .addCase(editRoute.fulfilled, (state, action) => {
        state.editPathId = action.payload.pathId
        state.authSelectedData = action.payload.selectedData
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(SetPathAuth.fulfilled, () => {
      })
      .addCase(showLoading.fulfilled, (state, action) => {
        state.pathLoading = action.payload
      })
      .addCase(selectAuthUser.fulfilled, (state, action) => {
        state.authSelectedData = action.payload
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.pathLoading = action.payload.loading
      })
      .addCase(addRoute.fulfilled, (state, action) => {
        state.pathLoading = action.payload.loading
      })
  }
})

export const { selectPermission } = fileManagerSlice.actions

export default fileManagerSlice.reducer
