// ** React Imports
import { Fragment, useState } from 'react'
import { Button } from 'reactstrap'

// ** Icons Imports
import { ArrowLeftCircle, Folder, Upload } from 'react-feather'

import { useDispatch, useSelector } from 'react-redux'
import { routeBack } from './store'

// ** Roles Components
import CreatePopup from './CreatePopup'
import FilePopup from './FilePopup'
import RoleCards from './RoleCards'


const Roles = () => {
  const dispatch = useDispatch()
  const store = useSelector(state => state.userFileManager)

  const [filePopupOpen, setFilePopupOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleFilePopup = () => setFilePopupOpen(!filePopupOpen)
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  console.log(store.systemPathUploadButtonShowHide)
  return (
    <Fragment>
      <div className='mb-2'>
        {store.path > 0 && !store.systemPathUploadButtonShowHide ? <Button.Ripple onClick={toggleFilePopup} className='btn-icon' style={{ marginLeft: 10, backgroundColor: 'transparent' }} outline color='primary'>
          <Upload /> Yükle
        </Button.Ripple> : null}
        <Button.Ripple onClick={toggleSidebar} className='btn-icon' style={{ marginLeft: 10, backgroundColor: 'transparent' }} outline color='primary'>
          <Folder /> Oluştur
        </Button.Ripple>
        <Button.Ripple onClick={() => { dispatch(routeBack("0")) }} className='btn-icon' style={{ marginLeft: 10, backgroundColor: 'transparent' }} outline color='primary'>
          <ArrowLeftCircle size={16} />
        </Button.Ripple>
      </div>
      <RoleCards />
      <FilePopup open={filePopupOpen} toggleFilePopup={toggleFilePopup} />
      <CreatePopup open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </Fragment>
  )
}

export default Roles
