// ** Reactstrap Imports
import {Fragment, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {DownloadCloud, FileText, X} from 'react-feather'
import {
    TabContent,
    TabPane,
    Button,
    Col,
    ListGroup,
    Label,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row
} from 'reactstrap'
// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import toast from 'react-hot-toast'
import {useDispatch, useSelector} from 'react-redux'
import {addSgkWorkerFile, setFileType} from '../store'
import Select from 'react-select'
import EntryExcel from '../../../../../assets/sgkDeclaration/ornek_toplu_ise_giris.xlsx'
import OutExcel from '../../../../../assets/sgkDeclaration/ornek_toplu_isten_cikis.xlsx'
import axios from "axios"

const FilePopup = ({open, toggleFilePopup}) => {
    const [active, setActive] = useState('1')
    const store = useSelector(state => state.sgkWorkerDeclaration)

    const [continuePopup, setContinuePopup] = useState(false)

    const continuePopupClose = () => {
        setContinuePopup(false)
    }

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const dispatch = useDispatch()
    const [files, setFiles] = useState([])
    const [copyFiles, setCopyFiles] = useState([])
    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop: result => {
            if (result.length && result[0].name.endsWith('xlsx')) {
                setFiles([...result.map(file => Object.assign(file))])
            } else {
                toast.error(
                    () => (
                        <p className='mb-0'>
                            Sadece <span className='fw-bolder'>.xlsx</span>, <span
                            className='fw-bolder'>.xls</span> &{' '} Yükleyebilirsin!.
                        </p>
                    ),
                    {
                        style: {
                            minWidth: '380px'
                        }
                    }
                )
            }
        }
    })

    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28'/>
        } else {
            return <FileText size='28'/>
        }
    }

    const handleRemoveFile = file => {
        const uploadedFiles = files
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        setFiles([...filtered])
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }

    const _downloadExcel = (type) => {
        const link = document.createElement('a')
        let filename = ''
        if (type === 1) {
            filename = 'ornek_toplu_ise_giris.xlsx'
            link.setAttribute('href', EntryExcel)
        } else {
            filename = 'ornek_toplu_isten_cikis.xlsx'
            link.setAttribute('href', OutExcel)
        }
        link.setAttribute('download', filename)
        link.click()
    }

    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>{renderFilePreview(file)}</div>
                <div>
                    <p className='file-name mb-0'>{file.name}</p>
                    <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                <X size={14}/>
            </Button>
        </ListGroupItem>
    ))

    const closePopup = () => {
        setFiles([])
        toggle("1")
        dispatch(setFileType("1"))
        toggleFilePopup()
    }

    useEffect(() => {
        setFiles([])
        toggle("1")
        dispatch(setFileType("1"))
    }, [store.filePopupOpen])

    const ReExcelUpload = () => {
        continuePopupClose(false)
        toggleFilePopup()
    }

    const Send = () => {
        const data = new FormData()
        if (copyFiles.length !== 0) {
            const file = copyFiles[0]
            data.append('File', file, file.name)
        } else {
            data.append('File', null)
        }
        data.append('Penalties', 0)
        continuePopupClose()
        dispatch(addSgkWorkerFile(data))
    }

    const SkipPenalties = () => {
        const data = new FormData()
        if (copyFiles.length !== 0) {
            const file = copyFiles[0]
            data.append('File', file, file.name)
        } else {
            data.append('File', null)
        }
        data.append('Penalties', 1)
        continuePopupClose()
        dispatch(addSgkWorkerFile(data))
    }

    const onSubmit = async () => {
        setCopyFiles(files)
        const data = new FormData()
        if (files.length !== 0) {
            const file = files[0]
            data.append('File', file, file.name)
        } else {
            data.append('File', null)
        }
        data.append('Type', parseInt(store.fileType))
        const response = await axios.post('/api/CompanyWorker/sgkFileCheck', data)
        if (response.data) {
            setContinuePopup(true)
            closePopup()
        } else {
            Send()
        }
    }

    return (
        <>
            <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Toplu işe giriş çıkış bildir</h1>
                        <p>SGK giriş çıkış bildirim ekranı</p>
                    </div>
                    <Fragment>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    active={active === '1'}
                                    onClick={() => {
                                        toggle('1')
                                        dispatch(setFileType("1"))
                                    }}
                                >
                                    Giriş Bildirimi
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={active === '2'}
                                    onClick={() => {
                                        toggle('2')
                                        dispatch(setFileType("2"))
                                    }}
                                >
                                    Çıkış Bildirimi
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent className='py-50' activeTab={store.fileType}>
                            <TabPane tabId='1'>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Button.Ripple block outline color='warning' onClick={() => _downloadExcel(1)}>
                                            Örnek Giriş Bildirimi Excel İndir
                                        </Button.Ripple>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId='2'>
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Button.Ripple block outline color='danger' onClick={() => _downloadExcel(2)}>
                                            Örnek Çıkış Bildirimi Excel İndir
                                        </Button.Ripple>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                        <Row>
                            <Col sm='12'>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <div className='d-flex align-items-center justify-content-center flex-column'>
                                        <DownloadCloud size={64}/>
                                        <h5>Dosyaları buraya bırakın veya yüklemek için tıklayın</h5>
                                        <p className='text-secondary'>
                                            Dosyaları buraya bırakın veya tıklayın{' '}
                                            <a href='/' onClick={e => e.preventDefault()}>
                                                Araştır
                                            </a>{' '}
                                        </p>
                                    </div>
                                </div>
                                {files.length ? (
                                    <Fragment>
                                        <ListGroup className='my-2'>{fileList}</ListGroup>
                                    </Fragment>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='12'>
                                <Button type='button' className='me-1' color='primary' onClick={onSubmit}>
                                    Bildir
                                </Button>
                                <Button type='reset' color='secondary' outline onClick={closePopup}>
                                    İptal
                                </Button>
                            </Col>

                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>

            <Modal isOpen={continuePopup} toggle={continuePopupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={continuePopupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>Dikkat</h1>
                        <p>Yüklediğiniz dosyada {active === "1" ? "işe girişi" : "işten çıkışı"} cezalı şekilde
                            yapılacak personeller var.
                            Devam etmek istiyor musunuz?</p>
                    </div>
                    <Button type='submit' className='me-1' color='primary' onClick={Send}>
                        Evet
                    </Button>
                    <Button type='submit' className='me-1' color='primary' onClick={ReExcelUpload}>
                        Yeni Excel Yükle
                    </Button>
                    <Button type='submit' className='me-1' color='primary' onClick={SkipPenalties}>
                        Cezalıları Atla
                    </Button>
                    <Button type='reset' color='secondary' outline onClick={continuePopupClose}>
                        İptal
                    </Button>
                </ModalBody>
            </Modal>

        </>
    )
}

export default FilePopup